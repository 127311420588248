import React, { useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import "../user.css"
import { useLocation, useNavigate } from 'react-router-dom';
const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();
    return (
        <div className="sticky-header">
            <Row style={{ margin: 0 }}>
                <Col className="fullwidth-menu">
                    <nav role="navigation">
                        <ul>
                            <li className={location.pathname === "/user/home" ? "active" : ""} onClick={() => navigate("/user/home")} ><a href="/" onClick={(e) => { e.preventDefault();  }}>Home</a></li>
                            {/* <li className={location.pathname === "/user/books/list" ? "active" : ""} ><a href="/" onClick={(e) => { e.preventDefault();  }}>Contents <i className="fas fa-angle-down"></i></a>
                                <ul className='submenu-ul' style={{width:"180px"}}>
                                    <li onClick={() => navigate("/user/books/list")}><a href="/" onClick={(e) => { e.preventDefault();  }}>Books</a></li>
                                    <li onClick={() => navigate("/user/videos")}><a href="/" onClick={(e) => { e.preventDefault();  }}>Videos</a></li>
                                    <li onClick={() => navigate("/user/podcasts")}><a href="/" onClick={(e) => { e.preventDefault();  }}>Podcasts</a></li>
                                    <li onClick={() => navigate("/user/expert_commentry")}><a href="/" onClick={(e) => { e.preventDefault();  }} style={{whiteSpace:"nowrap"}} >Expert Commentary</a></li>
                                </ul>
                            </li> */}
                            <li className={location.pathname === "/user/books/list" ? "active" : ""} onClick={() => navigate("/user/books/list")}><a href="/" onClick={(e) => { e.preventDefault();  }}>Books</a></li>
                            <li className={location.pathname === "/user/videos" ? "active" : ""} onClick={() => navigate("/user/videos")}><a href="/" onClick={(e) => { e.preventDefault();  }}>Videos</a></li>
                            <li className={location.pathname === "/user/podcasts" ? "active" : ""} onClick={() => navigate("/user/podcasts")}><a href="/" onClick={(e) => { e.preventDefault();  }}>Podcasts</a></li>
                            <li className={location.pathname === "/user/expert_commentry" ? "active" : ""} onClick={() => navigate("/user/expert_commentry")}><a href="/" onClick={(e) => { e.preventDefault();  }} style={{whiteSpace:"nowrap"}} >Expert Commentary</a></li>
                            <li className={location.pathname === "/user/courses" ? "active" : ""} onClick={() => navigate("/user/courses")}><a href="/" onClick={(e) => { e.preventDefault();  }}>Courses</a></li>
                            <li className={location.pathname === "/user/medical_cases" ? "active" : ""} onClick={() => navigate("/user/medical_cases")}><a href="/" onClick={(e) => { e.preventDefault();  }}>Medical Cases</a></li>
                            <li className={location.pathname === "/user/news" ? "active" : ""} onClick={() => navigate("/user/news")} ><a href="/" onClick={(e) => { e.preventDefault();  }}>News</a></li>
                            {/* <li className={location.pathname === "/user/decision_point" ? "active" : ""}><a href="/" onClick={(e) => { e.preventDefault(); navigate("/user/decision_point") }}>Decision point</a></li> */}
                            <li className={location.pathname === "/user/cme" ? "active" : ""} onClick={() => navigate("/user/cme")}><a href="/" onClick={(e) => { e.preventDefault();  }}>CME & Education</a></li>
                            {/* <li className={location.pathname === "/user/drugsanddiseases" ? "active" : ""}><a href="/" onClick={(e) => { e.preventDefault(); navigate("/user/drugsanddiseases") }}>Drugs and Diseases</a></li> */}
                            <li className={location.pathname === "/user/assessments" ? "active" : ""} onClick={() => navigate("/user/assessments")}><a href="/" onClick={(e) => { e.preventDefault();  }}>Assessments</a></li>
                        </ul>
                    </nav>

                </Col>
            </Row>
            <div style={{backgroundColor:"#f8f9fa", padding:"5px", borderBottom: "1px solid #ccc", borderTop: "1px solid #ccc"}}>
                <Container>
                    <Row>
                        <Col md={2}></Col>
                        <Col md={8}>
                            <div className="main-searchbox-div">
                                <input className="header-search-box"></input>
                                <button>
                                    <i class="fas fa-search"></i><span style={{marginLeft:"10px"}}>Search</span>
                                </button>
                            </div>
                        </Col>
                        <Col md={2}></Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Header;