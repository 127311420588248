import React, { useState, useEffect } from 'react';
import { Container, Col, Form, Row, Button, Card } from 'react-bootstrap';
import Swal from 'sweetalert2';
import axios from 'axios';

import { site_ip } from '../../../globalSetting';
import Aux from '../../../hoc/_Aux';
import API from '../../API';
import '../../Master/master.css';
import { useParams, useNavigate } from 'react-router-dom';
export default function EditPublisherUser(props) {
  const params = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    user_id: '',
    salutation: '',
    first_name: '',
    last_name: '',
    user_email: '',
    user_mobile: '',
    address: '',
    city: '',
    state_id: '',
    country_id: '',
    zipcode: '',
    gender: '',
    publisher_id: '',
  });
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };
  const fetchUsers = async (user_id) => {
    await axios
      .get(site_ip + '/auth/getPublisherUserById/' + user_id, jwtHeader)
      .then(function (response) {
        console.log(response);
        setValues(response.data.Students);
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };
  useEffect(() => {
    fetchUsers(params.user_id);
    //eslint-disable-next-line
  }, []);

  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [publisher, setPublisher] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (name === 'country_id') {
      fetchStateData(value);
    }
  };

  const fetchCountryData = async () => {
    try {
      setCountry(await API.fetchCountryList());
    } catch (error) {
      console.log(error);
    }
  };
  const fetchStateData = async (countryid) => {
    try {
      setState(await API.fetchStateByCountry(countryid));
    } catch (error) {
      console.log(error);
    }
  };
  const fetchPublisherData = async () => {
    try {
      setPublisher(await API.fetchPublishers());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCountryData();
    fetchPublisherData();
  }, []);

  const validateEmail = (mail) => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  };

  const phonenumber = (mobile) => {
    var phoneno = /^\d{10}$/;
    if (String(mobile).match(phoneno)) {
      return true;
    } else {
      return false;
    }
  };

  const proceed = async () => {
    if (
      values.salutation === '' ||
      values.first_name === '' ||
      values.last_name === '' ||
      values.address === '' ||
      values.gender === '' ||
      values.country_id === '' ||
      values.publisher_id === '' ||
      values.zipcode === ''
    )
      alert('Please fill all the fields');
    else if (!validateEmail(values.user_email.trim())) {
      alert('Please enter valid email address');
    } else if (!phonenumber(values.user_mobile)) {
      alert('Please enter valid mobile number');
    } else {
      await axios
        .post(site_ip + '/auth/setPublisherUser', values, jwtHeader)
        .then(function (response) {
          console.log(response);
          if (response.data.Success === 200) {
            Swal.fire({
              title: 'Success',
              text: 'User Updated',
              icon: 'success',
            });
            navigate('/admin/manage-publisher-users');
          } else {
            alert('Request Failed');
          }
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    }
  };

  return (
    <Aux>
      <Container fluid>
        <Card style={{marginTop:"15px"}}>
          <Card.Header as="h4">Edit Publisher User</Card.Header>
          <Card.Body>
        <Form>
          <Row>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Salutation</Form.Label>
                <Form.Control
                  className="mb-2"
                  as="select"
                  name="salutation"
                  value={values.salutation}
                  onChange={handleChange}
                >
                  <option value="">Select Salutation</option>
                  <option value="Dr.">Dr </option>
                  <option value="Mr.">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Ms.">Ms</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  className="mb-2"
                  type="text"
                  name="first_name"
                  value={values.first_name}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  className="mb-2"
                  type="text"
                  name="last_name"
                  value={values.last_name}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Gender</Form.Label>
                <Form.Control
                  className="mb-2"
                  as="select"
                  name="gender"
                  value={values.gender}
                  onChange={handleChange}
                >
                  <option>Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  className="mb-2"
                  type="email"
                  name="user_email"
                  value={values.user_email}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Mobile</Form.Label>
                <Form.Control
                  className="mb-2"
                  type="text"
                  name="user_mobile"
                  value={values.user_mobile}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>City</Form.Label>
                <Form.Control
                  className="mb-2"
                  type="text"
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group>
                <Form.Label>Country</Form.Label>
                <Form.Control
                  className="mb-2"
                  as="select"
                  name="country_id"
                  value={values.country_id}
                  onChange={handleChange}
                >
                  <option value="">Select Country</option>
                  {country?.map((value) => (
                    <option key={value.country_id} value={value.country_id}>
                      {value.country_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>State</Form.Label>
                <Form.Control
                  className="mb-2"
                  as="select"
                  name="state_id"
                  value={values.state_id}
                  onChange={handleChange}
                >
                  <option value="">Select State</option>
                  {state.map((value) => (
                    <option key={value.state_id} value={value.state_id}>
                      {value.state_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Zip Code</Form.Label>
                <Form.Control
                  className="mb-2"
                  type="text"
                  name="zipcode"
                  value={values.zipcode}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Publisher</Form.Label>
                <Form.Control
                  as="select"
                  className="mb-2"
                  name="publisher_id"
                  value={values.publisher_id}
                  onChange={handleChange}
                >
                  <option>Select Publisher</option>
                  {publisher?.map((option) => (
                    <option value={option.publisher_id}>
                      {option.publisher_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  className="mb-2"
                  as="textarea"
                  name="address"
                  value={values.address}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <br />
          <Button onClick={proceed}>Submit</Button>
        </Form>
        </Card.Body>
        </Card>
      </Container>
    </Aux>
  );
}
