import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./homepage.css";
import Footer from "./Footer";
import API from "../admin/content/API";
import disease from "../admin/assets/disease.jpg";
import { useNavigate } from "react-router-dom";
export default function Home() {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('userdata'));
  const [categories, setCategories] = useState([]);

  const getCategories = async () => {
    try {
      setCategories(await API.fetchCategories());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let token = localStorage.getItem("endUserToken")
    if (token === null) {
      navigate("/user/auth/login")
    } else {
      try {
        getCategories();        
      } catch (e) {
        navigate("/user/auth/login")
      }
    }
  }, [])

  return (
    <div>
      <Container>
        <Row style={{ margin: 0, minHeight: "80vh" }}>
          <Col md={2}></Col>
          {categories?.map((item, index) => {
            return (
              <Col md={4} xs={12} key={item?.category_id}>
                <a href="/" onClick={(e) => { e.preventDefault(); navigate("/courses/" + item?.category_id) }}>
                  <div className={index % 2 === 0 ? "m-3 animate_minus_45" : "m-3 animate_plus_45"}>
                    <div className="box p-3">
                      <img className="course-tab-image" src={index % 2 === 0 ? "./images/online-synchronous-class.jpg" : "./images/Bonafide-Certificate-Format.jpg"} />
                      <p className="course-tab-text">
                        {item?.category_name}
                      </p>
                    </div>
                  </div>
                </a>
              </Col>
            )
          })}
          <Col md={2}></Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

