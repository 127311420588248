import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import { site_ip } from "../../config";
import { useNavigate, useLocation } from "react-router-dom";
// import { convertFromRaw } from 'draft-js';
// import { stateToHTML } from 'draft-js-export-html';
function ExamReview() {
  const jwtHeader = {
    headers: { "access-token": localStorage.getItem("endUserToken") },
  };
  const navigate = useNavigate();
  const location = useLocation();

  const [questionSet, setQuestionSet] = useState([]);
  const [userResult, setUserResult] = useState({});
  const fetchQuestions = async () => {
    let user_test_id = location?.state?.user_test_id;
    if (user_test_id !== undefined && user_test_id !== null) {
      setUserResult(location?.state?.user_result);
      const response = await axios.get(
        site_ip + "/reviewTestQuestions/" + user_test_id,
        jwtHeader
      );
      setQuestionSet(response.data.ReviewData);
    } else {
      // history.replace('/student/dashboard');
    }
  };

  useEffect(() => {
    fetchQuestions();
    // eslint-disable-next-line
  }, []);

  // const backtoDashboard = () => {
  //   navigate("/user/assessments");
  // };

  return (
    <>
      <div className="styledCard" style={{ margin: "15px" }}>
        <div className="exam-container">
          <Row style={{ margin: 0 }}>
            <Col>
              <div className="score-box">
                <p className="boxValue">{userResult?.questions_count}</p>
                <p className="boxLabel">Question Count</p>
                <i className="fas fa-edit fix-pos"></i>
              </div>
            </Col>
            <Col>
              <div className="negative-marks">
                <p className="boxValue">{userResult?.correct_count}</p>
                <p className="boxLabel">Correct Questions</p>
                <i className="fa fa-thumbs-up fix-pos"></i>
              </div>
            </Col>
            <Col>
              <div className="average-time">
                <p className="boxValue">{userResult?.incorrect_count}</p>
                <p className="boxLabel">Incorrect Questions</p>
                <i className="fa fa-thumbs-down fix-pos"></i>
              </div>
            </Col>
            {/* <Col>
                  <div className="average-time">
                      <p className="boxValue">2.67</p>
                      <p className="boxLabel">Avg time per question (sec)</p>
                      <i class="fa fa-clock fix-pos"></i>
                  </div>
                </Col> */}
            <Col>
              <div className="accuracy">
                <p className="boxValue">{userResult?.unattempted_count}</p>
                <p className="boxLabel">Unattempted Questions</p>
                {/* <i class="fa fa-check fix-pos"></i> */}
                <i className="far fa-square fix-pos"></i>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {questionSet.map((item, index) => {
                return (
                  <QuestionReview
                    item={item}
                    index={parseInt(index) + 1}
                    key={index}
                  />
                );
              })}
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button onClick={() => navigate("/user/assessments")}>
                Back to Assessment
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default ExamReview;

const QuestionReview = (props) => {
  const renderQuestion = (text) => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    );
  };

  const renderOption = (text, option) => {
    return (
      <div style={{ display: "flex" }}>
        <b>{option}.</b>
        <div
          style={{ paddingLeft: "15px" }}
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      </div>
    );
  };

  const renderOptions = (text) => {
    // console.log(text)
    let correct_obj = JSON.parse(text);

    return (
      <>
        {correct_obj[0] ? renderOption(correct_obj[0], 1) : null}
        {correct_obj[1] ? renderOption(correct_obj[1], 2) : null}
        {correct_obj[2] ? renderOption(correct_obj[2], 3) : null}
        {correct_obj[3] ? renderOption(correct_obj[3], 4) : null}
      </>
    );
  };

  return (
    <>
      <div
        style={{ marginTop: "15px", border: "1px solid #ccc", padding: "15px" }}
      >
        {props.item?.attempt_status === "Incorrect" ? (
          <div
            style={{
              backgroundColor: "#ff0000",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "40px",
              color: "#ffffff",
            }}
          >
            Incorrect
          </div>
        ) : null}
        {props.item?.attempt_status === "Correct" ? (
          <div
            style={{
              backgroundColor: "#4caf50",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "40px",
              color: "#ffffff",
            }}
          >
            Correct
          </div>
        ) : null}
        {props.item?.attempt_status === "" ? (
          <div
            style={{
              backgroundColor: "#ff9900",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "40px",
              color: "#ffffff",
            }}
          >
            No Attempted
          </div>
        ) : null}
        <div>
          <p>
            Subject: <b>{props.item?.subject_name}</b>
          </p>
          <div className="divider-div" />
          <p>
            <b>Question No. {props.index}</b>
            {renderQuestion(props.item?.question)}
          </p>
          <div className="divider-div" />
          <Row>
            <Col>
              <p>
                <b>Options</b>
                {renderOptions(props.item?.question_options)}
              </p>
            </Col>
            <Col>
              <p>
                <b>Answer: </b>
                {props.item?.answers}
              </p>
            </Col>
          </Row>
          <div className="divider-div" />
          <Row>
            <Col>
              <p>
                <b>Explaination</b>
                {renderQuestion(props.item?.explanation)}
              </p>
            </Col>
            <Col>
              <p>
                <b>Reference Text</b>
                {renderQuestion(props.item?.reference_text)}
              </p>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
