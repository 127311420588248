import React, { useState, useEffect } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import API from '../API';
import Aux from '../../hoc/_Aux';
import '../common.css';
export default function InstitutionList(props) {
  // const [institutions, setInstitutions] = useState([]);

  useEffect(() => {
    fetchPublisherData();
    // eslint-disable-next-line
  }, []);
  const fetchPublisherData = async () => {
    try {
      //setInstitutions(await API.fetchInstitutions());
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Aux>
      <Container fluid>
        <Row className="align-items-center">
          <Col md={6}>
            <h4> Work In Progress</h4>
          </Col>
          <Col md={6} className="text-end">
            {/* <Button
              variant="dark"
              onClick={() => navigate('/admin/add-institution')}
            >
              Add Institution
            </Button> */}
          </Col>
        </Row>
        {/* <Row>
          <Col xl={12} className="mt-3">
              <table className="customTable">
                <thead>
                 <tr className="customHeader">
                    <th width="80px" style={{textAlign:'center'}}>Sr No</th>
                    <th>Institution Name</th>
                    <th>City</th>
                    <th>Mobile</th>
                    <th width="80px" style={{textAlign:'center'}}>Action</th>
                  </tr>
                </thead>  
                <tbody>
                  {institutions?.map((user, index) => {
                    return (
                      <tr key={index}>
                        <td style={{textAlign:'center'}}>{index + 1}</td>
                        <td>{user.institute_name}</td>
                        <td>{user.city}</td>
                        <td>{user.phone}</td>
                        <td style={{textAlign:'center'}}>
                          <Link
                            to={
                              '/admin/edit-institution/' + user.institution_id
                            }
                          >
                            <EditIcon />
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>  
              </table>
          </Col>
        </Row> */}
      </Container>
    </Aux>
  );
}
