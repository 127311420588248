import React from "react";
import "./Page.css";

const Page = React.forwardRef((props, ref) => {
  const {zoomed,setZoomed} = props;
  const handleZoom=(e)=>{
      e.preventDefault();
      setZoomed(false)
  }
    return (
      <div className="page" ref={ref}>
        <div className="page-content">
          {/* <h2 className="page-header"> {props.number}</h2> */}
          <div className="page-image">
           {props.zoomed? <img src={props.image} style={{ cursor: 'zoom-in', transform:"scale(2)" }} onClick={(e)=>handleZoom(e)} />:<img src={props.image} style={{ cursor: 'default' }} />}
          </div>
          {/* <div className="page-text">{props.children}</div> */}
          {/* <div className="page-footer">{props.number }</div> */}
        </div>
      </div>
    );
  });

  export default Page;