import React, {useState } from 'react';
import { Form, Button, Col, Row, } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

import { site_ip } from '../../globalSetting';

export default function FAQQuestion(props) {
  const [values, setValues] = useState({
    course_id: props.course_id
  });
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");


  const submitQuestion = async (e) => {
    e.preventDefault();
    const questions = {
      faq_question: question,
      faq_answer: answer,
    };
    const jwtHeader = {
      headers: { 'access-token': localStorage.getItem('admin_token') },
    };

    await axios
      .post(
        `${site_ip}/createCourseFaq`,
        [{ ...values, ...questions }],
        jwtHeader
      )
      .then((res) => {
        if (res.data.Status === 200) {
          Swal.fire('New FAQ added');
          props.refreshList();
        }
        setValues({
          course_id: props.course_id
        })
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div style={{ padding: "15px" }}>
        <Row>
          <Col md={12}>
            <Form.Group>
              <Form.Label>Question</Form.Label>
              <div
                style={{
                  border: '1px solid #ced4da',
                  padding: '2px',
                  minHeight: '300px',
                }}
              >
                <CKEditor
                  editor={Editor}
                  data={question}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setQuestion(data)
                  }}
                />
              </div>
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group>
              <Form.Label>Answer</Form.Label>
              <div
                style={{
                  border: '1px solid #ced4da',
                  padding: '2px',
                  minHeight: '300px',
                }}
              >
                <CKEditor
                  editor={Editor}
                  data={answer}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setAnswer(data)
                  }}
                />
              </div>
            </Form.Group>
          </Col>
        </Row>
        <br />
        <Button type="submit" onClick={submitQuestion}>
          Submit
        </Button>
      </div>
    </>
  );
}
