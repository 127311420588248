import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Container, Button, Image, Card, Modal } from 'react-bootstrap';
import './user.css';
import axios from 'axios';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { site_ip } from '../config';
export default function Home() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [loginTab, setLoginTab] = useState(true)
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");




  useEffect(() => {
    if (params.type !== undefined) {
      if (params.type === "register") {
        setLoginTab(false)
      }
    }
    
  }, [])

  const userLogin = () => {
    let req = {
      user_email: userEmail,
      password: userPassword
    }
    axios
      .post(site_ip + '/auth/siteSignin', req)
      .then((res) => {
        if (res.data?.token !== undefined) {
          localStorage.setItem("endUserToken", res.data?.token);
          localStorage.setItem("endUser", JSON.stringify(res.data?.content));
          localStorage.setItem("endUserName", res.data?.content?.first_name);
          localStorage.setItem("user_id", res.data?.content?.user_id);
          navigate("/")
        } else {
          alert("Invalid credentials.")
        }
      })
      .catch((err) =>
        alert("Invalid credentials.")
      );
  };

  const [registerationSuccess, setRegisterationSuccess] = useState(false)
  const blankValues = {
    salutation: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    user_email: "",
    user_mobile: "",
    address: "",
    city_id: "",
    state_id: "",
    country_id: "",
    zipcode: "",
    place_of_work: "",
    password: "",
    confirm_password: "",
    api_membership_number: "",
    speciality_id: ""
  }

  const [values, setValues] = useState(blankValues)

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "country_id") {
      setValues({ ...values, [name]: value, state_id: "", city_id: "" })
      fetchStateData(value)
    } else if (name === "state_id") {
      setValues({ ...values, [name]: value, city_id: "" })
      fetchCityData(value)
    } else {
      setValues({ ...values, [name]: value })
    }
  }

  useEffect(() => {
    fetchCountryData()
    fetchSpecialty();
  }, [])

  const [specialty, setSpecialty] = useState([])
  const fetchSpecialty = async () => {
    const result = await axios.get(`${site_ip}/getSubjects`);
    setSpecialty(result.data.content);
  };

  const [country, setCountry] = useState([])
  const fetchCountryData = async () => {
    const result = await axios.get(`${site_ip}/getCountries`);
    setCountry(result.data.Data);
  };
  const [states, setStates] = useState([])
  const fetchStateData = async (country_id) => {
    const result = await axios.get(`${site_ip}/getStateByCountry/${country_id}`);
    setStates(result.data.Data);
    // console.log(result);
  };

  const [cities, setCities] = useState([])
  const fetchCityData = async (state_id) => {
    const result = await axios.get(`${site_ip}/getCities/${state_id}`);
    setCities(result.data.States);
    // console.log(result);
  };

  const validateEmail = (mail) => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  };

  const phonenumber = (mobile) => {
    var phoneno = /^\d{10}$/;
    if (String(mobile).match(phoneno)) {
      return true;
    } else {
      return false;
    }
  };

  const userSignup = () => {
    let req = { ...values };
    if (req.first_name.trim() === "") {
      alert("Please enter your first name")
      return
    } else if (req.last_name.trim() === "") {
      alert("Please enter your last name")
      return
    } else if (!validateEmail(req.user_email)) {
      alert("Please enter a valid email address.")
      return
    } else if (!phonenumber(req.user_mobile)) {
      alert("Please enter a valid number.")
      return
    } else if (req.api_membership_number.trim() === "") {
      alert("Please enter API Membership/MCI Registration Number.")
      return
    } else if (req.speciality_id.trim() === "") {
      alert("Please select specialty.")
      return
    } else if (req.country_id.trim() === "") {
      alert("Please select country.")
      return
    } else if (req.state_id.trim() === "") {
      alert("Please select state.")
      return
    } else if (req.city_id.trim() === "") {
      alert("Please select city.")
      return
    } else if (req.place_of_work.trim() === "") {
      alert("Please enter your place of work.")
      return
    } 
    // else if (req.password?.length < 8) {
    //   alert("Minimum 8 character length password required.")
    //   return
    // } else if (req.password !== req.confirm_password) {
    //   alert("Password not matched.")
    //   return
    // }
    axios
      .post(site_ip + '/auth/signup', req)
      .then((res) => {
        setRegisterationSuccess(true)
        setValues(blankValues);
        setLoginTab(true)
      })
      .catch((err) =>
        alert("Invalid credentials.")
      );
  };

  return (
    <div>
      <div className='login-page'>
        <Container fluid="md">
          <Row>
            <Col md={2}></Col>
            <Col md={8}>
              <div className='login-box'>
                <div style={{ textAlign: 'center' }}>
                  <img src="../../images/icp.jpeg" className="login-logo"></img>
                </div>
                <Row>
                  <div style={{ height: "20px" }} />
                  <Col style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                    <a href="/" className={loginTab ? 'tablink' : 'tablink active'} onClick={(e) => { e.preventDefault(); setLoginTab(false) }}>Create Account</a>
                    <a href="/" className={loginTab ? 'tablink active' : 'tablink'} onClick={(e) => { e.preventDefault(); setLoginTab(true) }}>Log In</a>
                  </Col>
                </Row>
                {loginTab ?
                  <>
                    <Row>
                      <Col>
                        <p className="loginpage-heading">Log In To Your Account</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2}></Col>
                      <Col md={8}>
                        <p className="login-page-label">Email</p>
                        <input
                          className="loginpage-input"
                          placeholder=''
                          value={userEmail}
                          onChange={(e) => setUserEmail(e.target.value)}
                        />
                        <div style={{ height: "20px" }} />
                        <p className="login-page-label">Password</p>
                        <input
                          type={"password"}
                          className="loginpage-input"
                          placeholder=''
                          value={userPassword}
                          onChange={(e) => setUserPassword(e.target.value)}
                        />
                        <div style={{ textAlign: 'center', marginTop: "40px", marginBottom: "20px" }}>
                          <button className="user-primary-btn" onClick={() => userLogin()}>Log In</button>
                        </div>
                        <Row>
                          <Col md={5} style={{ display: "flex", alignItems: 'center' }}>
                            <div style={{ borderBottom: "1px solid #ccc", width: "100%" }}></div>
                          </Col>
                          <Col md={2} style={{ textAlign: 'center', justifyContent: 'center', display: "flex" }}>
                            <p style={{ fontSize: "12px", marginBottom: "0px" }}>or</p>
                          </Col>
                          <Col md={5} style={{ display: "flex", alignItems: 'center' }}>
                            <div style={{ borderBottom: "1px solid #ccc", width: "100%" }}></div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} style={{ flexDirection: "column", textAlign: 'center', justifyContent: 'center', display: "flex", margin: "15px 0px" }}>
                            <p style={{ fontSize: "14px", marginBottom: "5px" }}>For first time users, please signup to take a course</p>
                            <a style={{ fontSize: "14px", color: "#0c66a6", fontWeight: "bold" }} href="/" onClick={(e) => { e.preventDefault(); setLoginTab(false) }}>SIGN UP</a>
                          </Col>
                        </Row>
                        <Row>
                          {/* <Col style={{display:'flex', justifyContent:'center'}}>
                        <button className="social-media-buttons">
                          <img src="../../images/google.png" />
                          Google
                        </button>
                        <div style={{width:"20px"}} />
                        <button className="social-media-buttons">
                          <img src="../../images/facebook.png" />
                          Facebook
                        </button>
                      </Col> */}
                        </Row>
                      </Col>
                      <Col md={2}></Col>
                    </Row>
                  </> :
                  <>
                    <Row>
                      <Col>
                        <p className="loginpage-heading">Sign Up for a Free Account</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Row>
                          <Col md={6}>
                            <p className="login-page-label">First Name <span style={{ color: "#ff0000" }}>*</span></p>
                            <input
                              className="loginpage-input"
                              placeholder=''
                              name="first_name"
                              value={values?.first_name}
                              onChange={handleChange}
                            />
                            <div style={{ height: "20px" }} />
                          </Col>
                          <Col md={6}>
                            <p className="login-page-label">Last Name <span style={{ color: "#ff0000" }}>*</span></p>
                            <input
                              className="loginpage-input"
                              placeholder=''
                              name="last_name"
                              value={values?.last_name}
                              onChange={handleChange}
                            />
                            <div style={{ height: "20px" }} />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <p className="login-page-label">Email <span style={{ color: "#ff0000" }}>*</span></p>
                            <input
                              className="loginpage-input"
                              placeholder=''
                              name="user_email"
                              value={values?.user_email}
                              onChange={handleChange}
                            />
                            <p style={{ fontSize: "11px" }}>(Please give correct email id as username and password will be sent at this id)</p>
                            <div style={{ height: "20px" }} />
                          </Col>
                          <Col md={6}>
                            <p className="login-page-label">Phone <span style={{ color: "#ff0000" }}>*</span></p>
                            <input
                              className="loginpage-input"
                              placeholder=''
                              name="user_mobile"
                              value={values?.user_mobile}
                              onChange={handleChange}
                            />
                            <div style={{ height: "20px" }} />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <p className="login-page-label">API Membership/MCI Registration Number <span style={{ color: "#ff0000" }}>*</span></p>
                            <input
                              className="loginpage-input"
                              placeholder=''
                              name="api_membership_number"
                              value={values?.api_membership_number}
                              onChange={handleChange}
                            />
                            <div style={{ height: "20px" }} />
                          </Col>
                          <Col md={6}>
                            <p className="login-page-label">Specialty <span style={{ color: "#ff0000" }}>*</span></p>
                            <select className="loginpage-input" value={values?.speciality_id} name="speciality_id" onChange={handleChange}>
                              <option value="">--Select--</option>
                              {specialty?.map((item) => {
                                return (
                                  <option key={item?.subject_id} value={item?.subject_id}>{item?.subject_name}</option>
                                )
                              })}
                            </select>
                            <div style={{ height: "20px" }} />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <p className="login-page-label">Country <span style={{ color: "#ff0000" }}>*</span></p>
                            <select className="loginpage-input" value={values?.country_id} name="country_id" onChange={handleChange}>
                              <option value="">--Select--</option>
                              {country?.map((item) => {
                                return (
                                  <option key={item?.id} value={item?.id}>{item?.country_name}</option>
                                )
                              })}
                            </select>
                            <div style={{ height: "20px" }} />
                          </Col>
                          <Col md={6}>
                            <p className="login-page-label">State <span style={{ color: "#ff0000" }}>*</span></p>
                            <select className="loginpage-input" value={values?.state_id} name="state_id" onChange={handleChange}>
                              <option value="">--Select--</option>
                              {states?.map((item) => {
                                return (
                                  <option key={item?.state_id} value={item?.state_id}>{item?.state_name}</option>
                                )
                              })}
                            </select>
                            <div style={{ height: "20px" }} />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <p className="login-page-label">City <span style={{ color: "#ff0000" }}>*</span></p>
                            <select className="loginpage-input" value={values?.city_id} name="city_id" onChange={handleChange}>
                              <option value="">--Select--</option>
                              {cities?.map((item) => {
                                return (
                                  <option key={item?.city_id} value={item?.city_id}>{item?.city_name}</option>
                                )
                              })}
                            </select>
                            <div style={{ height: "20px" }} />
                          </Col>
                          <Col md={6}>
                            <p className="login-page-label">Place of work <span style={{ color: "#ff0000" }}>*</span></p>
                            <input
                              type={"text"}
                              className="loginpage-input"
                              placeholder=''
                              name="place_of_work"
                              value={values?.place_of_work}
                              onChange={handleChange}
                            />
                            <div style={{ height: "20px" }} />
                          </Col>
                        </Row>
                        {/* <Row>
                          <Col md={6}>
                            <p className="login-page-label">Password <span style={{ color: "#ff0000" }}>*</span></p>
                            <input
                              type={"password"}
                              className="loginpage-input"
                              placeholder=''
                              name="password"
                              value={values?.password}
                              onChange={handleChange}
                            />
                            <div style={{ height: "20px" }} />
                          </Col>
                          <Col md={6}>
                            <p className="login-page-label">Confirm Password <span style={{ color: "#ff0000" }}>*</span></p>
                            <input
                              type={"password"}
                              className="loginpage-input"
                              placeholder=''
                              name="confirm_password"
                              value={values?.confirm_password}
                              onChange={handleChange}
                            />
                            <div style={{ height: "20px" }} />
                          </Col>
                        </Row> */}
                        <Row>
                          <Col>
                            <p style={{ fontSize: "11px" }}><span style={{ color: "#ff0000" }}>*</span> All fields are mandatory</p>
                          </Col>
                        </Row>
                        <div style={{ textAlign: 'center', marginTop: "40px", marginBottom: "20px" }}>
                          <button className="user-primary-btn" style={{ width: "180px" }} onClick={() => userSignup()}>Create an Account</button>
                        </div>
                        <Row>
                          <Col md={5} style={{ display: "flex", alignItems: 'center' }}>
                            <div style={{ borderBottom: "1px solid #ccc", width: "100%" }}></div>
                          </Col>
                          <Col md={2} style={{ textAlign: 'center', justifyContent: 'center', display: "flex" }}>
                            <p style={{ fontSize: "12px", marginBottom: "0px" }}>or</p>
                          </Col>
                          <Col md={5} style={{ display: "flex", alignItems: 'center' }}>
                            <div style={{ borderBottom: "1px solid #ccc", width: "100%" }}></div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} style={{ flexDirection: "column", textAlign: 'center', justifyContent: 'center', display: "flex", margin: "15px 0px" }}>
                            <p style={{ fontSize: "14px", marginBottom: "5px" }}>Already have an account?</p>
                            <a style={{ fontSize: "14px", color: "#0c66a6", fontWeight: "bold" }} href="/" onClick={(e) => { e.preventDefault(); setLoginTab(true) }}>LOGIN</a>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>}
              </div>
            </Col>
            <Col md={2}></Col>
          </Row>
        </Container>
      </div>
      <Modal size={"sm"} centered show={registerationSuccess}>
        <Modal.Body>
          <p style={{ fontSize: "16px", marginBottom: "0px" }}><b>Thank you very much for registering.</b> Your username/email id and password is sent at your registered email id (which you have entered during registration process)</p></Modal.Body>
        <Modal.Footer>
          <button
            style={{ backgroundColor: "#000000f1", marginLeft: "5px", color: "#ffffff" }}
            className="btn btn-sm" variant="secondary" onClick={() => setRegisterationSuccess(false)}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {/* <Footer /> */}
    </div>
  );
}
