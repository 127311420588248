import React, { Component } from 'react';
import { Row, Container, Col, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { site_ip } from '../config';
// import Pic from '../img/logo_53.svg';
//import Background from "../img/BackgroungLogin.jpg";
import Swal from 'sweetalert2';
export default class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contact_email: '',
      contact_name: '',
      contact_nbr: '',
      message: '',
      // error: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {}
  handleSubmit() {
    if (
      !this.state.contact_name ||
      !this.state.contact_email ||
      !this.state.contact_nbr ||
      !this.state.message
    )
      alert('Please fill all the fields');
    else {
      axios({
        method: 'post',
        url: site_ip + '/setContactUs',
        data: {
          contact_email: this.state.contact_email,
          contact_name: this.state.contact_name,
          contact_nbr: this.state.contact_nbr,
          message: this.state.message,
        },
        dataType: 'json',
      })
        .then(function (response) {
          console.log(response);
          Swal.fire({
            title: 'Thank You For Contact Us',
            icon: 'success',
            confirmButtonText: 'Confirmed',
            confirmButtonColor: '#45b161',
          }).then((result) => {
            console.log(result);
            window.location.reload();
          });
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    }
  }
  render() {
    return (
      <Container fluid className="cust-height">
        <Row className="align-items-center h-100">
          <Col md={6} className="mx-auto">
            <div className="p-4 my-4 border shadow rounded-xl bg-white">
              <Form>
                <h2 style={{ textAlign: 'center' }}>Contact Us</h2>
                <Form.Group>
                  <Form.Control
                    type="text"
                    value={this.state.contact_name}
                    className="form-control mb-3"
                    onChange={(e) =>
                      this.setState({ contact_name: e.target.value })
                    }
                    placeholder="  Your Name"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    type="email"
                    value={this.state.contact_email}
                    className="form-control mb-3"
                    onChange={(e) =>
                      this.setState({ contact_email: e.target.value })
                    }
                    placeholder=" Your Email"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    type="tel"
                    minlength="10"
                    maxlength="10"
                    className="form-control mb-3"
                    value={this.state.contact_nbr}
                    onChange={(e) =>
                      this.setState({ contact_nbr: e.target.value })
                    }
                    placeholder=" Your Mobile Number"
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    type="text"
                    className="form-control mb-3"
                    placeholder=" Your City"
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    type="text"
                    className="form-control mb-3"
                    placeholder=" Your State"
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    as="textarea"
                    value={this.state.message}
                    onChange={(e) => this.setState({ message: e.target.value })}
                    placeholder="Drop your query"
                    className="form-control mb-3"
                  />
                </Form.Group>
                <Form.Group className="mb-0">
                  <Button variant="primary mr-2" onClick={this.handleSubmit}>
                    Submit
                  </Button>
                </Form.Group>
              </Form>
            </div>
            {/* <div>
              Write to us ......
              <br /> or
              <br />
              Call us (9AM to 6PM) ......
              <br />
              <br />
              Address .....
            </div> */}
          </Col>
        </Row>
      </Container>
    );
  }
}
