import React, { useEffect, useState } from "react"
import Header from "../../common/Header"
import Menu from "../../common/Menu";
import axios from 'axios';
import { site_ip } from '../../../config';
import { Form, Col, Row, Button, Modal } from "react-bootstrap";
import "./forum.css";
import { useParams, useNavigate } from "react-router-dom";
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
const ForumDetails = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [modalVisible, setModalVisible] = useState(false);
    return (
        <>
            <div style={{ padding: "25px" }}>
                <Row style={{ marginBottom: "15px" }}>
                    <Col>
                        <a className="burder-link" href="/" onClick={(e) => { e.preventDefault(); navigate("/user/home") }}>Discussion Forums</a>
                        <span style={{ padding: "0px 5px" }}>/</span>
                        <a className="burder-link" href="/" onClick={(e) => { e.preventDefault(); }}>Week 4</a>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>

                    </Col>
                    <Col md={9}>
                        <p className="forum-title">Week 4</p>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <p className="notes-text">Discuss the topic here.</p>
                            <button className="plain-button" style={{ marginLeft: "30px", fontSize: "12px" }}>Subscribe</button>
                        </div>
                        <Row>
                            <Col xs={7}>
                                <div style={{ display: "flex" }}>
                                    <Form.Control type="text" />
                                    <div style={{ width: "15px" }}></div>
                                    <Button variant="primary">Search</Button>{' '}
                                </div>
                            </Col>
                            <Col xs={1} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <span>or</span>
                            </Col>
                            <Col xs={4}>
                                <Button variant="outline-primary" onClick={() => setModalVisible(true)} >Create a post</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <Modal show={modalVisible} onHide={() => setModalVisible(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Create a new post</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <NewPost onClose={() => setModalVisible(false)} />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ForumDetails

const NewPost = (props) => {
    const [copyright, setCopyright] = useState("");
    return (
        <div>
            <p style={{fontSize:"14px", marginBottom:"5px", fontWeight:"bold"}}>Title <span style={{color:"#ff0000"}}>*</span></p>
            <Form.Control type="text" />
            <div style={{height:"10px"}}></div>
            <p style={{fontSize:"14px", marginBottom:"5px", fontWeight:"bold"}}>Body <span style={{color:"#ff0000"}}>*</span></p>
            <CKEditor
                style={{ width: "100%" }}
                editor={Editor}
                data={copyright}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    setCopyright(data)
                }}
            />
            <Row>
                <Col style={{display:"flex", marginTop:"20px"}}>
                    <Button variant="primary" onClick={() => props.onClose()}>
                        Save Changes
                    </Button>
                    <div style={{width:"10px"}}></div>
                    <Button variant="secondary" onClick={() => props.onClose()}>
                        Close
                    </Button>    
                </Col>
            </Row>
        </div>
    )
}