import React, { useState, useEffect } from "react";
import HTMLFlipBook from "react-pageflip";
import { pdfjs, Document, Page as ReactPdfPage } from "react-pdf";

import samplePDF from "./epaper.pdf";


const PdfPage = () => {
    const [numPages, setNumPages] = useState(null);
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const [bookWidth, setBookWidth] = useState(window.screen.width * 0.3);
    const [bookHeight, setBookHeight] = useState(window.screen.width * 0.5);
    const [zoom, setZoom] = useState(1)
    const width = window.screen.width * 0.3;
    const height = window.screen.width * 0.5;

    const zoomIn = () =>{
        let current_zoom = zoom + 1;
        setZoom(current_zoom)
        setBookWidth(window.screen.width * 0.3 * current_zoom)
    }

    const zoomOut = () =>{
        let current_zoom = zoom - 1;
        setZoom(current_zoom)
        setBookWidth(window.screen.width * 0.3 * current_zoom)
    }

    const Page = React.forwardRef(({ pageNumber }, ref) => {
        return (
            <div ref={ref}>
                <ReactPdfPage pageNumber={pageNumber} width={width} />
            </div>
        );
    });

    const [pageSelection, setPageSelection] = useState([]);

    useEffect(() => {
        const selection = [];
        for (let i = 1; i <= numPages; i++) {
            selection.push(i);
        }
        setPageSelection(selection);
    }, [numPages])

    const onDocumentLoadSuccess = (pdf) => {
        setNumPages(pdf?.numPages);
    }

    return (
        <div>
            <Document file={samplePDF} onLoadSuccess={(e) => onDocumentLoadSuccess(e)}>
                <HTMLFlipBook width={bookWidth} height={bookHeight} style={{ margin: 'auto' }}>
                    {pageSelection && pageSelection.map(function (cur, idx) {
                        return <Page pageNumber={cur} key={idx} />
                    })}
                </HTMLFlipBook>
            </Document>
            <button onClick={() => zoomOut()}>Zoom Out</button>
            <button onClick={() => zoomIn()}>Zoom In</button>
        </div>
    );
}
export default PdfPage;

