import React from 'react';
import Header from "../common/Header"
import Menu from "../common/Menu"

const Home = () =>{
    return(
        <div>
            <div style={{height:'40vh', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                <h3>Page under development.</h3>
                <h6>Please see News tab for content.</h6>
            </div>
        </div>
    )
}

export default Home;