import React, { useEffect, useState } from 'react';
import Header from "../../common/Header"
import Menu from "../../common/Menu"
import axios from 'axios';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { site_ip } from '../../../config';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import "./news.css";
import ReactPlayer from "react-player";
const News = () => {
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('endUserToken') },
    };
    const params = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        if (params.id !== undefined) {
            getPodcastsById(params.id)
        }
    }, [params.id])

    const [podcastsDetails, setPodcastsDetails] = useState({});
    const getPodcastsById = (id) => {
        axios
            .get(site_ip + '/getBookPodcastById/' + id, jwtHeader)
            .then((res) => {
                setPodcastsDetails(res.data.Content[0]);
                window.scrollTo(0, 0)
            })
            .catch((err) => console.log(err));
    };

    return (
        <div>
            <Container>
                <Row style={{ margin: 0 }}>
                    <Col md={12}>
                        <div style={{ padding: "15px 0px 0px 0px" }}>
                            <p className="news-thumbnail-category">{podcastsDetails?.subject_name}</p>
                            <p className="news-title">{podcastsDetails?.podcast_title}</p>
                            <div style={{ marginTop: "10px", marginBottom: "0px", width:"100%" }}>
                            {podcastsDetails?.podcast_url !== null && podcastsDetails?.podcast_url !== undefined && podcastsDetails?.podcast_url !== "" ?   
                            <audio controls>
                                <source src={podcastsDetails?.podcast_url} type="audio/mpeg" />
                                Your browser does not support the audio element.
                            </audio>  
                            : null }
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default News;
