import React, { useEffect, useState } from 'react';
import Header from "../../common/Header"
import Menu from "../../common/Menu";
import axios from 'axios';
import { site_ip } from '../../../config';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import "./course.css";

const CourseListing = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [thumbnailView, setThumbnailView] = useState(true);
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('endUserToken') },
    };
    const blankValues = {
        subject_id: "",
        category_id: "",
        publisher_id: "",
        book_title: "",
        isbn: ""
    }
    const [values, setValues] = useState(blankValues);
    const [courseList, setCourseList] = useState([])
    useEffect(() => {
        getCourses(values);
    }, [])

    const getCourses = (req) => {
        axios
            .get(site_ip + '/getCourses', jwtHeader)
            .then((res) => {
                setCourseList(res.data.Courses)
            })
            .catch((err) => console.log(err));
    };

    return (
        <div>
            <Container style={{ marginTop: "25px" }}>
                <Row>
                    <Col>
                        <a className="burder-link" href="/" onClick={(e) => { e.preventDefault(); navigate("/user/home") }}>Home</a>
                        <span style={{ padding: "0px 5px" }}>/</span>
                        <a className="burder-link" href="/" onClick={(e) => { e.preventDefault(); }}>All Courses</a>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ display: "flex", alignItems: "center" }}>
                        <p style={{ fontWeight: "bold", fontSize: "25px", color: "#000000" }}>Courses</p>
                    </Col>
                    <Col>
                        <Row>
                            <Col xs={8}>
                                <div className="search-round-div">
                                    <input placeholder='Search courses...' />
                                    <button>Search</button>
                                </div>
                            </Col>
                            <Col xs={4}>
                                <button className={thumbnailView ? "image-btn active" : "image-btn"}  onClick={() => setThumbnailView(true)}>
                                    <i class="fas fa-th"></i>
                                </button>
                                <button className={!thumbnailView ? "image-btn active" : "image-btn"} onClick={() => setThumbnailView(false)}>
                                    <i class="fas fa-th-list"></i>
                                </button> 
                            </Col>
                        </Row>

                    </Col>
                </Row>
                <div style={{ padding: "15px 0px" }}>
                    {thumbnailView ? 
                    <Row>
                        {courseList.map((item, index) => {
                            return (
                                <Col key={index} md={4}>
                                    <CourseThumbnail item={item} />
                                </Col>
                            )
                        })}
                    </Row> :
                    <Row>
                    {courseList.map((item, index) => {
                            return (
                                <Col key={index} md={12}>
                                    <CourseList item={item} />
                                </Col>
                            )
                        })}
                    </Row> } 
                </div>
            </Container>
        </div>
    )
}

export default CourseListing;

const CourseThumbnail = (props) => {
    const item = props.item;
    const navigate = useNavigate();
    
    return (
        <div className='subject-thumbnail-div'>
            <Row>
                <Col md={12}>
                    <a href="/" onClick={(e) => { e.preventDefault(); navigate("/user/course/" + item.course_id) }}>
                        <div className='course-thumbnail-img-div'>
                            <img src={item.course_image}  />
                        </div>
                    </a>
                </Col>
                <Col md={12}>
                <p className="suject-thumbnail-name"><a href="/" onClick={(e) => { e.preventDefault(); navigate("/user/course/" + item.course_id) }}>{item.course_title}</a></p>
                    <div style={{ height: "20px" }}></div>
                    <div style={{ display: "flex" }}>
                        <div style={{ flex: 1 }}>
                            </div>
                        <div style={{ flex: 1, textAlign: "right" }}>
                            {<p className="suject-thumbnail-category">{item?.access_type_cd}</p>}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

const CourseList = (props) => {
    const item = props.item;
    const navigate = useNavigate();
    
    return (
        <div className='subject-thumbnail-div'>
            <Row>
                <Col md={4}>
                    <a href="/" onClick={(e) => { e.preventDefault(); navigate("/user/course/" + item.course_id) }}>
                        <div className='course-thumbnail-img-div'>
                            <img src={item.course_image}  />
                        </div>
                    </a>
                </Col>
                <Col md={8}>
                <p className="suject-thumbnail-name"><a href="/" onClick={(e) => { e.preventDefault(); navigate("/user/course/" + item.course_id) }}>{item.course_title}</a></p>
                    <div style={{ height: "20px" }}></div>
                    <div style={{ display: "flex" }}>
                        <div style={{ flex: 1}}>
                            {<p className="suject-thumbnail-category">{item?.access_type_cd}</p>}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}