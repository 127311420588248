import React, { useState, useEffect } from 'react';
import { Container, Button, Col, Row } from 'react-bootstrap';
import Aux from '../../hoc/_Aux';
import Edit from './Edit';
import IPAddressess from './IPAddressess';
import Subscriptions from './Subscriptions';
import { useParams } from 'react-router-dom';
export default function AddInstitution(props) {
   const params = useParams();  
   const [activeTab, setActiveTab] = useState(1) 
   const [institutionId, setInstitutionId] = useState(0);
   useEffect(()=>{
    if(params.institution_id !== undefined){
        setInstitutionId(params.institution_id);
    }   
      // eslint-disable-next-line
   }, [])

   return (
    <Aux>
      <Container fluid>
        {institutionId !== 0 ?  
        <Row>
            <Col>
              <Button onClick={()=> setActiveTab(1)} className={activeTab === 1 ? "instituionTabSection active" : "instituionTabSection"}>Edit Instituion</Button>
              <Button onClick={()=> setActiveTab(2)} className={activeTab === 2 ? "instituionTabSection active" : "instituionTabSection"}>IP Address</Button>
              <Button onClick={()=> setActiveTab(3)} className={activeTab === 3 ? "instituionTabSection active" : "instituionTabSection"}>Subscriptions</Button>
            </Col>
            {/* Edit Institution */}
            {activeTab === 1 ? <Edit id={institutionId}/> : null }
            {/* IP Addresss */}
            {activeTab === 2 ? <IPAddressess id={institutionId} /> : null}
            {/* Subscriptions */}
            {activeTab === 3 ? <Subscriptions id={institutionId} /> : null}
        </Row> : null }
      </Container>
    </Aux>
  );
}
