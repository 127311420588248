import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./homepage.css";
import Footer from "./Footer";
import axios from 'axios';
import { site_ip } from '../config';
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
export default function Home() {
  const navigate = useNavigate();
  const params = useParams();
  const user = JSON.parse(localStorage.getItem('userdata'));
  const [courses, setCourses] = useState([]);
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('endUserToken') },
  };
  const [activeYear, setActiveYear] = useState(new Date().getFullYear())
  function generateArrayOfYears() {
    var max = new Date().getFullYear()
    var min = max - 2
    var years = []
  
    for (var i = max; i >= min; i--) {
      years.push({category_id: i, category_name: i, child:[]})
    }
    return years
  }

  useEffect(() => {
    let token = localStorage.getItem("endUserToken")
    if (token === null) {
      navigate("/user/auth/login")
    } else {
      try {
        let end_user = JSON.parse(localStorage.getItem("endUser"))
        
      } catch (e) {
        navigate("/user/auth/login")
      }
    }
  }, [])

  let years = generateArrayOfYears()


  const getCourses = async (category_id, year) => {
    try{
      await axios
      .get(
        `${site_ip}/getCourses/`+category_id+"/"+year,
        jwtHeader
      )
      .then((res) => {
        setCourses(res.data?.Courses)
      })
      .catch((err) => console.log(err));
    }catch(e){

    }
  };

  useEffect(() => {
    if(params?.category_id !== undefined){
      getCourses(params?.category_id, activeYear);
    }else{
      navigate("/")
    }
  }, [activeYear]);



  return (
    <div>
      <Row style={{ margin: 0, minHeight: "80vh" }}>
        <Col md={3}>
          <div className="m-3">
            <div className="box p-3">
              <p className="course-card-title">Year</p>
              <div className="divider mb-3"></div>
              {
                years?.map((item, index) => {
                  return (
                    <CategoryMenu item={item} key={index} activeYear={activeYear} changeActiveYear={(val) => setActiveYear(val)} />
                  )
                })
              }
            </div>
          </div>
        </Col>
        <Col md={9}>
          {/* <div className="m-3">
            <div className="box-header">
              <span>Short Certificate Course Name</span>
            </div>
          </div> */}
          <div className="m-3">
            <div className="box-content">
              <Row>
                {courses?.map((item, index) => {
                  return (
                    <Col key={index} md={4} style={{ marginBottom: "15px" }}>
                      <div className="box p-3">
                        <p className="course-card-title">{item?.course_title}</p>
                        <div className="divider mb-3"></div>
                        <div className="director-div">
                          <Row>
                            <Col md={8} sm={8}>
                              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: "100%" }}>
                                <p className="course-card-label">Course Director:</p>
                                <p className="doctor-name">
                                  Dr. Nandini Chatterjee
                                </p>
                              </div>
                            </Col>
                            <Col md={4} sm={8}>
                              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <img
                                  className="doctor-img"
                                  src={item?.course_director_img !== "" ? item?.course_director_img : "/images/doctor.png"}
                                ></img>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="mb-3 mt-3"></div>
                        <div>
                          <button onClick={() => navigate("/course/" + item?.course_id)} className="coa-btn">Click here to watch course videos</button>
                        </div>
                      </div>
                    </Col>
                  )
                })}
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      <Footer />
    </div>
  );
}

const CategoryMenu = ({item, activeYear, changeActiveYear}) => {
  const [selectedCategory, setSelectedCategory] = useState(false)
  return (
    <div>
      <div style={{ display: "flex" }}>
        {/* <input type="checkbox" style={{marginRight:"10px"}} checked={selectedCategory === item?.category_id} onChange={() => null} onClick={() => setSelectedCategory(selectedCategory === item?.category_id ? 0 : item?.category_id)} /> */}
        <a href="/" onClick={(e) => { e.preventDefault(); changeActiveYear(item?.category_id) }}><p className="video-topic-name" style={{color:activeYear !== item?.category_id ? "#000000" : "#ff9900"}}>{item?.category_name}</p></a>
      </div>
      <div className="divider"></div>
      {item?.child.length > 0 && selectedCategory ?
        <div style={{ paddingLeft: "15px" }}>
          {item?.child?.map((child, index)=>{
            return(
              <CategoryMenu item={child} key={index}/>
            )
          })}
        </div> : null}
    </div>
  )
}