import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { site_ip } from '../../../config';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import "./news.css"
const News = () => {
    const params = useParams();
    const navigate = useNavigate();
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('endUserToken') },
    };
    useEffect(() => {
        getSubjects();
        getVideos();
    }, [])

    const [subjectList, setSubjectList] = useState([]);
    const [activeSubject, setActiveSubject] = useState(null);
    const [searchedString, setSearchString] = useState("");
    const [queryString, setQueryString] = useState("");
    const getSubjects = (id) => {
        axios
            .get(site_ip + '/getSubjects', jwtHeader)
            .then((res) => {
                setSubjectList(res.data.content);
            })
            .catch((err) => console.log(err));
    };

    const [videosList, setVideosList] = useState([]);
    const getVideos = () => {
        axios
            .get(site_ip + '/getVideoDatabase', jwtHeader)
            .then((res) => {
                setVideosList(res.data.Content);
            })
            .catch((err) => console.log(err));
    };

    const getVideosBySubject = (id) => {
        axios
            .get(site_ip + '/getVideoBySubject/'+id, jwtHeader)
            .then((res) => {
                setVideosList(res.data.Content);
            })
            .catch((err) => console.log(err));
    };

    // const searchMedicalCases = (subject_id) => {
    //     let req;
    //     if(subject_id !== null){
    //         setSearchString("");
    //         req = {
    //             subject_id: subject_id 
    //         }
    //     }else{
    //         setActiveSubject(null)
    //         if(queryString.trim() === ""){
    //             return 
    //         }
    //         req = {
    //             news_title: queryString.trim() 
    //         }
    //         setSearchString(queryString.trim())
    //     }
    //     axios
    //         .post(site_ip + '/searchMedicalCases', req)
    //         .then((res) => {
    //             setVideosList(res.data.Cases);
    //         })
    //         .catch((err) => console.log(err));
    // };

    

    return (
        <div>
            <Container>
                <Row style={{ marginTop: "30px" }}>
                    <Col md={3}>
                        <p style={{ fontWeight: "bold", fontSize: "15px", color: "#000000" }}>Subjects</p>
                        {subjectList.map((item, index) => {
                            return (
                                <button key={index} className="news-subject-item" onClick={() => {getVideosBySubject(item.subject_id); setActiveSubject(item)}} >{item.subject_name}</button>
                            )
                        })}
                    </Col>
                    <Col md={9}>
                        <Row style={{ margin: 0, marginBottom:"15px" }}>
                            <Col style={{display:"flex", alignItems:"center"}}>
                                <p style={{ fontWeight: "bold", fontSize: "15px", color: "#000000" }}>Videos</p>
                            </Col>
                            {/* <Col>
                                <div className="search-round-div">
                                    <input placeholder='Search videos...' value={queryString} onChange={(e) => setQueryString(e.target.value)} />
                                    <button style={{cursor:"pointer"}} onClick={() => searchMedicalCases(null)}>Search</button>
                                </div>
                            </Col> */}
                        </Row>
                        <Row style={{ margin: 0 }}>
                            <Row>
                                <Col xs={10}>
                                    {activeSubject !== null ? <p className="related-news-title">Showing result for <b>{activeSubject?.subject_name}</b></p> : null }
                                    {searchedString !== "" ? <p className="related-news-title">Showing result for <b>{searchedString}</b></p> : null }
                                </Col>
                                <Col xs={2}>
                                   {activeSubject !== null || searchedString !== "" ? <button style={{borderBottom:"0px"}} className="news-subject-item" onClick={() => {getVideos(); setActiveSubject(null); setSearchString("")}} >Clear</button> : null }
                                </Col>
                            </Row>
                            {videosList.map((item, index) => {
                                return (
                                    <Col key={index} md={6}>
                                        <VideosSubjectThumbnail item={item} />
                                    </Col>
                                )
                            })}
                            {videosList.length === 0 ? 
                            <div style={{height:"150px", display:"flex", alignItems:"center", justifyContent:'center'}}>
                                <p>No result found...</p>
                            </div> : null }
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default News;

const VideosSubjectThumbnail = (props) => {
    const item = props.item;
    const navigate = useNavigate();
    
    return (
        <div className='subject-thumbnail-div'>
            <Row>
                <Col md={12}>
                    <a href="/" onClick={(e) => { e.preventDefault(); navigate("/user/videos/" + item.video_id + "/" + item.video_title?.split(' ').join('_')) }}>
                        <div className='subject-thumbnail-img-div' style={{maxHeight:"180px"}}>
                            {item?.thumbnail_name !== null || item?.thumbnail_name !== ""}
                            <img src={item.thumbnail_name} style={{maxHeight:"180px", objectFit:"contain"}} />
                        </div>
                    </a>
                </Col>
                <Col md={12}>
                    <p className="suject-thumbnail-category">{item.subject_name}</p>
                    <p className="suject-thumbnail-name"><a href="/" onClick={(e) => { e.preventDefault(); navigate("/user/videos/" + item.video_id + "/" + item.video_title?.split(' ').join('_')) }}>{item.video_title}</a></p>
                    <div style={{ height: "20px" }}></div>
                    <div style={{ display: "flex" }}>
                        <div style={{ flex: 1 }}>
                            <p className="suject-thumbnail-category">Duration: <b>{item?.duration}</b></p>
                        </div>
                        <div style={{ flex: 1, textAlign: "right" }}>
                            <p className="suject-thumbnail-category">{item?.access_type_id}</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}