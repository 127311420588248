import React, { useState, useEffect, useRef  } from 'react';
import Header from "../../common/Header"
import Menu from "../../common/Menu"
import axios from 'axios';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { site_ip } from '../../../config';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import Footer from '../../../Components/Footer';


const CertificatePreview = () => {
const navigate = useNavigate();
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('endUserToken') },
    };
    useEffect(() => {
        let token = localStorage.getItem("endUserToken")
        if (token === null) {
          navigate("/user/auth/login")
        } else {
          try {
            let end_user = JSON.parse(localStorage.getItem("endUser"))
            
          } catch (e) {
            navigate("/user/auth/login")
          }
        }
      }, [])
    
    const params = useParams();

    const blankValues = {director: "",course_name:"" };
    const [values, setValues] = useState(blankValues)
    const handleChange =(e) => {
        let { name, value } = e.target;      
         setValues({ ...values, [name]: value })      
      }
      const storedData = localStorage.getItem('pdf_url');

     const downloadPdf = () => {
    const pdfUrl = storedData;

    axios
      .get(pdfUrl, {
        responseType: 'blob', // Set the response type to blob
      })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'downloaded_file.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(error => {
        console.error('Error downloading PDF:', error);
      });
  };
  
return (
    <div>
        <Container className='mt-5'>
        <Row>
            <Col md={2}></Col>
            <Col md={8}>
            <Row>
			<Col md={12}>
            <iframe  width="880"  height="554" src={storedData} ></iframe>
            </Col>
			
            </Row>
            <Row>
			  <Col md={12} className="m-5" align="center"><button type="button"  className="btn btn-primary"  onClick={downloadPdf}>Download</button></Col>
			</Row>
            </Col>
        </Row>
        </Container>
 <Footer />
    </div>
  )
}
export default CertificatePreview;
