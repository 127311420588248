import React, { useEffect, useState } from 'react';
import Header from "../../common/Header"
import Menu from "../../common/Menu"
import axios from 'axios';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { site_ip } from '../../../config';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import "./news.css";
const News = () => {

    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('endUserToken') },
    };
    const params = useParams();
    // const [isLiked, setIsLiked] = useState(false);
    const [related, setRelated] = useState([]);
    const [liked, setLiked] = useState(false)
    const navigate = useNavigate();
    useEffect(() => {
        if (params.id !== undefined) {
            getMedicalCaseById(params.id)
        }
    }, [params.id])

    const [medicalCasesDetails, setMedicalCasesDetails] = useState({});
    const getMedicalCaseById = (id) => {
        // setLiked(false)
        // let req = {
        //     news_id: id,
        //     user_id: parseInt(localStorage.getItem("user_id"))
        // }
        axios
            .get(site_ip + '/getMedicalCaseById/' + id, jwtHeader)
            .then((res) => {
                setMedicalCasesDetails(res.data.Content[0]);
                // updateViews(id)
                // setRelated(res.data.RelatedNews)
                window.scrollTo(0, 0)
            })
            .catch((err) => console.log(err));
    };

    return (
        <div>
            <Container>
                <Row style={{ margin: 0 }}>
                    <Col md={12}>
                        <div style={{ padding: "15px 0px 0px 0px" }}>
                            {medicalCasesDetails?.case_images_names !== null ?
                                <div className="imageBox">
                                    <img alt="news-image" src={medicalCasesDetails?.case_images_names} />
                                </div> : null}
                            <p className="news-thumbnail-category">{medicalCasesDetails?.category_name}</p>
                            <p className="news-title">{medicalCasesDetails?.case_name}</p>
                            {/* <p className="news-subtitle">{medicalCasesDetails?.news_subtitle}</p>
                        <div style={{ display: "flex", marginTop: "10px" }}>
                            {medicalCasesDetails?.author_name !== null && medicalCasesDetails?.author_name !== "" ?
                                <div style={{ display: 'flex', alignItems: 'center', marginRight: "20px" }}>
                                    <i className="fas fa-user-edit"></i>
                                    <p className='author-name'>{medicalCasesDetails?.author_name}</p>
                                </div> : null}
                            {medicalCasesDetails?.publish_date !== null && medicalCasesDetails?.publish_date !== "" ?
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <i class="fas fa-calendar-alt"></i>
                                    <p className='author-name'>{moment(medicalCasesDetails?.publish_date).format("DD, MMM YYYY")}</p>
                                </div> : null}
                        </div> */}
                            {/* <Row style={{ marginTop: "25px" }}>
                            <Col>
                                <div style={{ display: "flex" }}>
                                    <div className="count-div">
                                        <i class="fa fa-heart" aria-hidden="true" style={{ color: "#ff0000" }}></i>
                                        <p>{medicalCasesDetails?.likes_cnt + (liked ? 1 : 0)}</p>
                                    </div>
                                    <div className="count-div" style={{ marginLeft: "25px" }}>
                                        <i class="fa fa-eye" aria-hidden="true"></i>
                                        <p>{medicalCasesDetails?.views_cnt}</p>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    {medicalCasesDetails?.like_status === "Liked" || liked ?
                                        <button className="liked-btn">
                                            <FavoriteIcon style={{ color: "#ff0000" }} />
                                        </button> :
                                        <button className="like-btn" onClick={() => updateLike()}>
                                            <FavoriteBorderIcon style={{ color: "#ccc" }} />
                                        </button>}
                                </div>
                            </Col>
                        </Row> */}
                            <div style={{ marginTop: "10px", marginBottom: "0px" }}>
                                <div
                                    style={{ height: "100%" }}
                                    dangerouslySetInnerHTML={{ __html: medicalCasesDetails?.case_desc }} className="ck-content" />
                            </div>
                        </div>
                        <p className="related-news-title">References:</p>
                        <ul>
                            <li>
                                {medicalCasesDetails?.case_file_name !== undefined && medicalCasesDetails?.case_file_name !== null && medicalCasesDetails?.case_file_name !== "" ?
                                    <a href={medicalCasesDetails.case_file_name} style={{ fontSize: "12px", color: "#0000ff" }} >{medicalCasesDetails.case_file_name}</a> : null}
                            </li>
                        </ul>
                    </Col>
                    {/* <Col md={4}>
                   <p className="related-news-title">Related News</p> 
                   {related?.map((item, index)=>{
                        return(
                            <NewsThumbnail item={item} key={index} />
                        )
                   })}         
                </Col> */}
                </Row>
            </Container>
        </div>
    )
}

export default News;

