import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Container, Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import ReactPlayer from 'react-player'
import { site_ip } from '../config';

export default function WebinarList(props) {
  const [webinars, setWebinars] = useState([]);
  const [show, setShow] = useState(false);
  const [webinarURL, setWebinarURL] = useState("")
  const handleClose = () => setShow(false);
  const fetchWebinars = async () => {
    await axios
      .get(`${site_ip}/getCompletedWebinars`)
      .then((res) => {
        setWebinars(res.data.Webinars);
        console.log(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchWebinars();
  }, []);

  const registerWebinar = () => {
    if (localStorage.getItem('siteuser'))
      props.history.push('/lms/register-webinar');
    else props.history.push('/lms/signup');
  };

  const showWebinarModal = (url) =>{
    setWebinarURL(url);
    setShow(true);
  }

  return (
    <>
      <Row style={{ backgroundColor: '#0C66A6' }}>
        <Col></Col>
        <Col>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              alt="dynamism"
              src="/img/Monthly_Webinar.png"
              className="offerImages"
            />
          </div>
        </Col>
        <Col></Col>
      </Row>
      <br />
      <Row>
        {webinars?.map((item) => (
          <Col md={4} key={item.webinar_id}>
            <Card
              style={{
                border: '3px',
                margin: '10px',
                boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px"
              }}
            >
              <Card.Body>
                <div className="img">
                  <img
                    src={item.image_name}
                    alt="alt"
                    width="100%"
                    style={{
                      borderRadius: '5px',
                      cursor: 'pointer',
                      position: 'relative',
                                          }}
                  />
                  {/* <PlayCircleFilledIcon
                    sx={{ color: 'white', fontSize: 60 }}
                    style={{
                      top: '30%',
                      left: '50%',
                      position: 'absolute',
                      transform: 'translate(-50%, -50%)',
                    }}
                  /> */}
                </div>
                <br />
                <p style={{fontSize:"11px", marginBottom:"0px"}}>Speaker Name</p>
                <p style={{fontSize:"13px"}}><b>{item.speaker_name}</b></p>
                <p style={{fontSize:"11px", marginBottom:"0px"}}>Description</p>
                <p style={{fontSize:"13px"}}><b>{item.webinar_desc}</b></p>
                <div className="d-flex align-items-center justify-content-end">
                  <Button variant="warning" size="sm" onClick={() => showWebinarModal(item.webinar_filename)}>
                    Watch Now
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size={'lg'}
        >
          <Modal.Body style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
            <ReactPlayer url={webinarURL} controls={true} />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose} >Close</Button>
            </Modal.Footer>
        </Modal>
    </>
  );
}
