import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Button, Table } from "react-bootstrap";
import "./Home.css";
import axios from 'axios';
import { site_ip } from '../config';
import { useParams } from "react-router-dom";
import moment from "moment";
const FileList = () => {
    const params = useParams();
    const [valid, setValid] = useState(0);
    const [data, setData] = useState({})
    useEffect(() => {
        if (params?.id !== undefined) {
            getFiles(params?.id)
        }
    }, [])

    const getFiles = async (id) => {
        try {
            await axios
                .get(`${site_ip}/getFellowshipDocuments/` + id)
                .then((res) => {
                    console.log(res.data)
                    setData(res.data)
                    setValid(1)
                })
                .catch((err) =>
                    setValid(2)
                );
        } catch (e) {
            setValid(2)
        }
    }

    // https://lms.indiancollegeofphysicians.org/fellowship-docs/

    const showFileName = (str) => {
        try {
            let str_arr = str.split("/")
            return str_arr[str_arr?.length - 1]
        } catch (e) {
            console.log(e)
            return ""
        }

    }

    const renderJsonAttachments = () => {
        <tr>
            <td>
                <span>{showFileName(data?.Content?.letter_of_sanction)}</span>
            </td>
            <td>
                <a style={{ color: "#0000ff" }} href={data?.Content?.letter_of_sanction}>Download</a>
            </td>
        </tr>
    }

    return (
        <div className="container" style={{ marginTop: "15px" }}>
            {/* <!-------------Slider-section-start---------------> */}
            <section>
                <div>
                    <Row>
                        <Col md={3}></Col>
                        {valid === 1 ?
                            <Col md={6}>
                                <div className="apply_box">
                                    <div className="page-logo-div">
                                        <img src={"/images/ICP.jpg"} alt='demo' className="mt-2" />
                                    </div>
                                    <div className="title">
                                        <p className="page-heading">Indian College of Physicians</p>
                                    </div>
                                    <div style={{ padding: "15px", paddingBottom: "0px" }}>
                                        <Row>
                                            <Col md={6}>
                                                <span>Full Name</span>
                                                <p><b>{data?.Content?.full_name}</b></p>
                                            </Col>
                                            <Col md={6}>
                                                <span>API Membership No</span>
                                                <p><b>{data?.Content?.api_membership_no}</b></p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <span>User Email</span>
                                                <p><b>{data?.Content?.user_email}</b></p>
                                            </Col>
                                            <Col md={6}>
                                                <span>User Mobile</span>
                                                <p><b>{data?.Content?.user_mobile}</b></p>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div style={{ padding: "15px" }}>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>File Name</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data?.Content?.letter_of_sanction !== undefined && data?.Content?.letter_of_sanction !== null && data?.Content?.letter_of_sanction?.length !== undefined ?
                                                    data?.Content?.letter_of_sanction?.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    <span>{showFileName(item?.attachment)}</span>
                                                                </td>
                                                                <td>
                                                                    {/* <a target="_blank" style={{ color: "#0000ff", marginRight:"15px"}} href={item?.attachment}>View</a> */}
                                                                    <a download={true} style={{ color: "#0000ff" }} href={item?.attachment}>Download</a>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    : null}
                                                {data?.Content?.publication_attachment !== undefined && data?.Content?.publication_attachment !== null && data?.Content?.publication_attachment?.length !== undefined ?
                                                    data?.Content?.publication_attachment?.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    <span>{showFileName(item?.attachment)}</span>
                                                                </td>
                                                                <td>
                                                                    {/* <a target="_blank" style={{ color: "#0000ff", marginRight:"15px" }} href={item?.attachment}>View</a>     */}
                                                                    <a download={true} style={{ color: "#0000ff" }} href={item?.attachment}>Download</a>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    : null}
                                                {data?.Content?.title_page_abstract !== undefined && data?.Content?.title_page_abstract !== null && data?.Content?.title_page_abstract?.length !== undefined ?
                                                    data?.Content?.title_page_abstract?.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    <span>{showFileName(item?.attachment)}</span>
                                                                </td>
                                                                <td>
                                                                    {/* <a target="_blank" style={{ color: "#0000ff", marginRight:"15px" }} href={item?.attachment}>View</a>     */}
                                                                    <a download={true} style={{ color: "#0000ff" }} href={item?.attachment}>Download</a>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    : null}
                                                {data?.Docs?.map((item, index) => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <span>{showFileName(item)}</span>
                                                            </td>
                                                            <td>
                                                                {/* <a target="_blank" style={{ color: "#0000ff", marginRight:"15px" }} href={"https://icp-fellowship.s3.ap-south-1.amazonaws.com/" + item}>View</a> */}
                                                                <a download={true} style={{ color: "#0000ff" }} href={"https://icp-fellowship.s3.ap-south-1.amazonaws.com/" + item}>Download</a>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Col> : null}
                        {valid === 2 ?
                            <Col md={6}>
                                <div className="apply_box">
                                    <div className="page-logo-div">
                                        <img src={"/images/ICP.jpg"} alt='demo' className="mt-2" />
                                    </div>
                                    <div className="title">
                                        <p className="page-heading">Indian College of Physicians</p>
                                    </div>
                                    <p className="page-sub-heading" style={{ color: "#ff0000" }}>
                                        <b>Invalid link</b>
                                    </p>
                                </div>
                            </Col> : null}
                        {valid === 0 ?
                            <Col md={6}>
                                <div className="apply_box">
                                    <div className="page-logo-div">
                                        <img src={"/images/ICP.jpg"} alt='demo' className="mt-2" />
                                    </div>
                                    <div className="title">
                                        <p className="page-heading">Indian College of Physicians</p>
                                    </div>
                                    <p className="page-sub-heading" style={{ color: "#b1b1b1" }}>
                                        <b>validating link...</b>
                                    </p>
                                </div>
                            </Col> : null}
                        <Col md={3}></Col>
                    </Row>

                </div>
            </section >
        </div >
    );
};

export default FileList;
