import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Modal } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import Plus from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import Aux from '../../hoc/_Aux';
import { site_ip } from '../../globalSetting';

import SectionAssessmentQuestion from './SectionAssessmentQuestion';
import SectionAssessmentQuestionEdit from './SectionAssessmentQuestionEdit';
const Assessments = (props) => {
  
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('admin_token') },
      };

    const [activeSection, setActiveSection] = useState(1);
    const [show, setShow] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(0);
    const [editLesson, setEditLesson] = useState();
    const [questionList, setQuestionList] = useState([])
   
   
   useEffect(()=>{
    getCourseQuestion(props.id)
    // eslint-disable-next-line
   }, [])
   
   const getCourseQuestion = async(id) =>{
    if(id === 0) return
    await axios
    .get(site_ip + '/getCourseAssessment/'+id, jwtHeader)
    .then(function (response) {
        if(response.data.Lessons.length !== 0){
            setQuestionList(response.data.Lessons)
        }else{
            setQuestionList([])
        }
    })
    .catch(function (error) {
        console.log('error' + error);
    });
   }


   const handleClose = () => setShow(false);

   const removeRecord = async() => {
      

      let item = {
          section_id: selectedRecord
      }
      handleClose();
        await axios
        .get(site_ip + '/deleteCourseAssessment/'+selectedRecord, jwtHeader)
        .then(function (response) {
            Swal.fire({
                title: 'Success',
                text: 'Question removed successfully',
                icon: 'success',
            });
            getCourseQuestion(props.id);
        })
        .catch(function (error) {
            console.log('error' + error);
        });
   }

   const deleteEntry = (section_id, index) =>{
        setSelectedRecord(section_id);
        setShow(true);
        
   }

   const showCompleteList = () =>{
    setActiveSection(1)
   }

   return (
    <Aux>
      <div style={{padding:"15px"}}>
        <Row className="rangeBox" >
            <Col md={12} style={{height:"calc(100vh - 250px)", overflow:"scroll"}}>
                {activeSection === 1 ? 
                <div>
                    <Row>
                        <Col className="text-end" style={{marginTop:"15px"}}>
                            <Button variant="dark" onClick={()=> setActiveSection(2)}>
                                <Plus /> New Question
                            </Button>
                        </Col>
                    </Row>
                    <QuestionHeader />
                    {
                        questionList.map((item, index)=>{
                            return(
                                <QuestionList key={index} item={item} editLesson={() => {setEditLesson(item); setActiveSection(3)}} deleteEntry={(section_id) => deleteEntry(section_id, index)}/>
                            )
                        })
                    }
                </div>
                 : null }
                {activeSection === 2 ? 
                <div>   
                    <Row>
                        <Col className="text-end" style={{marginTop:"15px"}}>
                            <Button variant="dark" onClick={()=> setActiveSection(1)}>
                                Show Question List
                            </Button>
                        </Col>
                    </Row>
                    <SectionAssessmentQuestion course_id={props.id} refreshList={() => {setActiveSection(1); getCourseQuestion(props.id) }} /> 
                </div> : null }
                {activeSection === 3 ? 
                <div>   
                    <Row>
                        <Col className="text-end" style={{marginTop:"15px"}}>
                            <Button variant="dark" onClick={()=> setActiveSection(1)}>
                                Show Question List
                            </Button>
                        </Col>
                    </Row>  
                    <SectionAssessmentQuestionEdit item={editLesson} refreshList={() => {setActiveSection(1); getCourseQuestion(props.id) }} /> 
                </div> : null }
            </Col>
        </Row>
      </div>
      <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
          <Modal.Body>
            Are you sure to remove this record?
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={removeRecord}>
                Yes
            </Button>
            <Button variant="secondary" onClick={handleClose} >No</Button>
            </Modal.Footer>
        </Modal>
    </Aux>
  );
}

const TableHeader = () =>{

    return(
        <Row className="tableRow" style={{paddingTop:"5px", paddingBottom:"5px"}}>
            <Col style={{fontWeight:'bold'}}>
                Lesson Name
            </Col>
        </Row>
    )
}



const LessonItem = (props) =>{
    const [item, setItem] = useState(props.item);

    useEffect(()=>{
        setItem(props.item)
    }, [props.item])
    
    return(
        <Row className="tableRow">
            <Col style={{paddingTop:"10px", paddingBottom:"10px"}}>
                <a style={{color: props.activeLesson === props.item.lesson_id ? "#1f96d3" : "#000000"}} href="/" onClick={(e) => {e.preventDefault(); props.activeThisLesson(props.item.lesson_id, props.item.section_id)}} >{item.lesson_name}</a>
            </Col>
        </Row>
    )
}

const QuestionHeader = () =>{

    return(
        <Row className="tableRow" style={{paddingTop:"5px", paddingBottom:"5px"}}>
            <Col style={{fontWeight:'bold'}}>
                Question
            </Col>
        </Row>
    )
}

const QuestionList = (props) =>{
    const [item, setItem] = useState(props.item);

    useEffect(()=>{
        setItem(props.item)
    }, [props.item])
    
    const renderQuestion = (text) => {
        return(
            <div
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            />
        )
    };
    return(
        <Row className="tableRow">
            <Col xs={9} style={{display:"flex", alignItems:'center'}}>
                {renderQuestion(item?.question)}
            </Col>
            <Col xs={3} style={{display:'flex', alignItems:'center', justifyContent:'center', padding:"5px"}}>
                <Button variant="success" className="rowActionButton" onClick={()=> props.editLesson()}>
                   <EditIcon />
                </Button>
                <Button variant="danger" className="rowActionButton" onClick={()=> props.deleteEntry(item?.question_id)}>
                   <DeleteForeverIcon />
                </Button>    
            </Col>
        </Row>
    )
}

export default Assessments 
