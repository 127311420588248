import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import API from '../API';

export default function CourseDetails(props) {
  const params = useParams();
  const [course, setCourse] = useState([]);
  const handleCourse = async (course_id) => {
    try {
      setCourse(await API.fetchCourseById(course_id));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleCourse(params.course_id);
    //eslint-disable-next-line
  }, []);
  return (
    <Container>
      <br />
      <div
        style={{
          backgroundImage:
            'url(https://d3030h7whein66.cloudfront.net/Images/Associations/DRL/DRL0010/Dental+1.jpg)',
          height: '200px',
          width: '100%',
          borderRadius: '5px',
        }}
      >
        <div className="row">
          <div className="col-md-7 p-4">
            <h1>{course.course_title}</h1>
            <p>Dec 11, 04:00 PM</p>
            <div>
              <h5>Ongoing</h5>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div style={{ textAlign: 'center' }}>
        <h2>About This Course</h2>
        <p>
          The 1st module of the course has a unique content structure that will
          enable you to get a thorough understanding of single sitting root
          canal treatment, management of complications in implantology and
          aesthetics in anterior restorations. This course is designed such that
          it will provide clinical tips and advices which thereby will help
          enhance your skills.
        </p>
      </div>
      <br />
      <br />
      <div>
        <h2 style={{ textAlign: 'center' }}>Meet the Speaker</h2>
        <h2>Certified by:</h2>
        <p>
          The above faculties have indicated they have no relationship which, in
          the context of their presentations, could be perceived as a potential
          conflict of interest.
        </p>
        <h2>Certification Benefit</h2>
      </div>
    </Container>
  );
}
