import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { site_ip } from '../../../config';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import Footer from '../../../Components/Footer';
import HTMLFlipBook from "react-pageflip";
import { pdfjs, Document, Page as ReactPdfPage } from "react-pdf";
import Iframe from 'react-iframe';

import FlipBook from '../../../Components/FlipBook';
import "./news.css";
const News = () => {
    const [folder, setFolder] = useState('');
    const [related, setRelated] = useState([]);
    const [liked, setLiked] = useState(false)

    const images =[
        `/images/${folder}/img-01.png`,
        `/images/${folder}/img-02.png`,
        `/images/${folder}/img-03.png`,
        `/images/${folder}/img-04.png`,
        `/images/${folder}/img-05.png`,
        `/images/${folder}/img-06.png`,
        `/images/${folder}/img-07.png`,
        `/images/${folder}/img-08.png`,
        `/images/${folder}/img-09.png`,
        `/images/${folder}/img-10.png`,
        `/images/${folder}/img-11.png`,
        `/images/${folder}/img-12.png`,
        `/images/${folder}/img-13.png`,
        `/images/${folder}/img-14.png`,
        `/images/${folder}/img-15.png`,
        `/images/${folder}/img-16.png`,
        `/images/${folder}/img-17.png`,
        `/images/${folder}/img-18.png`,

      ];

    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('endUserToken') },
    };
    const params = useParams();

    // const [isLiked, setIsLiked] = useState(false);
    
    const navigate = useNavigate();
    useEffect(() => {
        if (params.id !== undefined) {
            getNewsByNewsId(params.id)
        }
    }, [params.id])

    const [newsDetails, setNewsDetails] = useState({});
    const getNewsByNewsId = (id) => {
        setLiked(false)
        let req = {
            news_id: id,
            user_id: parseInt(localStorage.getItem("user_id") !== null ? localStorage.getItem("user_id") : 0)
        }
        axios
            .post(site_ip + '/getNewsByNewsId', req, jwtHeader)
            .then((res) => {

                console.log(res.data.News[0].flipbook_folder)
                setFolder(res.data.News[0].flipbook_folder)
                setNewsDetails(res.data.News[0]);
                // if (localStorage.getItem("user_id") !== null) {
                //     updateViews(id)
                // }
                setRelated(res.data.RelatedNews)
                window.scrollTo(0, 0)
            })
            .catch((err) => console.log(err));
    };

    const updateViews = (id) => {
        axios
            .get(site_ip + '/setNewsViews/' + id, jwtHeader)
            .then((res) => {

            })
            .catch((err) => console.log(err));
    }

    const updateLike = (id) => {

        if (localStorage.getItem("user_id") !== null) {
            let req = {
                user_id: parseInt(localStorage.getItem("user_id")),
                news_id: newsDetails.news_id
            };

            axios
                .post(site_ip + '/setNewsLikes', req, jwtHeader)
                .then((res) => {
                    setLiked(true)
                })
                .catch((err) => console.log(err));
        } else {
            navigate("/user/auth/login", { state: { id: newsDetails.news_id, name: newsDetails?.news_title?.split(' ').join('_') } })
        }
    }
    return (
        <div>
            <div className="box p-3 m-3">
                <Row style={{ margin: 0 }}>
                    <Col md={12}>
                        <div style={{ padding: "15px 0px 150px 0px" }}>
                            {newsDetails?.thumbnail !== null ?
                                <div className="imageBox">
                                    <img alt="news-image" src={newsDetails?.thumbnail} />
                                </div> : null}
                            <div style={{display:'flex', alignItems:'flex-end', justifyContent:'flex-end'}}>
                                <button onClick={() => window.open(newsDetails?.pdf_file_name, '_blank')}>Open/Download as PDF</button>    
                            </div>    
                            <p className="news-thumbnail-category">{newsDetails?.category_name}</p><span className="" style={{fontSize:'14px'}}>{newsDetails?.news_title}</span>
                            <p className="" style={{fontSize:"10px" , margin:"0px"}}>{newsDetails?.news_subtitle}</p>
                            <div style={{ display: "flex", marginTop: "" }}>
                                {newsDetails?.author_name !== null && newsDetails?.author_name !== "" ?
                                    <div style={{ display: 'flex', alignItems: 'center', marginRight: "" }}>
                                        <i className="fas fa-user-edit"></i>
                                        <p className='author-name'>{newsDetails?.author_name}</p>
                                    </div> : null}
                                {newsDetails?.publish_date !== null && newsDetails?.publish_date !== "" ?
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <i class="fas fa-calendar-alt"></i>
                                        <span className='author-name'>{moment(newsDetails?.publish_date).format("DD, MMM YYYY")}</span>
                                    </div> : null}
                            </div>
                            <Row style={{display:'flex'}} >
                                
                                    <div style={{ width:"150px",display: "flex" ,justifyContent:"space-evenly"}}>
                                        
                                        <div className="count-div" >
                                            <i class="fa fa-heart" aria-hidden="true" style={{ color: "#ff0000" }}></i>
                                            <p>{newsDetails?.likes_cnt + (liked ? 1 : 0)}</p>
                                        </div>
                                        <div className="count-div" style={{ marginLeft: "2px" }}>
                                            <i class="fa fa-eye" aria-hidden="true"></i>
                                            <p>{newsDetails?.views_cnt}</p>
                                        </div>
                                        
                                        
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        {newsDetails?.like_status === "Liked" || liked ?
                                            <button className="liked-btn">
                                                <FavoriteIcon style={{ color: "#ff0000" }} />
                                                {/* Like */}
                                            </button> :
                                            <button className="like-btn" onClick={() => updateLike()}>
                                                <FavoriteBorderIcon style={{ color: "#ccc" }} />
                                                {/* Liked */}
                                            </button>}
                                    </div>
                                    </div>
                                   
                            </Row>

                                <FlipBook
                                    images={images}
                                    bucketName={""}
                                />
                            {/* <Iframe url={"https://online.flippingbook.com/view/682079806/"}
                                    width="100%"
                                    height="800px"
                                    id=""
                                    className=""
                                    display="block"
                                    position="relative"/>     */}
                            {/* {newsDetails?.pdf_file_name !== undefined && newsDetails?.pdf_file_name !== "" ? 
                            <PdfPage newsDetails={newsDetails?.pdf_file_name} /> : null } */}
                        </div>
                    </Col>
                    {/* <Col md={4}>
                        <p className="related-news-title">Related News</p>
                        {related?.map((item, index) => {
                            return (
                                <NewsThumbnail item={item} key={index} />
                            )
                        })}
                    </Col> */}
                </Row>
            </div>
            <Footer />
        </div>
    )
}

export default News;

const NewsThumbnail = (props) => {
    const item = props.item;
    const navigate = useNavigate();
    const location = useLocation();
    const navigateToPage = () => {
        if (location?.pathname === '/user/news') {
            navigate("/user/news/" + item.news_id + "/" + item.news_title?.split(' ').join('_'))
        } else {
            navigate("/news/" + item.news_id + "/" + item.news_title?.split(' ').join('_'))
        }
    }
    return (
        <div className='news-thumbnail-div'>
            <Row>
                <Col md={12}>
                    <a href="/" style={{ fontSize: "13px" }} onClick={(e) => { e.preventDefault(); navigateToPage(); }}>
                        <div className='news-thumbnail-img-div'>
                            {item?.thumbnail !== null ?
                                <img src={item?.thumbnail} alt="news_image" /> : null}
                        </div>
                    </a>
                </Col>
                <Col md={12}>
                    <p className="news-thumbnail-category">{item.subject_name}</p>
                    <p className="news-thumbnail-name" ><a href="/" style={{ fontSize: "13px" }} onClick={(e) => { e.preventDefault(); navigateToPage(); }}>{item.news_title}</a></p>
                    <Row>
                        <Col md={6}>
                            <div>
                                {/* {item?.author_name !== null && item?.author_name !== "" ?
                                    <div style={{ display: 'flex', alignItems: 'center', marginRight: "20px" }}>
                                        <i className="fas fa-user-edit"></i>
                                        <p className='author-name'>{item?.author_name}</p>
                                    </div> : null} */}
                                {item?.publish_date !== null && item?.publish_date !== "" ?
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <i class="fas fa-calendar-alt"></i>
                                        <p className='author-name'>{moment(item?.publish_date).format("DD, MMM YYYY")}</p>
                                    </div> : null}
                            </div>
                        </Col>
                        <Col md={6}>
                            <div style={{ display: "flex" }}>
                                <div className="count-div">
                                    <i class="fa fa-heart" aria-hidden="true" style={{ color: "#ff0000" }}></i>
                                    <p>{item?.likes_cnt}</p>
                                </div>
                                <div className="count-div" style={{ marginLeft: "25px" }}>
                                    <i class="fa fa-eye" aria-hidden="true"></i>
                                    <p>{item?.views_cnt}</p>
                                </div>
                            </div>
                        </Col>

                    </Row>
                </Col>
            </Row>
        </div>
    )
}





// const samplePDF = "https://derma-news.s3.ap-south-1.amazonaws.com/RSSDI_Newsletter.pdf";
const samplePDF = "https://icp-videos.s3.amazonaws.com/ICP_Newsletter.pdf";


const PdfPage = (props) => {
    const book = useRef();
    const [numPages, setNumPages] = useState(null);
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const [bookWidth, setBookWidth] = useState(window.screen.width * 0.4);
    const [bookHeight, setBookHeight] = useState(window.screen.width * 0.6);
    const [zoom, setZoom] = useState(1)

    const zoomIn = () => {
        let current_zoom = zoom + 1;
        setZoom(current_zoom)
        setBookWidth(window.screen.width * 0.4 * current_zoom)
    }

    const zoomOut = () => {
        let current_zoom = zoom - 1;
        setZoom(current_zoom)
        setBookWidth(window.screen.width * 0.4 * current_zoom)
    }

    const Page = React.forwardRef(({ pageNumber }, ref) => {
        return (
            <div ref={ref}>
                <ReactPdfPage pageNumber={pageNumber} width={bookWidth} />
            </div>
        );
    });

    const [pageSelection, setPageSelection] = useState([]);

    useEffect(() => {
        const selection = [];
        for (let i = 1; i <= numPages; i++) {
            selection.push(i);
        }
        setPageSelection(selection);
    }, [numPages])

    const onDocumentLoadSuccess = (pdf) => {
        setNumPages(pdf?.numPages);
    }


    return (
        <div style={{position:"relative"}}>
            <button className="flip_nav" style={{position:"absolute", left:"0px", top:"45%"}} onClick={() =>
            book.current.pageFlip().flipPrev()}>
                <i class="fas fa-chevron-left"></i>
            </button>
            <Document file={props?.newsDetails} onLoadSuccess={(e) => onDocumentLoadSuccess(e)}>
                <HTMLFlipBook ref={book} width={bookWidth} height={bookHeight} style={{ margin: 'auto' }}>
                    {pageSelection && pageSelection.map(function (cur, idx) {
                        return <Page pageNumber={cur} key={idx} />
                    })}
                </HTMLFlipBook>
            </Document>
            <button className="flip_nav" style={{position:"absolute", right:"0px", top:"45%"}} onClick={() =>
                    book.current.pageFlip().flipNext()}>
                        <i class="fas fa-chevron-right"></i>
                    </button>
            {/* <button onClick={() => zoomOut()}>Zoom Out</button>
            <button onClick={() => zoomIn()}>Zoom In</button> */}
        </div>
    );
}

