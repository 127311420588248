import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Button, Table } from "react-bootstrap";
import "./Home.css";
import axios from 'axios';
import { site_ip } from '../config';
import { useParams } from "react-router-dom";
import moment from "moment";
const Home = () => {
    const params = useParams();
    const [valid, setValid] = useState(0);
    const [data, setData] = useState({});
    useEffect(() => {
        if (params?.id !== undefined) {
            validateLink(params?.id)
        }
    }, [])

    const blankValues = {
        reason_for_recommendation: "",
        city_name: "",
        acceptance_date: moment(new Date()).format("YYYY-MM-DD"),
        proposer_decision: "Accept",
        proposer_email:"",
        user_email:"",
    }

    const [values, setValues] = useState(blankValues)
    const handleChange = (e) => {
        let { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    const validateLink = async (id) => {
        try {
            await axios
                .get(`${site_ip}/checkProposerLink/` + id)
                .then((res) => {
                    // console.log(res)
                    setData(res.data)
                    setValid(1)
                })
                .catch((err) =>
                    setValid(2)
                );
        } catch (e) {
            setValid(2)
        }
    }

    const declineRequest = async () => {
        let request = {
            proposer_id: data?.Content?.proposer_id,
            proposer_decision: "Reject",
            proposer_email:data?.Content?.proposer_email,
            user_email:data?.MainData?.user_email,
        }
        try {
            await axios
                .post(
                    `${site_ip}/fellowshipProposerAccept`,
                    request
                )
                .then((res) => {
                    setValid(3)
                })
                .catch((err) => console.log(err));
        } catch (e) {

        }
    }

    const acceptRequest = async () => {
        let req = { ...values }
        if (req.reason_for_recommendation.trim() === "") {
            alert("Please add reason for recommendation.")
            return
        } else if (req.city_name.trim() === "") {
            alert("Please add city name.")
            return
        }

        let request = { 
            ...req, 
            proposer_id: data?.Content?.proposer_id,
            proposer_email:data?.Content?.proposer_email,
            user_email:data?.MainData?.user_email,
            proposer_decision: "Accept",
        }
        try {
            await axios
                .post(
                    `${site_ip}/fellowshipProposerAccept`,
                    request
                )
                .then((res) => {
                    setValid(3)
                })
                .catch((err) => console.log(err));
        } catch (e) {

        }
    }
    return (
        <div className="container" style={{ marginTop: "15px" }}>
            {/* <!-------------Slider-section-start---------------> */}
            <section>
                <div>
                    <Row>
                        <Col md={3}></Col>
                        {valid === 1 ?
                            <Col md={6}>
                                <div className="apply_box">
                                    <div className="page-logo-div">
                                        <img src={"/images/ICP.jpg"} alt='demo' className="mt-2" />
                                    </div>
                                    <div className="title">
                                        <p className="page-heading">Indian College of Physicians</p>
                                    </div>
                                    <p className="page-sub-heading">
                                        <b>Request to propose and recommend</b>
                                    </p>
                                    <div className="flex center" style={{ display: "flex" }}>
                                        <button className="accept-btn" onClick={() => setValid(4)}>Accept</button>
                                    </div>
                                    <div className="flex center" style={{ display: "flex" }}>
                                        <button className="decline-btn" onClick={() => declineRequest()}>Decline</button>
                                    </div>
                                </div>
                            </Col> : null}
                        {valid === 2 ?
                            <Col md={6}>
                                <div className="apply_box">
                                    <div className="page-logo-div">
                                        <img src={"/images/ICP.jpg"} alt='demo' className="mt-2" />
                                    </div>
                                    <div className="title">
                                        <p className="page-heading">Indian College of Physicians</p>
                                    </div>
                                    <p className="page-sub-heading" style={{ color: "#ff0000" }}>
                                        <b>Invalid link</b>
                                    </p>
                                </div>
                            </Col> : null}
                        {valid === 0 ?
                            <Col md={6}>
                                <div className="apply_box">
                                    <div className="page-logo-div">
                                        <img src={"/images/ICP.jpg"} alt='demo' className="mt-2" />
                                    </div>
                                    <div className="title">
                                        <p className="page-heading">Indian College of Physicians</p>
                                    </div>
                                    <p className="page-sub-heading" style={{ color: "#b1b1b1" }}>
                                        <b>validating link...</b>
                                    </p>
                                </div>
                            </Col> : null}
                        {valid === 3 ?
                            <Col md={6}>
                                <div className="apply_box">
                                    <div className="page-logo-div">
                                        <img src={"/images/ICP.jpg"} alt='demo' className="mt-2" />
                                    </div>
                                    <div className="title">
                                        <p className="page-heading">Indian College of Physicians</p>
                                    </div>
                                    <p className="page-sub-heading" style={{ color: "#000000" }}>
                                        <b>Thanks for your response.</b>
                                    </p>
                                </div>
                            </Col> : null}
                        {valid === 4 ?
                            <Col md={6}>
                                <div className="apply_box">
                                    <div className="page-logo-div">
                                        <img src={"/images/ICP.jpg"} alt='demo' className="mt-2" />
                                    </div>
                                    <div className="title">
                                        <p className="page-heading">Indian College of Physicians</p>
                                    </div>
                                    <Row style={{ margin: 0 }}>
                                        <Col md={6} xs={12} className="border-all" style={{ outline: 0, marginBottom: "10px" }}>
                                            <p>Name of Applicant:<span className="required">*</span></p>
                                            <input type="text" className="form-input" style={{ backgroundColor: "#ccc", margin: 0 }} readOnly value={data?.MainData?.full_name} onChange={(e) => console.log("")}></input>
                                        </Col>
                                        <Col md={6} xs={12} className="border-all" style={{ outline: 0, marginBottom: "10px" }}>
                                            <p>Reference No.</p>
                                            <p style={{marginTop:"10px"}}><b>{data?.MainData?.fellowship_id}</b></p>
                                        </Col>
                                        <Col xs={12} className="border-all" style={{ outline: 0, marginBottom: "10px" }}>
                                            <p>Academic and Professional Achievements</p>
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Degree</th>
                                                        <th scope="col">Year of Passing</th>
                                                        <th scope="col">Institute</th>
                                                        <th scope="col">University</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data?.Qualification?.map((item, index) => {
                                                        if(item?.degree_name.trim() === ""){
                                                            return null
                                                        }else{
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{item?.degree_name}</td>
                                                                    <td>{item?.year_of_passing}</td>
                                                                    <td>{item?.institute}</td>
                                                                    <td>{item?.university}</td>
                                                                </tr>
                                                            ) 
                                                        }  
                                                    })}
                                                </tbody>
                                            </table>
                                        </Col>
                                        <Col xs={12} className="border-all" style={{ outline: 0, marginBottom: "10px" }}>
                                            <p>Reason for recommendation:<span className="required">*</span></p>
                                            <textarea className="" name="reason_for_recommendation" onChange={handleChange} value={values?.reason_for_recommendation} />
                                        </Col>
                                        <Col xs={12} className="border-all" style={{ outline: 0, marginBottom: "10px" }}>
                                            <p>Name of Proposer:<span className="required">*</span></p>
                                            <input type="text" className="form-input" style={{ backgroundColor: "#ccc", margin: 0 }} readOnly value={data?.Content?.proposer_name} onChange={(e) => console.log("")}></input>
                                        </Col>
                                        <Col xs={12} className="border-all" style={{ outline: 0, marginBottom: "10px" }}>
                                            <p>City:<span className="required">*</span></p>
                                            <input type="text" className="form-input" style={{ margin: 0 }} name="city_name" value={values?.city_name} onChange={handleChange}></input>
                                        </Col>
                                        <Col xs={12} className="border-all" style={{ outline: 0, marginBottom: "10px" }}>
                                            <p>Date:<span className="required">*</span></p>
                                            <input type="date" className="form-input" style={{ backgroundColor: "#ccc", margin: 0 }} readOnly name="reason_for_recommendation" value={values?.acceptance_date} onChange={handleChange}></input>
                                        </Col>
                                        <Col className="p-3">
                                            <Button onClick={() => acceptRequest()}>Submit</Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col> : null}
                        <Col md={3}></Col>
                    </Row>

                </div>
            </section >
        </div >
    );
};

export default Home;
