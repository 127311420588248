import React, { useState, useEffect } from 'react';
import { Container, Button, Col, Form, Row, Card } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';

import Aux from '../../hoc/_Aux';
import { site_ip } from '../../globalSetting';
import API from '../API';
import { useNavigate } from 'react-router-dom';
export default function AddInstitution(props) {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    publisher_id: '',
    institute_name: '',
    institute_code: '',
    site_admin_email: '',
    street_number: '',
    city: '',
    pin_code: '',
    country_id: '',
    state_id: '',
    phone: '',
    email: '',
    site_logo: '',
    comments: '',
    subscription_type: '',
    display_status: '1',
    inst_catg_type: '',
  });

  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [publisher, setPublisher] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === 'country_id') {
      fetchStateData(value);
    }
  };

  const fetchCountryData = async () => {
    try {
      setCountry(await API.fetchCountryList());
    } catch (error) {
      console.log(error);
    }
  };
  const fetchStateData = async (countryid) => {
    try {
      setState(await API.fetchStateByCountry(countryid));
    } catch (error) {
      console.log(error);
    }
  };
  const fetchPublisherData = async () => {
    try {
      setPublisher(await API.fetchPublishers());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCountryData();
    fetchPublisherData();
  }, []);

  const proceed = async () => {
    const jwtHeader = {
      headers: { 'access-token': localStorage.getItem('admin_token') },
    };
    if (
      values.institute_name === '' ||
      values.site_admin_email === '' ||
      values.pin_code === '' ||
      values.country_id === '' ||
      values.publisher_id === '' ||
      values.state_id === '' ||
      values.phone === '' ||
      values.subscription_type === ''
    )
      alert('Please fill all the fields');
    else {
      await axios
        .post(site_ip + '/createInstitution', values, jwtHeader)
        .then(function (response) {
          console.log(response);
          if (response.data.Status === 200) {
            Swal.fire({
              title: 'Success',
              text: 'New Institution Created Successfully',
              icon: 'success',
            });
            navigate('/admin/manage-institutions');
          } else {
            alert('Request Failed');
          }
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    }
  };

  return (
    <Aux>
      <Container fluid>
        <br />
        <Card>
          <Card.Header>
            <Card.Title as="h5">Add Institution</Card.Title>
          </Card.Header>
          <Card.Body>
        <Form>
          <Row>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Institution Name</Form.Label>
                <Form.Control
                  type="text"
                  className="mb-3"
                  name="institute_name"
                  value={values.institute_name}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Institution Code</Form.Label>
                <Form.Control
                  type="text"
                  className="mb-3"
                  name="institute_code"
                  value={values.institute_code}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Site Admin Email</Form.Label>
                <Form.Control
                  type="email"
                  className="mb-3"
                  name="site_admin_email"
                  value={values.site_admin_email}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Site Logo</Form.Label>
                <Form.Control
                  type="file"
                  className="mb-3"
                  name="site_logo"
                  value={values.site_logo}
                  onChange={handleChange}
                  accept="image/png, image/jpeg"
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Publisher</Form.Label>
                <Form.Control
                  as="select"
                  className="mb-3"
                  name="publisher_id"
                  value={values.publisher_id}
                  onChange={handleChange}
                >
                  <option value="">Select Publisher</option>
                  {publisher?.map((value) => (
                    <option key={value.publisher_id} value={value.publisher_id}>
                      {value.publisher_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Country</Form.Label>
                <Form.Control
                  as="select"
                  className="mb-3"
                  name="country_id"
                  value={values.country_id}
                  onChange={handleChange}
                >
                  <option value="">Select Country</option>
                  {country?.map((value) => (
                    <option key={value.country_id} value={value.country_id}>
                      {value.country_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>State</Form.Label>
                <Form.Control
                  as="select"
                  className="mb-3"
                  name="state_id"
                  value={values.state_id}
                  onChange={handleChange}
                >
                  <option value="">Select State</option>
                  {state?.map((value) => (
                    <option key={value.state_id} value={value.state_id}>
                      {value.state_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Subscription Type</Form.Label>
                <Form.Control
                  as="select"
                  className="mb-3"
                  name="subscription_type"
                  value={values.subscription_type}
                  onChange={handleChange}
                >
                  <option value="">Select Subscription</option>
                  <option value="Trial">Trial</option>
                  <option value="Perpetual">Perpetual</option>
                  <option value="Subscription">Subscription</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Display Status</Form.Label>
                <Form.Control
                  as="select"
                  className="mb-3"
                  name="display_status"
                  value={values.display_status}
                  onChange={handleChange}
                >
                  <option value="">Select Subscription</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Institute Type</Form.Label>
                <Form.Control
                  as="select"
                  className="mb-3"
                  name="inst_catg_type"
                  value={values.inst_catg_type}
                  onChange={handleChange}
                >
                  <option value="">Select Type</option>
                  <option value="Institution">Institution</option>
                  <option value="Corporate">Corporate</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Street Number</Form.Label>
                <Form.Control
                  type="text"
                  className="mb-3"
                  name="street_number"
                  value={values.street_number}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  className="mb-3"
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Pin Code</Form.Label>
                <Form.Control
                  type="text"
                  required
                  name="pin_code"
                  value={values.pin_code}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  className="mb-3"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label> Email</Form.Label>
                <Form.Control
                  type="email"
                  className="mb-3"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Comments</Form.Label>
                <Form.Control
                  type="text"
                  className="mb-3"
                  name="comments"
                  value={values.comments}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <br />
          <Button onClick={proceed}>Submit</Button>
        </Form>
        </Card.Body>
        </Card>
      </Container>
    </Aux>
  );
}
