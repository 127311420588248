import React, { useState, useEffect } from 'react';
import { Container, Col, Form, Row, Modal } from 'react-bootstrap';
import Header from "../../common/Header"
import Menu from "../../common/Menu";
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { site_ip } from '../../../config';

import AddIcon from '@mui/icons-material/Add';
import API from '../../API';
import "../../user.css";
export default function QuestionList(props) {
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('endUserToken') },
    };

    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const blankValues = {
        course_id: '',
        subject_id: '',
        topic_id: '',
        difficulty_level_id: '',
        question_id: '',
        question: '',
        question_count: 5
    }

    const [values, setValues] = useState(blankValues);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (name === 'subject_id') fetchTopicData(value);
    };

    const [subjects, setSubjects] = useState([]);
    const fetchSubjects = async () => {
        try {
            setSubjects(await API.fetchSubjects());
        } catch (error) {
            console.log(error);
        }
    };

    const [courses, setCourses] = useState([]);
    const fetchCourses = async () => {
        try {
            setCourses(await API.fetchCourses());
        } catch (error) {
            console.log(error);
        }
    };
    const [topics, setTopics] = useState(null);
    const fetchTopicData = async (subject_id) => {
        try {
            setTopics(await API.fetchSubjectTopics(subject_id));
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchSubjects();
        fetchCourses();
    }, [])

    const getQuestions = (req) => {
        axios
            .post(site_ip + '/searchQuestionBank', req, jwtHeader)
            .then((res) => {
                navigate('/user/assessment/instruction', {
                    state: { data: res.data.Questions, test_type: 'Custom' },
                });
            })
            .catch((err) => console.log(err));
    };

    return (
        <div>
            <Container>
                <div className='new-content-section'>
                    <Row className="underline" style={{margin:0}}>
                        <Col className="page-title-container" style={{padding:0}}>
                            <p>Assessment Setup</p>
                        </Col>
                    </Row>
                    <Row className="assessment-setup-box">
                        <Col md={12}>
                            <p>Nos. of Questions</p>    
                            <Form.Control
                                as="select"
                                name="question_count"
                                onChange={handleChange}
                                value={values.question_count}
                            >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                                <option value={20}>20</option>
                            </Form.Control>
                        </Col>
                        <Col md={12}>
                            <p>Select Course</p>    
                            <Form.Control
                                as="select"
                                name="course_id"
                                onChange={handleChange}
                                value={values.course_id}
                            >
                                <option value="">Select Course</option>
                                {courses?.map((item, key) => (
                                    <option key={key} value={item.course_id}>
                                        {item.course_title}
                                    </option>
                                ))}
                            </Form.Control>
                        </Col>
                        <Col md={12}>    
                            <p>Select Subject</p>
                            <Form.Control
                                as="select"
                                name="subject_id"
                                onChange={handleChange}
                                value={values.subject_id}
                            >
                                <option value="">Select Subject</option>
                                {subjects?.map((item, key) => (
                                    <option key={key} value={item.subject_id}>
                                        {item.subject_name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Col>
                        <Col md={12}>    
                            <p>Select Topic</p>
                            <Form.Control
                                as="select"
                                name="topic_id"
                                onChange={handleChange}
                                value={values.topic_id}
                            >
                                <option value="">Select Topic</option>
                                {topics?.map((item, key) => (
                                    <option key={key} value={item.topic_id}>
                                        {item.topic_name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Col>
                        <Col md={12} style={{display:"flex", marginTop:"15px", marginBottom:"15px"}}>    
                            <button onClick={() => getQuestions(values)} className="primary-plus-button">Start</button>
                            <div className='spacer' />
                            <button onClick={() => { getQuestions(blankValues); setValues(blankValues) }} className="secondary-button">Reset</button>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}