import { Upload } from '@aws-sdk/lib-storage';
import { S3Client } from '@aws-sdk/client-s3';
// import { Buffer } from "buffer";
window.Buffer = window.Buffer || require("buffer").Buffer;
export const fileUpload = async (attachment, folder) => {
    console.log(attachment)
    const s3_url =
        'https://icp-fellowship.s3.ap-south-1.amazonaws.com/' + folder +'/'+
        attachment.name;
    const target = { Bucket: 'icp-fellowship', Key: folder +'/'+attachment.name, Body: attachment };
    // const creds = {
    //     accessKeyId: 'AKIATT4SF2SQIHAF7MUJ',
    //     secretAccessKey: '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',
    // };

    const creds = {
        accessKeyId: 'AKIATT4SF2SQGWSV3VY5',
        secretAccessKey: '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',
    };
    try {
        const parallelUploads3 = new Upload({
            client: new S3Client({ region: 'ap-south-1', credentials: creds }),
            leavePartsOnError: false, // optional manually handle dropped parts
            params: target,
        });

        parallelUploads3.on('httpUploadProgress', (progress) => {
            // console.log(progress, 'prajakta')
            // console.log(progress,'rahul');
        });

        await parallelUploads3.done();
        return s3_url;

    } catch (e) {
        console.log(e);
        return "image not found."
    }

    // 

}

