import React, { useEffect, useState } from "react"
import Header from "../../common/Header"
import Menu from "../../common/Menu";
import axios from 'axios';
import { site_ip } from '../../../config';
import { Form, Col, Row, Button } from "react-bootstrap";
import "../Books/book.css";
import "./forum.css";
import { useParams, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
const CourseDetails = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [courseDetails, setCourseDetails] = useState({})
    const [sectionList, setSectionList] = useState([])
    const [lessonList, setLessonList] = useState([]);
    const [activeLessons, setActiveLessons] = useState([]);
    const [activeSectionId, setActiveSectionId] = useState(0);
    useEffect(() => {
        getCourseById(params?.id);
        getSections(params?.id);
    }, [])

    const getCourseById = (id) => {
        axios
            .get(site_ip + '/getCourseById/' + id)
            .then((res) => {
                setCourseDetails(res.data.Content[0]);

            })
            .catch((err) => console.log(err));
    };


    const findActiveLessons = (allLessons, activeSection) =>{
        let filtered_lessons = allLessons.filter((item) => item.section_id === activeSection);
        return filtered_lessons;
    }

    const getSections = (id) => {
        axios
            .get(site_ip + '/getCourseSectionLessons/' + id)
            .then((res) => {
                console.log(res.data)
                let section_list = res.data.Section;
                section_list.sort((a, b) => a.section_seq > b.section_seq ? 1 : -1)
                setSectionList(section_list)
                setLessonList(res.data.Lesson)
                if(section_list.length > 0){
                    setActiveLessons(findActiveLessons(res.data.Lesson, section_list[0]?.section_id))
                    setActiveSectionId(section_list[0]?.section_id)
                }
            })
            .catch((err) => console.log(err));
    };

    const activeSectionChanged = (new_section_id) =>{
        setActiveSectionId(new_section_id);
        setActiveLessons(findActiveLessons(lessonList, new_section_id))
    }
    

    return (
        <>
            <div style={{padding:"25px"}}>
                <Row style={{marginBottom:"15px"}}>
                    <Col>
                        <a className="burder-link" href="/" onClick={(e) => { e.preventDefault(); navigate("/user/home") }}>Home</a>
                        <span style={{ padding: "0px 5px" }}>/</span>
                        <a className="burder-link" href="/" onClick={(e) => { e.preventDefault(); navigate("/user/courses")}}>Courses</a>
                        <span style={{ padding: "0px 5px" }}>/</span>
                        <a className="burder-link" href="/" onClick={(e) => { e.preventDefault();}}>{courseDetails?.course_title}</a>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <div>
                            <img src={courseDetails?.course_image} className="book-detail-logo" style={{marginTop:"0px", marginBottom:"0px"}} />
                            <p className="black label-2 mb-5">{courseDetails?.course_title}</p>
                        </div>
                        <div className="book-detail-menu">
                            {sectionList.map((item, index)=>{
                                return(
                                    <div key={index} className={activeSectionId === item?.section_id ? "section-btn-div active" : "section-btn-div"}>
                                        <button onClick={() => activeSectionChanged(item?.section_id)}>
                                            {item?.section_name} 
                                        </button>
                                    </div>
                                )
                            })}
                        </div>
                    </Col>
                    <Col md={9} style={{border:"1px solid #ccc"}}>
                        {activeLessons.map((item, index)=>{
                            return(
                                <Lesson item={item} key={index} />
                            )
                        })}
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default CourseDetails




const Lesson = ({ item }) => {
    const navigate = useNavigate();
    const [expended, setExpended] = useState(item.section === null ? true : false)
    const [notesVisible, setNotesVisible] = useState(false);

    return (
        <div style={{ borderBottom: "1px solid #ccc" }}>
            {item.section !== null ?
                <div className="content-row">
                    <button className="section-btn" style={{padding:"15px 0px"}} onClick={() => setExpended(!expended)}>
                        <p style={{ paddingRight: "15px" }}>{item?.lesson_name} <span style={{fontSize:"12px", color:"#ccc"}}>({item?.lesson_duration+" "+item?.lesson_duration_unit})</span></p>
                        <p style={{ fontSize: "16px", position: "absolute", right: 0 }}> {expended ? "-" : "+"}</p>
                    </button>
                </div> : null}
            {expended ?
            <div>
                <div>
                    <button className="plain-button" onClick={() => setNotesVisible(!notesVisible)} ><i className="far fa-clipboard" style={{marginRight:"5px"}}></i>Notes</button>
                    <button className="plain-button"><i className="fas fa-users" style={{marginRight:"5px"}}></i>Discuss</button>
                </div>
                <div style={{display:"flex"}}>
                    <div style={{flex: 2, border:"1px solid #cccccc", padding:"15px"}}>
                        <p style={{fontSize:"12px"}}>{item?.lesson_desc}</p>
                        <div className="video-box-shadow">
                            <ReactPlayer
                                controls={true}
                                url='https://icp-videos.s3.amazonaws.com/Acid-Base-Disorder-Dr-Subhash-Todi.mp4'
                                className='react-player'
                                playing={false}
                                width='100%'
                                height='100%'
                            />
                        </div>
                    </div>
                    {notesVisible ? 
                    <div style={{flex: 1, border:"1px solid #cccccc", padding:"15px"}}>
                        <Row>
                            <Col xs={8}>
                                <p className="notes-title">Notes (2)</p>
                            </Col>
                            <Col xs={4} style={{display:"flex", justifyContent:"flex-end"}}>
                                <button onClick={() => setNotesVisible(false)} className="plain-button" style={{marginRight:0}}>Close <i className="fas fa-times" style={{marginLeft:"5px"}}></i></button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Control as="textarea" rows={3} />
                                <div style={{height:"5px"}}></div>
                                <Button variant="success">Save</Button>{' '}
                                <Button variant="outline-secondary">Cancel</Button>
                            </Col>
                        </Row>
                        <Row style={{paddingTop:"15px"}}>
                            <Col xs={12}>
                                <p className="notes-text">Welcome to the videos of the next lesson. In this lesson, we will try to understand what is streaming and how to multimedia content.</p>
                            </Col>
                            <Col xs={3}>
                                <button className="plain-button" style={{marginRight:0, fontSize:"12px"}}>Delete</button>
                            </Col>
                        </Row>
                    </div> : null }   
                </div>
            </div> : null}
        </div>
    )
}