import React, { useEffect, useState } from "react";
import { Container, Navbar, Nav, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
export default function Header() {
  const navigate = useNavigate();
  const [user, setUser] = useState({})
  useEffect(() => {
    let token = localStorage.getItem("endUserToken")
    if (token === null) {
      // navigate("/user/auth/login")
    } else {
      try {
        let end_user = JSON.parse(localStorage.getItem("endUser"))
        setUser(end_user);
      } catch (e) {
        // navigate("/user/auth/login")
      }
    }
  }, [])

  const logmeOut = () => {
    localStorage.clear()
    navigate("/user/auth/login")
  }

  return (
    <div className="custom-navbar">
      <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
        <img src="/images/icp.jpeg" className="header-logo" alt="logo1" style={{ objectFit: "cover" }} />
        <div className="user-profile-box">
          {user?.first_name !== undefined ?
            <>
              <p>Hi, {user?.first_name}</p>
              <button className="coa-btn" onClick={() => logmeOut()}>Logout</button>
            </> : null}
        </div>
      </div>
      <Navbar
        className="custom-navbar-menu"
        collapseOnSelect
        expand="lg"
        variant="light"
      >
        <Container fluid>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-center"
          >
            <Nav className="mr-auto">
              <Nav.Link href="https://indiancollegeofphysicians.org/">
                Home
              </Nav.Link>
              <Nav.Link href="/">
                Courses
              </Nav.Link>
              <Nav.Link href="/fellowship">Fellowship</Nav.Link>
              <Nav.Link href="/newsletter">Newsletter</Nav.Link>
              {/* <Nav.Link href="/news">News</Nav.Link> */}
              {/* <Nav.Link href="/profile">Profile</Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
