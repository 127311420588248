import React, { useState, useEffect } from 'react';
import {
  Card,
  Row,
  Col,
  Form,
  Button,
  Container,
  Modal
} from 'react-bootstrap';
import axios from 'axios';
import API from '../API';
import { site_ip } from '../../globalSetting';

import { useNavigate } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Swal from 'sweetalert2';
import DataTable from 'react-data-table-component';
import CustomTableStyle from '../customTableStyle';

export default function News() {

  const navigate = useNavigate();
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };

  const [show, setShow] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);


  const [data, setData] = useState([]);

  const blankFitlers = {
    fellowship_status: '',
    date_from: '',
    date_to: '',
  };

  useEffect(() => {
    handleSearch(blankFitlers);
    // eslint-disable-next-line
  }, []);

  const [search, setSearch] = useState(blankFitlers);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSearch = async (filter) => {
    const jwtHeader = {
      headers: { 'access-token': localStorage.getItem('admin_token') },
    };
    await axios
      .post(`${site_ip}/getFellowshipDetails`, filter, jwtHeader)
      .then((res) => {
        setData(res.data.Data);
        // console.log(res.data);
      })
      .catch((err) => console.log(err));
  };

  const editButton = (id) => {
    navigate('/admin/edit-news/' + id)
  }

  const handleClose = () => setShow(false);

  const removeRecord = async () => {
    let data = {
      news_id: selectedRecord
    }
    handleClose();
    await axios
      .post(site_ip + '/deleteNews/' + selectedRecord, data, jwtHeader)
      .then(function (response) {
        Swal.fire({
          title: 'Success',
          text: 'Record removed successfully',
          icon: 'success',
        });
        handleSearch();
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  }

  const deleteEntry = (id) => {
    setSelectedRecord(id);
    setShow(true);
  }

  let tableHeader = [
    {
      name: 'S. No.',
      selector: (row, index) => (index + 1),
      width: "100px"
    },
    {
      name: 'Full Name',
      selector: (row) => row?.full_name,
    },
    {
      name: 'Email',
      selector: (row) => row?.user_email,
    },
    {
      name: 'Mobile',
      selector: (row) => row?.user_mobile,
    },
    {
      name: 'Status',
      cell: (row) => (
        <>
          {row?.fellowship_status === "P" ? <span style={{ backgroundColor: "#ff9900", padding: '5px', color: "#ffffff" }}>Pending</span> : null}
          {row?.fellowship_status === "A" ? <span style={{ backgroundColor: "#4caf50", padding: '5px', color: "#ffffff" }}>Approved</span> : null}
          {row?.fellowship_status === "R" ? <span style={{ backgroundColor: "#ff3333", padding: '5px', color: "#ffffff" }}>Rejected</span> : null}
        </>
      )
    },
    {
      name: 'Action',
      width: "120px",
      cell: (row) => (
        <>
          <button
            className="btn btn-sm circle"
            role="button"
            style={{ backgroundColor: '#4caf50', color: "#ffffff", width: "35px" }}
            onClick={() => navigate("/admin/fellowships/"+row.fellowship_id)}
          >
            <i class="far fa-eye"></i>
          </button>
          {/*<button
                    className="btn btn-sm circle"
                    role="button"
                    style={{ backgroundColor: '#ff0000', color: "#ffffff", width: "35px", marginLeft: "0px" }}
                    onClick={(e) => deleteDataById(e, row.farm_id)}
                >
                    <i className="fas fa-trash"></i>
                </button> */}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ]

  const seeDetails = (id) => {

  }

  return (
    <Container fluid>
      <br />
      <Row>
        <Col>
          <Card>
            <Card.Header as="h4">Fellowship Award Request</Card.Header>
            <Card.Body>
              <Form>
                <Row>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        className="mb-3"
                        name="fellowship_status"
                        value={search.fellowship_status}
                        onChange={handleChange}
                      >
                        <option value="">All</option>
                        <option value="P">Pending</option>
                        <option value="A">Accepted</option>
                        <option value="R">Rejected</option>  
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>From</Form.Label>
                      <Form.Control
                        name="publish_date"
                        value={search.publish_date}
                        onChange={handleChange}
                        className="mb-3"
                        type="date"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>To</Form.Label>
                      <Form.Control
                        name="publish_date"
                        value={search.publish_date}
                        onChange={handleChange}
                        className="mb-3"
                        type="date"
                      />
                    </Form.Group>
                  </Col>
                  <Col className="mt-1">
                    <Button
                      variant="primary"
                      className="mt-4"
                      onClick={() => handleSearch(search)}
                    >
                      Search
                    </Button>
                    <Button
                      variant="warning"
                      className="mt-4 mb-0 m-2"
                      onClick={() => {
                        setSearch(blankFitlers)
                        handleSearch(blankFitlers)
                      }
                      }
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <br />
      <DataTable columns={tableHeader} data={data} pagination customStyles={CustomTableStyle} />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          Are you sure to remove this record?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={removeRecord}>
            Yes
          </Button>
          <Button variant="secondary" onClick={handleClose} >No</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
