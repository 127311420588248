import React, { useEffect, useState } from 'react';
import Header from "../../common/Header"
import Menu from "../../common/Menu"
import axios from 'axios';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { site_ip } from '../../../config';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import ReactPlayer from "react-player";
import "./news.css";
const News = () => {

    const params = useParams();
    const navigate = useNavigate();
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('endUserToken') },
    };
    useEffect(() => {
        if (params.id !== undefined) {
            getExpertCommentryById(params.id)
        }
    }, [params.id])

    const [expertCommentryDetails, setExpertCommentryById] = useState({});
    const getExpertCommentryById = (id) => {
        // setLiked(false)
        // let req = {
        //     news_id: id,
        //     user_id: parseInt(localStorage.getItem("user_id"))
        // }
        axios
            .get(site_ip + '/getExpertCommentariesById/' + id, jwtHeader)
            .then((res) => {
                setExpertCommentryById(res.data.Data[0]);
                // updateViews(id)
                // setRelated(res.data.RelatedNews)
                window.scrollTo(0, 0)
            })
            .catch((err) => console.log(err));
    };

    

    return (
        <div>
            <Container>
                <Row style={{ margin: 0 }}>
                    <Col md={12}>
                        <div style={{ padding: "15px 0px 0px 0px" }}>
                            {expertCommentryDetails?.case_images_names !== null && expertCommentryDetails?.case_images_names !== undefined ?
                                <div className="imageBox">
                                    <img alt="news-image" src={expertCommentryDetails?.case_images_names} />
                                </div> : null}
                            <p className="news-thumbnail-category">{expertCommentryDetails?.category_name}</p>
                            <p className="news-title">{expertCommentryDetails?.case_name}</p>
                            {/* <p className="news-subtitle">{expertCommentryDetails?.news_subtitle}</p>
                        <div style={{ display: "flex", marginTop: "10px" }}>
                            {expertCommentryDetails?.author_name !== null && expertCommentryDetails?.author_name !== "" ?
                                <div style={{ display: 'flex', alignItems: 'center', marginRight: "20px" }}>
                                    <i className="fas fa-user-edit"></i>
                                    <p className='author-name'>{expertCommentryDetails?.author_name}</p>
                                </div> : null}
                            {expertCommentryDetails?.publish_date !== null && expertCommentryDetails?.publish_date !== "" ?
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <i class="fas fa-calendar-alt"></i>
                                    <p className='author-name'>{moment(expertCommentryDetails?.publish_date).format("DD, MMM YYYY")}</p>
                                </div> : null}
                        </div> */}
                            {/* <Row style={{ marginTop: "25px" }}>
                            <Col>
                                <div style={{ display: "flex" }}>
                                    <div className="count-div">
                                        <i class="fa fa-heart" aria-hidden="true" style={{ color: "#ff0000" }}></i>
                                        <p>{expertCommentryDetails?.likes_cnt + (liked ? 1 : 0)}</p>
                                    </div>
                                    <div className="count-div" style={{ marginLeft: "25px" }}>
                                        <i class="fa fa-eye" aria-hidden="true"></i>
                                        <p>{expertCommentryDetails?.views_cnt}</p>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    {expertCommentryDetails?.like_status === "Liked" || liked ?
                                        <button className="liked-btn">
                                            <FavoriteIcon style={{ color: "#ff0000" }} />
                                        </button> :
                                        <button className="like-btn" onClick={() => updateLike()}>
                                            <FavoriteBorderIcon style={{ color: "#ccc" }} />
                                        </button>}
                                </div>
                            </Col>
                        </Row> */}
                            <div style={{ marginTop: "10px", marginBottom: "0px" }}>
                                <div
                                    style={{ height: "100%" }}
                                    dangerouslySetInnerHTML={{ __html: expertCommentryDetails?.abstract }} className="ck-content" />
                            </div>
                            <div style={{ marginTop: "10px", marginBottom: "0px" }}>
                                <div
                                    style={{ height: "100%" }}
                                    dangerouslySetInnerHTML={{ __html: expertCommentryDetails?.case_suggested_reading }} className="ck-content" />
                            </div>
                        </div>
                        {expertCommentryDetails?.case_video_names !== null && expertCommentryDetails?.case_video_names !== "" ?     
                        <ReactPlayer
                            controls={true}
                            url={expertCommentryDetails?.case_video_names}
                            className='react-player'
                            playing={false}
                            // width='100%'
                            // height='100%'
                            // progressInterval={5000}
                            // onProgress={(state) => {
                            //     // console.log(state);
                            //     setPlayedTiming(state?.playedSeconds)
                            // }}
                        /> : null }
                        <p className="related-news-title">References:</p>
                        <ul>
                            <li>
                                {expertCommentryDetails?.case_pdf_file_link !== undefined && expertCommentryDetails?.case_pdf_file_link !== null && expertCommentryDetails?.case_pdf_file_link !== "" ?
                                    <a href={expertCommentryDetails.case_pdf_file_link} style={{ fontSize: "12px", color: "#0000ff" }} >{expertCommentryDetails.case_pdf_file_link}</a> : null}
                            </li>
                        </ul>
                    </Col>
                    {/* <Col md={4}>
                   <p className="related-news-title">Related News</p> 
                   {related?.map((item, index)=>{
                        return(
                            <NewsThumbnail item={item} key={index} />
                        )
                   })}         
                </Col> */}
                </Row>
            </Container>
        </div>
    )
}

export default News;

const NewsThumbnail = (props) => {
    const item = props.item;
    const navigate = useNavigate();
    return (
        <div className='news-thumbnail-div'>
            <Row>
                <Col md={12}>
                    <div className='news-thumbnail-img-div'>
                        {item?.thumbnail !== null ?
                            <img src={item?.thumbnail} alt="news_image" /> : null}
                    </div>
                </Col>
                <Col md={12}>
                    <p className="news-thumbnail-category">{item.subject_name}</p>
                    <p className="news-thumbnail-name" ><a href="/" style={{ fontSize: "13px" }} onClick={(e) => { e.preventDefault(); navigate("/user/news/" + item.news_id + "/" + item.news_title?.split(' ').join('_')) }}>{item.news_title}</a></p>
                    <Row>
                        <Col md={6}>
                            <div>
                                {/* {item?.author_name !== null && item?.author_name !== "" ?
                                    <div style={{ display: 'flex', alignItems: 'center', marginRight: "20px" }}>
                                        <i className="fas fa-user-edit"></i>
                                        <p className='author-name'>{item?.author_name}</p>
                                    </div> : null} */}
                                {item?.publish_date !== null && item?.publish_date !== "" ?
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <i class="fas fa-calendar-alt"></i>
                                        <p className='author-name'>{moment(item?.publish_date).format("DD, MMM YYYY")}</p>
                                    </div> : null}
                            </div>
                        </Col>
                        <Col md={6}>
                            <div style={{ display: "flex" }}>
                                <div className="count-div">
                                    <i class="fa fa-heart" aria-hidden="true" style={{ color: "#ff0000" }}></i>
                                    <p>{item?.likes_cnt}</p>
                                </div>
                                <div className="count-div" style={{ marginLeft: "25px" }}>
                                    <i class="fa fa-eye" aria-hidden="true"></i>
                                    <p>{item?.views_cnt}</p>
                                </div>
                            </div>
                        </Col>

                    </Row>
                </Col>
            </Row>
        </div>
    )
}