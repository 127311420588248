import React, {useEffect, useState} from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import "../user.css"
import { useLocation, useNavigate } from 'react-router-dom';
import SecondaryHeader from "./SecondaryHeader";
const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [userName, setUserName] = useState(null);

    useEffect(()=>{
        let username = localStorage.getItem("endUserName");
        if(username !== null){
            setUserName(username)
        }else{
            navigate("/user/auth/login")
        }
    }, [])

    const logmeOut = () =>{
        localStorage.clear();
        navigate("/user/auth/login")
    }

    return (
        <>
            <SecondaryHeader />    
            <div>
                <div>
                    <Row style={{margin:0}}>
                        <Col>
                            <div style={{textAlign:'center', marginTop:"10px"}}>
                                <img src="../../../../images/DKN.png" className="header-logo"></img>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default Header;