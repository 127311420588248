import React, { useState, useEffect } from 'react';
import { Container, Button, Col,Row } from 'react-bootstrap';

import Aux from '../../hoc/_Aux';
import Edit from './EditCourses';
import Sections from './Sections';
import Lessons from './Lessons';
import Assessments from './Assessments';
import FAQs from './FAQs';
import SectionAssessment from './SectionAssessment';
import { useParams } from 'react-router-dom';
// import Subscriptions from './Subscriptions';

export default function AddInstitution(props) {
  const params = useParams();
  const [activeTab, setActiveTab] = useState(1)
  const [courseId, setCourseId] = useState(0);
  useEffect(() => {
    if (params.id !== undefined) {
      setCourseId(params.id);
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Aux>
      <Container fluid>
        {courseId !== 0 ?
          <div>
            <Row>
              <Col>
                <Button onClick={() => setActiveTab(1)} className={activeTab === 1 ? "instituionTabSection active" : "instituionTabSection"}>Edit Course</Button>
                {/* <Button onClick={() => setActiveTab(2)} className={activeTab === 2 ? "instituionTabSection active" : "instituionTabSection"}>Sections</Button> */}
                <Button onClick={() => setActiveTab(3)} className={activeTab === 3 ? "instituionTabSection active" : "instituionTabSection"}>Lessons</Button>
                <Button onClick={() => setActiveTab(4)} className={activeTab === 4 ? "instituionTabSection active" : "instituionTabSection"}>Lesson Assessments</Button>
                {/* <Button onClick={() => setActiveTab(5)} className={activeTab === 5 ? "instituionTabSection active" : "instituionTabSection"}>FAQs</Button>
                <Button onClick={() => setActiveTab(6)} className={activeTab === 6 ? "instituionTabSection active" : "instituionTabSection"}>Course Assessment</Button> */}
              </Col>
            </Row>
            <Row>
              {/* Edit Institution */}
              {activeTab === 1 ? <Edit id={courseId} /> : null}
              {/* Sections */}
              {/* {activeTab === 2 ? <Sections id={courseId} /> : null} */}
              {/* Subscriptions */}
              {activeTab === 3 ? <Lessons id={courseId} /> : null}
              {/* Assessments */}
              {activeTab === 4 ? <Assessments id={courseId} /> : null}
              {/* FAQs */}
              {activeTab === 5 ? <FAQs id={courseId} /> : null}
              {/* Section Assessment */}
              {activeTab === 6 ? <SectionAssessment id={courseId} /> : null}
            </Row>
          </div> : null}
      </Container>
    </Aux>
  );
}
