import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

import { site_ip } from '../../globalSetting';
import API from '../API';
import Aux from '../../hoc/_Aux';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { fileUpload } from '../Common/fileUploads';
export default function CreateNews(props) {
  const params = useParams();
  const navigate = useNavigate();
  const [editorDescriptionState, setEditorDescriptionState] = useState("");
  const initialState = {
    news_id: "",
    news_title: "",
    news_subtitle: "",
    subject_id: null,
    category_id: null,
    title_image: "",
    thumbnail: "",
    publish_date: "",
    author_name: "",
    quote: "",
    created_by_user: null,
    news: "",
    video_link: "",
    views_cnt: 0,
    likes_cnt: 0,
    keywords: "",
    highlights: "",
    content_type_flag: "HTML",
    pdf_file_name: ""
  };

  const [file, setFile] = useState(null);
  const [pdf, setPDF] = useState(null);
  const [values, setValues] = useState(initialState);

  const fetchNewsById = async (news_id) => {
    try {
      const data = await API.fetchNewsById(news_id);
      setValues(data);
      // console.log(data);
      setEditorDescriptionState(data.news);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    setValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleRadio = (name, value) => {
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const [categories, setCategories] = useState([]);
  const [subject, setSubject] = useState([]);
  const fetchCategories = async () => {
    try {
      setCategories(await API.fetchCategories());
    } catch (error) {
      console.log(error);
    }
  };
  const fetchSubjects = async () => {
    try {
      setSubject(await API.fetchSubjects());
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchNewsById(params.news_id);
    fetchCategories();
    fetchSubjects();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async () => {
    let req = { ...values };
    var convertedDescriptionState = editorDescriptionState;
    const jwtHeader = {
      headers: { 'access-token': localStorage.getItem('admin_token') },
    };

    if (values?.content_type_flag === "PDF" && (pdf === null && values?.pdf_file_name === "")) {
      alert("Please add a pdf file.")
      return
    }
    let pdf_file_name = values?.pdf_file_name;
    if (values?.content_type_flag === "PDF" && pdf !== null) {
      pdf_file_name = await fileUpload(pdf[0])
    }

    if (file !== null) {
      let fileUrl = await fileUpload(file[0])
      req = { ...req, thumbnail: fileUrl }
    }

    await axios
      .post(
        site_ip + '/setNews',
        {
          news_id: req.news_id,
          news_title: req.news_title,
          news_subtitle: req.news_subtitle,
          subject_id: req.subject_id,
          category_id: req.category_id,
          title_image: "",
          thumbnail: req.thumbnail,
          publish_date: req.publish_date,
          author_name: req.author_name,
          quote: "",
          created_by_user: null,
          news: convertedDescriptionState,
          content_type_flag: values.content_type_flag,
          pdf_file_name: pdf_file_name,
          video_link: "",
          views_cnt: 0,
          likes_cnt: 0,
          keywords: req.keywords,
          highlights: req.highlights
        },
        jwtHeader
      )
      .then((res) => {
        // console.log(res)
        if (res.data.Status === 200) {
          Swal.fire('News updated');
          navigate('/admin/news');
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Aux>
      <Row style={{ margin: 0 }}>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title as="h5" style={{ color: "#ffffff" }}>Update News</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      name="news_title"
                      className="mb-3"
                      value={values.news_title}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Sub Title</Form.Label>
                    <Form.Control
                      type="text"
                      name="news_subtitle"
                      className="mb-3"
                      value={values.news_subtitle}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Category</Form.Label>
                    <Form.Control
                      as="select"
                      name="category_id"
                      className="mb-3"
                      value={values.category_id}
                      onChange={handleChange}
                    >
                      <option value="null">Select Category</option>
                      {categories.map((item, index) => (
                        <option value={item.category_id} kye={index}>
                          {item.category_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                      as="select"
                      className="mb-3"
                      name="subject_id"
                      value={values.subject_id}
                      onChange={handleChange}
                    >
                      <option value="null">Select Subject</option>
                      {subject.map((item, index) => (
                        <option value={item.subject_id} key={index}>
                          {item.subject_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Author</Form.Label>
                    <Form.Control
                      type="text"
                      name="author_name"
                      className="mb-3"
                      value={values.author_name}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Publish Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="publish_date"
                      className="mb-3"
                      min={moment(new Date()).format('YYYY-MM-DD')}
                      value={values.publish_date}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Keywords</Form.Label>
                    <Form.Control
                      type="text"
                      name="keywords"
                      className="mb-3"
                      value={values.keywords}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Highlights</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="highlights"
                      className="mb-3"
                      value={values.highlights}
                      onChange={handleChange}
                      style={{ height: "150px" }}
                    />
                  </Form.Group>
                </Col>
                <Col md={12} className="mb-3">
                  <Form.Group>
                    <Form.Label>Content Type </Form.Label>
                    <div style={{ display: "flex" }}>
                      <Form.Check
                        type={"radio"}
                        label={`HTML`}
                        checked={values?.content_type_flag === "HTML" ? true : false}
                        onChange={() => null}
                        onClick={() => handleRadio("content_type_flag", "HTML")}
                      />
                      <div style={{ width: "50px" }}></div>
                      <Form.Check
                        type={"radio"}
                        label={`PDF`}
                        checked={values?.content_type_flag === "PDF" ? true : false}
                        onChange={() => null}
                        onClick={() => handleRadio("content_type_flag", "PDF")}
                      />
                    </div>
                  </Form.Group>
                </Col>
                {values?.content_type_flag === "HTML" ?
                  <Col md={12} className="mb-3">
                    <Form.Group>
                      <Form.Label>News Content</Form.Label>
                      <div
                        style={{
                          border: '1px solid #ced4da',
                          padding: '2px',
                          minHeight: '300px',
                        }}
                      >
                        <CKEditor
                          // config={custom_config}
                          editor={Editor}
                          data={editorDescriptionState}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setEditorDescriptionState(data)
                          }}
                        />
                      </div>
                    </Form.Group>
                  </Col> : null}
                {values?.content_type_flag === "HTML" ?
                  <>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>News Image</Form.Label>
                        <Form.Control
                          type="file"
                          className="mb-3"
                          accept="image/*"
                          name="thumbnail"
                          // value={values.thumbnail}
                          onChange={(e) => setFile(e.target.files)}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      {values.thumbnail !== "" ? <img alt="image" src={values.thumbnail} style={{ height: "100px", width: "100px" }}></img> : null}
                    </Col>
                  </> :
                  <>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>PDF <span style={{color:"#ff0000"}}>*</span></Form.Label>
                        <Form.Control
                          type="file"
                          className="mb-3"
                          accept="application/pdf"
                          name="thumbnail"
                          // value={values.thumbnail}
                          onChange={(e) => setPDF(e.target.files)}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      {values.pdf_file_name !== "" ? <a href={values?.pdf_file_name}>{values?.pdf_file_name}</a> : null}
                    </Col> 
                  </> }
              </Row>
              <Button variant="primary" onClick={handleSubmit}>
                Submit
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Aux>
  );
}
