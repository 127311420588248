import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import "./Home.css";
import { useLocation } from "react-router-dom";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
const Home = () => {
  const location = useLocation();
  const blankValues = {
    salutation: "",
    full_name: "",
    user_profile_img: "",
    api_membership_no: "",
    date_of_birth: "",
    date_of_joining: "",
    user_email: "",
    user_mobile: "",
    user_phone: "",
    // state_id:req.body.state_id,
    // country_id:req.body.country_id,
    residence_address: "",
    office_address: "",
    nos_of_publications: "",
    nos_of_chapters_in_books: "",
    editorship_of_national_state_level: "",
    publication_attachment: "",
    oration_title: "",
    oration_org: "",
    oration_year: "",
    oration_proof: "",
    award_title: "",
    award_org: "",
    award_year: "",
    award_proof: "",
    research_work: "",
    letter_of_sanction: "",
    departmental_research: "",
    title_page_abstract: "",
    cont_api_post_held: "",
    cont_api_organization: "",
    cont_api_level: "",
    cont_api_year: "",
    cont_api_proof: "",
    cme_participation_speaker: "",
    cme_title_talk: "",
    cme_meeting_name: "",
    cme_year: "",
    // social_welfare_service: "",
    // social_welfare_evidence: []
  }
  const [values, setValues] = useState(blankValues)
  const [professionalQualifications, setProfessionalQualifications] = useState([])
  const [experienceList, setExperienceList] = useState([])
  const [orationList, setOrationList] = useState([])
  const [awardList, setAwardList] = useState([])
  const [contributionList, setContributionList] = useState([])
  const [cmeList, setCmeList] = useState([])
  const [evidenceList, setEvidenceList] = useState([])
  const [proposerList, setProposerList] = useState([])
  const [attachments, setAttachments] = useState(null)
  const [dp, setDP] = useState(null);
  useEffect(() => {
    let preview = localStorage.getItem("preview")
    if (preview !== null) {
      preview = JSON.parse(preview)
      setValues(preview?.state?.values)
      setProfessionalQualifications(preview?.state?.professionalQualifications)
      setExperienceList(preview?.state?.experienceList)
      setOrationList(preview?.state?.orationList)
      setAwardList(preview?.state?.awardList)
      setContributionList(preview?.state?.contributionList)
      setCmeList(preview?.state?.cmeList)
      setEvidenceList(preview?.state?.evidenceList)
      setProposerList(preview?.state?.proposerList)
      setDP(preview?.state?.dp)
    }

  }, [])

  const generatePDF = async() => {
    const pdf = new jsPDF("portrait", "pt", "a4"); 
    const data = await html2canvas(document.querySelector("#preview"));
    const img = data.toDataURL("image/png");  
    const imgProperties = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(img, "PNG", 30, 30, pdfWidth - 60, pdfHeight);
    pdf.save("Fellowship.pdf");
  }

  return (
    <div className="form-container" >
      {/* <!-------------Slider-section-start---------------> */}
      <div id="preview">
        <section>
          <div className="page-logo-div">
            <img src={"/images/top_banner.jpg"} alt='demo' className="mt-2" />
          </div>
          {/* <div className="title">
          <p className="page-heading">Indian College of Physicians</p>
        </div> */}
          <div className="sub-title">
            <Row>
              <Col md={2}>
              </Col>
              <Col md={8}>
                <p className="page-sub-heading">
                  <b>Format for Submission of Bio - Data of The Nominee for Consideration
                    for Award of Fellowship of Indian College of Physicians.</b>
                </p>
                <p className="page-sub-heading">
                  <b>PREVIEW</b>
                </p>
              </Col>
              <Col md={2}>
              </Col>
            </Row>
          </div>
        </section>
        <section>
          <div>
            <div className="apply_box">
              <Row className="no-margin">
                <Col md={9} className="border-all">
                  <Row>
                    <Col className="border-all">
                      <p>1. Name in Full(Surname First)(in Block Letters): </p>
                      <p><b>{values?.full_name}</b></p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} xs={12} className="border-all">
                      <p>2.(a) API Membership Number: </p>
                      <p><b>{values?.api_membership_no}</b></p>
                    </Col>
                    <Col md={6} xs={12} className="border-all">
                      <p>2.(b) Date of Joining </p>
                      <p><b>{values?.date_of_joining}</b></p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="border-all">
                      <p>3. Date of Birth: </p>
                      <p><b>{values?.date_of_birth}</b></p>
                    </Col>
                  </Row>
                </Col>
                <Col md={3} className="border-all p-3">
                {dp !== null ? 
                  <img src={dp} style={{height: "100px", objectFit:"contain"}} /> : null }
                </Col>
              </Row>
              <Row className="no-margin">
                <Col md={6} className="border-all">
                  <p>Address Residence:</p>
                  <p><b>{values?.residence_address}</b></p>
                </Col>
                <Col md={6} className="border-all">
                  <p>Address Office:</p>
                  <p><b>{values?.office_address}</b></p>
                </Col>
              </Row>
              <Row className="no-margin">
                <Col md={1} className="border-all">
                  <p>4</p>
                </Col>
                <Col md={11} className="border-all no-padding">
                  <Row className="no-margin">
                    <Col md={4} xs={6} className="">
                      <p>Telephone:</p>
                      <p><b>{values?.user_phone}</b></p>
                    </Col>
                    <Col md={4} xs={6} className="border-all">
                      <p>Mobile: </p>
                      <p><b>{values?.user_mobile}</b></p>
                    </Col>
                    <Col md={4} xs={6} className="border-all">
                      <p>Email: </p>
                      <p><b>{values?.user_email}</b></p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {professionalQualifications?.length > 0 ?
                <Row className="no-margin">
                  <Col md={1} className="border-all">
                    <p>5</p>
                  </Col>
                  <Col md={11} className="border-all no-padding">
                    <Row className="no-margin">
                      <Col xs={3} className="">
                        <p>Postgraduate degree in Medicine: </p>
                        <p><b>{professionalQualifications[0]?.degree_name}</b></p>
                      </Col>
                      <Col xs={3} className="border-all">
                        <p>Year of passing:</p>
                        <p><b>{professionalQualifications[0]?.year_of_passing}</b></p>
                      </Col>
                      <Col xs={3} className="border-all">
                        <p>Institute:</p>
                        <p><b>{professionalQualifications[0]?.institute}</b></p>
                      </Col>
                      <Col xs={3} className="border-all">
                        <p>University:</p>
                        <p><b>{professionalQualifications[0]?.university}</b></p>
                      </Col>
                      {/* <Col md={2} className="border-all">
                    <p>Attach Certificates</p>
                    <input type={"file"} onChange={(e) => handlePQAttachment(0, e.target.files[0])}></input>
                  </Col> */}
                    </Row>
                    <Row className="no-margin border-all">
                      <Col xs={3} className="">
                        <p>Other Professional Qualification:</p>
                      </Col>
                      <Col xs={3} className="border-all">
                        <p>Year of passing:</p>
                      </Col>
                      <Col xs={3} className="border-all">
                        <p>Institute:</p>
                      </Col>
                      <Col xs={3} className="border-all">
                        <p>University:</p>
                      </Col>
                      {/* <Col md={2}>
                    <p>Attach Certificates</p>
                    <input type={"file"} onChange={(e) => handlePQAttachment(1, e.target.files[0])}></input>
                  </Col> */}
                    </Row>
                    {professionalQualifications?.map((item, index) => {
                      if (index > 0) {
                        return (
                          <Row className="no-margin border-all" key={index}>
                            <Col xs={3} className="">
                              <p><b>{professionalQualifications[index]?.degree_name}</b></p>
                            </Col>
                            <Col xs={3} className="border-all">
                              <p><b>{professionalQualifications[index]?.year_of_passing}</b></p>
                            </Col>
                            <Col xs={3} className="border-all">
                              <p><b>{professionalQualifications[index]?.institute}</b></p>
                            </Col>
                            <Col xs={3} className="border-all">
                              <p><b>{professionalQualifications[index]?.university}</b></p>
                            </Col>
                            {/* <Col md={2} className="p-3">
                          <input type={"file"} onChange={(e) => handlePQAttachment(index, e.target.files[0])}></input>
                        </Col> */}
                          </Row>
                        )
                      }
                    })}
                  </Col>
                </Row> : null}
              <Row className="no-margin">
                <Col xs={1} className="border-all">
                  <p>6</p>
                </Col>
                <Col xs={11} className="border-all">
                  <p>Experience in Medical Profession after Postgratuation in Medicine:</p>
                </Col>
              </Row>
              {experienceList?.length > 0 ?
                <Row className="no-margin border-all">
                  <Col xs={4} className="">
                    <p>Name of Hospital/ Clinic/ Organisation & Location:</p>
                  </Col>
                  <Col xs={2} className="border-all">
                    <p>Number of Beds (if applicable):</p>
                  </Col>
                  <Col xs={2} className="border-all">
                    <p>Post held:</p>
                  </Col>
                  <Col xs={2} className="border-all">
                    <p>Period Served year wise (From):</p>
                  </Col>
                  <Col xs={2} className="border-all">
                    <p>Period Served year wise (To):</p>
                  </Col>
                </Row> : null}
              {experienceList.length > 0 && experienceList?.map((item, index) => {
                return (
                  <Row className="no-margin border-all" key={index}>
                    <Col xs={4} className="">
                      <p><b>{experienceList[index]?.hospital_name}</b></p>
                    </Col>
                    <Col xs={2} className="border-all">
                      <p><b>{experienceList[index]?.number_of_beds}</b></p>
                    </Col>
                    <Col xs={2} className="border-all">
                      <p><b>{experienceList[index]?.post_held}</b></p>
                    </Col>
                    <Col xs={2} className="border-all">
                      <p><b>{experienceList[index]?.served_from_year}</b></p>
                    </Col>
                    <Col xs={2} className="border-all">
                      <p><b>{experienceList[index]?.served_to_year}</b></p>
                    </Col>
                  </Row>
                )
              })}
              <Row className="no-margin">
                <Col xs={1} className="border-all">
                  <p>7</p>
                </Col>
                <Col xs={11} className="border-all">
                  <p>Publications: List below. (If number of publications in Journals exceeds 8, publications which can qualify as research papers may be listed under Research section 9.)</p>
                </Col>
              </Row>
              <Row className="no-margin">
                <Col md={12} className="border-all">
                  <Row>
                    {/* <Col xs={12}>
                    <p>a) Number of Publications in Indexed National / International Journals</p>
                  </Col>
                  <Col xs={12} className="border-all p-3">
                    <textarea className="" />
                  </Col> */}
                    <Col xs={12} className="border-all">
                      <p>a. Number of Publications in Indexed National / International Journals: </p>
                      <p><b>{values?.nos_of_publications}</b></p>
                    </Col>
                    <Col xs={12} className="border-all">
                      <p>b. Number of Chapter in Books / monograms: </p>
                      <p><b>{values?.nos_of_chapters_in_books}</b></p>
                    </Col>
                    <Col xs={12} className="border-all">
                      <p>c. Editorship of National level or State level: Book /Monogram/Update Series: </p>
                      <p><b>{values?.editorship_of_national_state_level}</b></p>
                    </Col>
                  </Row>
                </Col>
                {/* <Col md={3} className="border-all p-3">
                <input type={"file"} onChange={(e) => handleAttachment("publication_attachment", e.target.files[0])}></input>
              </Col> */}
              </Row>
              <Row className="no-margin">
                <Col xs={1} className="border-all">
                  <p>8</p>
                </Col>
                <Col xs={11} className="border-all">
                  <p>Honours And Awards (list below with photocopy of proof)</p>
                </Col>
              </Row>
              <Row className="no-margin">
                <Col md={12} className="border-all">
                  <p>(a) Oration in National / State Association Meeting</p>
                </Col>
              </Row>
              {orationList?.length > 0 ?
                <Row className="no-margin border-all">
                  <Col xs={4} className="">
                    <p>Title of Oration:</p>
                    <p><b>{orationList[0]?.oration_title}</b></p>
                  </Col>
                  <Col xs={4} className="border-all">
                    <p>Organisation:</p>
                    <p><b>{orationList[0]?.oration_org}</b></p>
                  </Col>
                  <Col xs={4} className="border-all">
                    <p>Year:</p>
                    <p><b>{orationList[0]?.oration_year}</b></p>
                  </Col>
                  {/* <Col md={3}>
                <p>Attach Proof</p>
                <p><b>{values?.date_of_joining}</b></p>
                <input type={"file"} name="oration_proof" onChange={(e) => handleOrationValues(0, e.target.files[0], "file")}></input>
              </Col> */}
                </Row> : null}
              {orationList.length > 0 && orationList?.map((item, index) => {
                if (index > 0) {
                  return (
                    <Row className="no-margin border-all">
                      <Col xs={4} className="">
                        <p><b>{orationList[index]?.oration_title}</b></p>
                      </Col>
                      <Col xs={4} className="border-all">
                        <p><b>{orationList[index]?.oration_org}</b></p>
                      </Col>
                      <Col xs={4} className="border-all">
                        <p><b>{orationList[index]?.oration_year}</b></p>
                      </Col>
                      {/* <Col md={3} className="p-3">
                      <input type={"file"} name="oration_proof" onChange={(e) => handleOrationValues(index, e.target.files[0], "file")}></input>
                    </Col> */}
                    </Row>
                  )
                }
              })}
              <Row className="no-margin">
                <Col xs={12} className="border-all">
                  <p>8. (b) Award National / International / or State level</p>
                </Col>
              </Row>
              {awardList?.length > 0 ?
                <Row className="no-margin">
                  <Col xs={4} className="border-all">
                    <p>Title of Award:</p>
                    <p><b>{awardList[0]?.award_title}</b></p>
                  </Col>
                  <Col xs={4} className="border-all">
                    <p>Organisation:</p>
                    <p><b>{awardList[0]?.award_org}</b></p>
                  </Col>
                  <Col xs={4} className="border-all">
                    <p>Year:</p>
                    <p><b>{awardList[0]?.award_year}</b></p>
                  </Col>
                  {/* <Col md={3}>
                <p>Attach Proof</p>
                <input type={"file"} name="award_proof" onChange={(e) => handleAwardValues(0, e.target.files[0], "file")}></input>
              </Col> */}
                </Row> : null}
              {awardList.length > 0 && awardList?.map((item, index) => {
                if (index > 0) {
                  return (
                    <Row className="no-margin border-all" key={index}>
                      <Col xs={4} className="">
                        <p><b>{awardList[index]?.award_title}</b></p>
                      </Col>
                      <Col xs={4} className="border-all">
                        <p><b>{awardList[index]?.award_org}</b></p>
                      </Col>
                      <Col xs={4} className="border-all">
                        <p><b>{awardList[index]?.award_year}</b></p>
                      </Col>
                      {/* <Col md={3} className="p-3">
                      <input type={"file"} name="award_proof" onChange={(e) => handleAwardValues(index, e.target.files[0], "file")}></input>
                    </Col> */}
                    </Row>
                  )
                }
              })}
              <Row className="no-margin">
                <Col xs={1} className="border-all">
                  <p>9</p>
                </Col>
                <Col xs={11} className="border-all">
                  <p>Research work (list below)</p>
                </Col>
              </Row>
              <Row className="no-margin">
                <Col xs={12} className="border-all">
                  <p>(a) Research sanctioned & funded by Research Agency</p>
                  <p><b>{values?.research_work}</b></p>
                </Col>
                {/* <Col md={3} className="border-all">
                <p>Attach Letter of sanction</p>
                <input type={"file"} name="letter_of_sanction" onChange={(e) => handleAttachment("letter_of_sanction", e.target.files[0])}></input>
              </Col> */}
              </Row>
              <Row className="no-margin">
                <Col xs={12} className="border-all">
                  <p style={{ whiteSpace: "pre-wrap" }}>(b) Departmental Research. (To qualify, the findings should be published in National/International Journal) Do not include papers already listed under Publications</p>
                  <p><b>{values?.departmental_research}</b></p>
                </Col>
                {/* <Col md={3} className="border-all">
                <p>Attach title page / Abstract</p>
                <input type={"file"} name="title_page_abstract" onChange={(e) => handleAttachment("title_page_abstract", e.target.files[0])}></input>
              </Col> */}
              </Row>
              <Row className="no-margin">
                <Col xs={1} className="border-all">
                  <p>10</p>
                </Col>
                <Col xs={11} className="border-all">
                  <p>Contribution to API (list below and attach proof)</p>
                </Col>
              </Row>
              {contributionList?.length > 0 ?
                <Row className="no-margin border-all">
                  <Col xs={3} className="">
                    <p>Post held in Organisation/ Meeting:</p>
                    <p><b>{contributionList[0]?.cont_api_post_held}</b></p>
                  </Col>
                  <Col xs={3} className="border-all">
                    <p>Name of Organisation/ Meeting/ CME:</p>
                    <p><b>{contributionList[0]?.cont_api_organization}</b></p>
                  </Col>
                  <Col xs={3} className="border-all">
                    <p style={{ whiteSpace: "pre-wrap" }}>National/ Zonal/ Under API/ ICP State level:</p>
                    <p><b>{contributionList[0]?.cont_api_level}</b></p>
                  </Col>
                  <Col xs={3} className="border-all">
                    <p>Year:</p>
                    <p><b>{contributionList[0]?.cont_api_year}</b></p>
                  </Col>
                  {/* <Col md={2} className="border-all">
                <p>Attach Proof</p>
                <input type={"file"} name="cont_api_proof" onChange={(e) => handleAwardValues(0, e.target.files[0], "file")}></input>
              </Col> */}
                </Row> : null}
              {contributionList.length > 0 && contributionList?.map((item, index) => {
                if (index > 0) {
                  return (
                    <Row className="no-margin border-all" key={index}>
                      <Col xs={3} className="">
                        <p><b>{contributionList[index]?.cont_api_post_held}</b></p>
                      </Col>
                      <Col xs={3} className="border-all">
                        <p><b>{contributionList[index]?.cont_api_organization}</b></p>
                      </Col>
                      <Col xs={3} className="border-all">
                        <p><b>{contributionList[index]?.cont_api_level}</b></p>
                      </Col>
                      <Col xs={3} className="border-all">
                        <p><b>{contributionList[index]?.cont_api_year}</b></p>
                      </Col>
                      {/* <Col md={2} className="border-all p-3">
                      <input type={"file"} name="cont_api_proof" onChange={(e) => handleContributionValues(index, e.target.files[0], "file")}></input>
                    </Col> */}
                    </Row>
                  )
                }
              })}
              < Row className="no-margin">
                <Col md={1} className="border-all">
                  <p>11</p>
                </Col>
                <Col md={11} className="border-all">
                  <p>Participation in CME or Scientific Sessions of API or ICP as Faculty:</p>
                </Col>
              </Row>
              {cmeList?.length > 0 ?
                <Row className="no-margin border-all">
                  <Col xs={3} className="">
                    <p>Speaker/ Chairperson/ Other:</p>
                    <p><b>{cmeList[0]?.cme_participation_speaker}</b></p>
                  </Col>
                  <Col xs={3} className="border-all">
                    <p>Title of Talk / Session:</p>
                    <p><b>{cmeList[0]?.cme_title_talk}</b></p>
                  </Col>
                  <Col xs={3} className="border-all">
                    <p>Name of meeting:</p>
                    <p><b>{cmeList[0]?.cme_meeting_name}</b></p>
                  </Col>
                  <Col xs={3} className="border-all">
                    <p>Year:</p>
                    <p><b>{cmeList[0]?.cme_year}</b></p>
                  </Col>
                </Row> : null}
              {cmeList.length > 0 && cmeList?.map((item, index) => {
                if (index > 0) {
                  return (
                    <Row className="no-margin border-all" key={index}>
                      <Col xs={3} className="">
                        <p><b>{cmeList[index]?.cme_participation_speaker}</b></p>
                      </Col>
                      <Col xs={3} className="border-all">
                        <p><b>{cmeList[index]?.cme_title_talk}</b></p>
                      </Col>
                      <Col xs={3} className="border-all">
                        <p><b>{cmeList[index]?.cme_meeting_name}</b></p>
                      </Col>
                      <Col xs={3} className="border-all">
                        <p><b>{cmeList[index]?.cme_year}</b></p>
                      </Col>
                    </Row>
                  )
                }
              })}
              <Row className="no-margin">
                <Col md={1} className="border-all">
                  <p>12</p>
                </Col>
                <Col md={11} className="border-all">
                  <p>Social welfare / Community service. (Include under the headings given below, with documentry evidence)</p>
                  <p>(a) Emergency services during National calamities (Quakes/Floods/Cyclones,etc)</p>
                  <p>(b) Public education Programme (Radio), TV talk/writing in news papers.</p>
                  <p>(c) Service in Rural Areas</p>
                </Col>
              </Row>
              {evidenceList?.length > 0 ?
                <Row className="no-margin border-all">
                  <Col md={12} className="">
                    <p>Service:</p>
                    <p><b>{evidenceList[0]?.social_welfare_service}</b></p>
                  </Col>
                  {/* <Col md={6} className="border-all">
                <p>Evidence:</p>
                <p><b>{values?.date_of_joining}</b></p>
                <input type={"file"} onChange={(e) => handleEvidenceValues(0, e.target.files[0], "file")}></input>
              </Col> */}
                </Row> : null}
              {evidenceList?.length > 0 && evidenceList?.map((item, index) => {
                if (index > 0) {
                  return (
                    <Row className="no-margin border-all" key={index}>
                      <Col md={12} className="">
                        <p>Service:</p>
                        <p><b>{evidenceList[index]?.social_welfare_service}</b></p>
                      </Col>
                      {/* <Col md={6} className="border-all">
                      <p>Evidence:</p>
                      <input type={"file"} onChange={(e) => handleEvidenceValues(index, e.target.files[0], "file")}></input>
                    </Col> */}
                    </Row>
                  )
                }
              })}
            </div>
          </div>
        </section >
        {proposerList?.length > 1 ?
          <section>
            <div className="end">
              <h5><b>Indian College of Physicians Citation</b></h5>
            </div>
            <div>
              <p>Please share the details of two proposers for recommendation:</p>
            </div>
            <div className="apply_box">
              <Row className="no-margin border-all">
                <Col md={6}>
                  <Row>
                    <Col xs={12} className="">
                      <p>Proposer 1:</p>
                    </Col>
                    <Col xs={12} className="border-all">
                      <p>Name:</p>
                      <p><b>{proposerList[0]?.proposer_name}</b></p>
                    </Col>
                    <Col xs={12} className="border-all">
                      <p>Fellowship No.:</p>
                      <p><b>{proposerList[0]?.membership_no}</b></p>
                    </Col>
                    <Col xs={12} className="border-all">
                      <p>Email:</p>
                      <p><b>{proposerList[0]?.proposer_email}</b></p>
                    </Col>
                    <Col xs={12} className="border-all">
                      <p>Mobile No.:</p>
                      <p><b>{proposerList[0]?.proposer_phone}</b></p>
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row>
                    <Col xs={12} className="border-all">
                      <p>Proposer 2:</p>
                    </Col>
                    <Col xs={12} className="border-all">
                      <p>Name:</p>
                      <p><b>{proposerList[1]?.proposer_name}</b></p>
                    </Col>
                    <Col xs={12} className="border-all">
                      <p>Fellowship No.:</p>
                      <p><b>{proposerList[1]?.membership_no}</b></p>
                    </Col>
                    <Col xs={12} className="border-all">
                      <p>Email:</p>
                      <p><b>{proposerList[1]?.proposer_email}</b></p>
                    </Col>
                    <Col xs={12} className="border-all">
                      <p>Mobile No.:</p>
                      <p><b>{proposerList[1]?.proposer_phone}</b></p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </section> : null}
      </div>
      <Button onClick={() => generatePDF()} style={{ marginRight: "15px" }}>Export PDF</Button>
    </div >
  );
};

export default Home;
