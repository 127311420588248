import React, { useEffect, useState } from 'react';
import Header from "../../common/Header"
import Menu from "../../common/Menu"
import axios from 'axios';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { site_ip } from '../../../config';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import "./news.css";
import ReactPlayer from "react-player";
const News = () => {
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('endUserToken') },
    };
    const params = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        if (params.id !== undefined) {
            getVideoById(params.id)
        }
    }, [params.id])

    const [videoDetails, setVideoDetails] = useState({});
    const getVideoById = (id) => {
        axios
            .get(site_ip + '/getVideoById/' + id, jwtHeader)
            .then((res) => {
                setVideoDetails(res.data.Content);
                window.scrollTo(0, 0)
            })
            .catch((err) => console.log(err));
    };

    return (
        <div>
            <Container>
                <Row style={{ margin: 0 }}>
                    <Col md={12}>
                        <div style={{ padding: "15px 0px 0px 0px" }}>
                            <p className="news-thumbnail-category">{videoDetails?.subject_name}</p>
                            <p className="news-title">{videoDetails?.video_title}</p>
                            <div style={{ marginTop: "10px", marginBottom: "0px" }}>
                            {videoDetails?.video_url !== null && videoDetails?.video_url !== "" ?     
                            <ReactPlayer
                                controls={true}
                                url={videoDetails?.video_url}
                                className='react-player'
                                playing={false}
                                width='100%'
                                height='100%'
                                // progressInterval={5000}
                                // onProgress={(state) => {
                                //     // console.log(state);
                                //     setPlayedTiming(state?.playedSeconds)
                                // }}
                            /> : null }
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default News;
