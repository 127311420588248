import React from 'react';
import {Col } from 'react-bootstrap';
import './common.css';
export default function TopBar() {
  return (
    <div className="d-flex align-items-center top-bar">
      <Col md={2} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
        
      </Col>
      <Col md={8} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
        <img src="/images/icp.jpeg" style={{height:"80px"}} />
      </Col>
      <Col md={2} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
        {/* <div style={{ backgroundColor: '#fff', padding: '15px 20px' }}>
          <img src={'/img/Knowledge.png'} className="right_logo" alt="Logo2" />
        </div> */}
      </Col>
    </div>
  );
}
