import React, { useState, useEffect } from 'react';
import {
  Button,
  Container,
  Col,
  Form,
  Row,
  Card,
  Modal,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { site_ip } from '../../globalSetting';
import API from '../API';
import '../Master/master.css';

export default function Adminusers(props) {

  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('admin_token') },
  };

  const [show, setShow] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);

  const initialValues = {
    admin_id: '',
    user_name: '',
    role_id: '',
    user_email: '',
    mobile_number: '',
    password: '',
    updating: false,
  };

  const initialSearch = {
    admin_name: '',
    admin_email: '',
    role_id: '',
    mobile_number: '',
  };

  const [roles, setRoles] = useState([]);
  const [users, setUsersList] = useState([]);

  const [add, setAdd] = useState(false);

  const [values, setValues] = useState(initialValues);
  const [search, setSearch] = useState(initialSearch);

  const handleClose = () => {
    setAdd(false);
    setShow(false);
  };
  const handleShow = () => {
    setValues(initialValues);
    setAdd(true);
  };

  const validateEmail = (mail) => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  };

  const phonenumber = (mobile) => {
    var phoneno = /^\d{10}$/;
    if (String(mobile).match(phoneno)) {
      return true;
    } else {
      return false;
    }
  };

  const validatePassword = (pass) => {
    if (pass.trim().length < 8) {
      return false;
    }
    return true;
  };

  const proceed = () => {
    if (
      values.user_name.trim() === '' ||
      values.user_email.trim() === '' ||
      values.role_id === ''
    ) {
      alert('Please fill all fields');
    } else if (!validateEmail(values.user_email.trim())) {
      alert('Please enter valid email address');
    } else if (!phonenumber(values.mobile_number)) {
      alert('Please enter valid mobile number');
    } else if (values.admin_id === '') {
      if (!validatePassword(values.password)) {
        alert('Password must be minimum 8 character long');
        return;
      }
      handleClose();
      // Add industry
      axios
        .post(
          site_ip + '/auth/addAdminUser',
          {
            role_id: values.role_id,
            admin_name: values.user_name,
            admin_email: values.user_email,
            password: values.password,
            mobile_number: values.mobile_number,
          },
          jwtHeader
        )
        .then(function (response) {
          if (response.data.Message === 200) {
            Swal.fire({
              title: 'Success',
              text: 'New Admin Created Successfully',
              icon: 'success',
            }).then((result) => {
              fetchUsers();
            });
          } else {
            alert(response.data.Message);
          }
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    } else {
      handleClose();
      // Update industry
      // console.log(values);
      axios.post(
        site_ip + '/auth/setAdminUser',
        {
          admin_id: values.admin_id,
          role_id: values.role_id,
          admin_name: values.user_name,
          admin_email: values.user_email,
          mobile_number: values.mobile_number,
        },
        jwtHeader
      )
        .then(function (response) {
          if (response.data.message === 200) {
            Swal.fire({
              title: 'Success',
              text: 'Admin Details Updated Successfully',
              icon: 'success',
            }).then((result) => {
              fetchUsers();
            });
          } else {
            alert(response.data.Message);
          }
        })
        .catch(function (error) {
          console.log('error' + error);
        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    fetchUsers();
    fetchAdminRoles();
    // eslint-disable-next-line
  }, []);

  const fetchAdminRoles = async () => {
    try {
      setRoles(await API.fetchRoles());
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUsers = () => {
    axios.post(
      site_ip + '/auth/searchAdminUsers',
      {
        admin_name: search.admin_name,
        admin_email: search.admin_email,
        role_id: search.role_id,
        mobile_number: search.mobile_number,
      },
      jwtHeader
    )
      .then(function (response) {
        setUsersList(response.data.Admin);
        // console.log(response);
      })
      .catch(function (error) {
        console.log('error' + error);
      });
  };

  const resetFilters = () => {
    setSearch(initialSearch);
  };

  const editNow = (item) => {
    const newValues = {
      admin_id: item.admin_id,
      user_name: item.admin_name,
      role_id: item.role_id,
      user_email: item.admin_email,
      mobile_number: item.mobile_number,
      updating: true,
    };
    setValues(newValues);
    setAdd(true);
  };

   const removeRecord = async() => {
      let data = {
        admin_id: selectedRecord
      }
      handleClose();
        await axios
        .post(site_ip + '/auth/deleteAdminUser/'+selectedRecord,data,jwtHeader)
        .then(function (response) {
            Swal.fire({
                title: 'Success',
                text: 'Record removed successfully',
                icon: 'success',
            });
            fetchUsers();
        })
        .catch(function (error) {
            console.log('error' + error);
        });
   }

   const deleteEntry = (id) =>{
        setSelectedRecord(id);
        setShow(true);
   }

  return (
    <Container fluid>
      <Row style={{marginTop:"15px"}}>
          <Col>
            <Card>
              <Card.Header as="h4">Search Admin Users</Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                  <Col md={12} style={{ marginTop: '15px' }}>
                    <div>
                      <Row>
                        <Col md={3}>
                          <Form.Group>
                            <Form.Label>Role</Form.Label>
                            <Form.Control
                              as="select"
                              className="mb-3"
                              name="role_id"
                              value={search.role_id}
                              onChange={handleChange1}
                            >
                              <option value="">Select Role</option>
                              {roles.map((value) => (
                                <option key={value.role_id} value={value.role_id}>
                                  {value.role_name}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                              type="text"
                              required
                              name="admin_name"
                              value={search.admin_name}
                              onChange={handleChange1}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="text"
                              required
                              name="admin_email"
                              value={search.admin_email}
                              onChange={handleChange1}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group>
                            <Form.Label>Mobile</Form.Label>
                            <Form.Control
                              type="tel"
                              required
                              minLength="10"
                              maxLength="10"
                              name="mobile_number"
                              value={search.mobile_number}
                              onChange={handleChange1}
                            />
                          </Form.Group>
                        </Col>{' '}
                        <Col md={4}></Col>
                        <Col md={7}>
                          <Button variant="primary" onClick={fetchUsers}>
                            Search
                          </Button>
                          <Button
                            variant="secondary"
                            onClick={resetFilters}
                            style={{ marginLeft: '15px' }}
                          >
                            Reset
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
      </Row>
      <Row className="align-items-center" style={{marginTop:"15px"}}>
        <Col md={6}>
        </Col>
        <Col md={6} className="text-end">
          <Button
            variant="dark"
            onClick={() => handleShow()}
            aria-controls="example-collapse-text"
            aria-expanded={add}
          >
            Add
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xl={12} className="mt-3">
          <div>
            <table className="customTable">
              <thead className="lt-head">
                <tr className="customHeader">
                  {/* <th scope="row">Sr No</th> */}
                  <th width="80px" style={{textAlign:'center'}}>Sr No</th>
                  {/* <th>Action</th> */}
                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Role</th>
                  <th width="80px" style={{textAlign:'center'}}>Action</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => {
                  return (
                    <tr key={index}>
                      <td style={{textAlign:'center'}}>{index + 1}</td>
                      <td>{user.admin_name}</td>
                      <td>{user.admin_email}</td>
                      <td>{user.mobile_number}</td>
                      <td>{user.role_name}</td>
                      <td style={{textAlign:'center', display:"flex"}}>
                          <Button variant="success" className="rowActionButton" onClick={()=> editNow(user)}>
                            <EditIcon />
                          </Button>
                          <Button variant="danger" className="rowActionButton" onClick={()=> deleteEntry(user.admin_id)}>
                            <DeleteForeverIcon />
                          </Button> 
                        </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
      <Modal size="lg" show={add} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {values.updating ? 'Update Admin Details' : 'Add Admin User'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  required
                  name="user_name"
                  value={values.user_name}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Role</Form.Label>
                <Form.Control
                  as="select"
                  className="mb-3"
                  name="role_id"
                  value={values.role_id}
                  onChange={handleChange}
                >
                  <option value="">Select Role</option>
                  {roles.map((value) => (
                    <option key={value.role_id} value={value.role_id}>
                      {value.role_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  required
                  name="user_email"
                  value={values.user_email}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  type="text"
                  required
                  name="mobile_number"
                  value={values.mobile_number}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          {!values.updating ? (
            <Row style={{ marginTop: '15px' }}>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={proceed}>
            {values.updating ? 'Update' : 'Add'}
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
          <Modal.Body>
            Are you sure to remove this record?
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={removeRecord}>
                Yes
            </Button>
            <Button variant="secondary" onClick={handleClose} >No</Button>
            </Modal.Footer>
        </Modal>
    </Container>
  );
}
