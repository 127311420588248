import React, { useState, useEffect, useRef  } from 'react';
import Header from "../../common/Header"
import Menu from "../../common/Menu"
import axios from 'axios';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { site_ip } from '../../../config';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import Footer from '../../../Components/Footer';

//const navigate = useNavigate();

const Certificate = () => {

   const navigate = useNavigate();
const location = useLocation();
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('endUserToken') },
    };
    let course_id = localStorage.getItem("course_id");

    useEffect(() => {

     
      if(course_id===null){
        navigate("/");
  
      } 
  
      let token = localStorage.getItem("endUserToken")
      if (token === null) {
        navigate("/user/auth/login")
      } else {
        try {
          let end_user = JSON.parse(localStorage.getItem("endUser"))
          
        } catch (e) {
          navigate("/user/auth/login")
        }
      }
    }, [])
   
    const [courseData, setCourseData] = useState({
      course_name: '',
      course_id:course_id,
      director_name: '',
      from_date: '',
      to_date: '',
      attended_all_course: '',
    });
    //Get course details from server
    let end_user = JSON.parse(localStorage.getItem("endUser"));
    let req = {
      course_id: course_id,
      user_id:end_user.user_id
    };
            
            useEffect(() => {
               const fetchData = async () => {
                 try {
                   const response = await axios.post(site_ip + '/checkCourseAttemptedByUser', req); // Replace with your actual API endpoint
                   const data = response.data.courseData;
                   if( data.all_attempted==0){
                  

                   }
                   setCourseData({
                     course_id:course_id,
                     course_name: data.course_title,
                     director_name: data.course_director,
                     course_director_qualification:data.course_director_qualification,
                     course_director_signature:data.course_director_signature,
                     from_date: data.from_date, // Adjust the date format as needed
                     to_date: data.to_date,
                     attended_all_course: data.all_attempted.toString(), 
                     });
                 } catch (error) {
                   console.error('Error fetching data:', error);
                 }
               };
           
               // Call the fetchData function when the component mounts
               fetchData();
             }, []); // The empty dependency array ensures that this effect runs once when the component mounts
           
   

 
    const handleChange =(e) => {
        /*let { name, value } = e.target;      
         setValues({ ...values, [name]: value })   */   
      }

      const certificateGen = () => {
         let req1 = { ...courseData };
        if(req1.to_date !== undefined || req1.from_date !== undefined)
        {
         let end_user = JSON.parse(localStorage.getItem("endUser"));
         let fullname = end_user.first_name+' '+end_user.last_name
         let req = {
            name:fullname ,
            user_id:end_user.user_id,
            course_name: req1.course_name.trim(),
            to_date:req1.to_date,
            from_date:req1.from_date,
            director_name:req1.director_name.trim(),
            director_qualification:req1.course_director_qualification.trim(),
            director_signature:req1.course_director_signature.trim(),
          }
          axios
            .post(site_ip + '/generateCertificate', req)
            .then((res) => {
            if(res.data.Status == 200){
               
            // Store data in local storage
            localStorage.setItem('pdf_url',res.data.pdf_url);

            navigate('/certificate-preview');

            } 
            else{

            }

            })
            .catch((err) =>
              alert("Invalid credentials.")
            );
        }    
        else{
        if(req1.to_date === undefined){
         alert('Please provide To Date.')
        }
        else if(req1.from_date_date === undefined){
         alert('Please provide From Date.')
        }
        else if(req1.course_name ===''){
         alert('Please provide Course Name.')
        }
        else if(req1.director_name ===''){
         alert('Please provider Director Name.')
        }
         
        }
      }
  
      
return (
    <div>
    <Container className='mt-5'>
    <Row>
       <Col md={2}></Col>
       <Col md={8}>
       <Row>
       <Col md={2}></Col>
          <Col md={12}>
          <Row>
             <Col md={6}>
             <p className="login-page-label">Course Name <span style={{ color: "#ff0000" }}>*</span></p>
             <input type='text' className="loginpage-input" readOnly placeholder='' name="course_name" value={courseData.course_name}
             onChange={handleChange}
             />
             <div style={{ height: "20px" }} />
             </Col>
             <Col md={6}>
             <p className="login-page-label">Director <span style={{ color: "#ff0000" }}>*</span></p>
             <input type='text' className="loginpage-input" readOnly placeholder='' name="director_name" value={courseData.director_name}
             onChange={handleChange}
             />
             
             <div style={{ height: "20px" }} />
             </Col>
          </Row>
          </Col>
       </Row>
       <Row>
          <Col md={6}>
          <p className="login-page-label">From Date<span style={{ color: "#ff0000" }}>*</span></p>
          <input type='text' className="loginpage-input" readOnly placeholder='' name="from_date" value={courseData.from_date}
             onChange={handleChange}
             />
          <div style={{ height: "20px" }} />
          </Col>
          <Col md={6}>
          <p className="login-page-label">To Date <span style={{ color: "#ff0000" }}>*</span></p>
          <input type='text' className="loginpage-input" readOnly placeholder='' name="to_date"  value={courseData.to_date}     onChange={handleChange} />
          <div style={{ height: "20px" }} />
          </Col>
       </Row>
       <Row>
          <Col md={6}>
          <p className="login-page-label">Whether attended all courses<span style={{ color: "#ff0000" }}>*</span></p>
          <label className='form-check-label'>
            
            {courseData.attended_all_course==1 ? 'Yes':'No'}
          </label>
          <div style={{ height: "20px" }} />
          </Col>
          <Col md={6}>
          {courseData.attended_all_course==1?
          <button className="btn btn-primary "  onClick={() => certificateGen()}>Generate Certificate</button> : <button className="btn btn-primary " disabled  >Generate Certificate</button>} 
          </Col>
       </Row>

       {courseData.attended_all_course==0?
       <Row>
       <Col md={12} className='text-center' ><span className='text-danger'><strong>In order to get your certificate - first please complete watching all the course videos and complete all the assessments.</strong></span></Col>
       </Row> : ''}
       <div style={{ textAlign: 'center', marginTop: "40px", marginBottom: "120px" }}>
       

      
 </div>
 </Col>
       </Row>
 </Container>
 <Footer />
    </div>
  )
}



export default Certificate;
