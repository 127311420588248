import React from 'react';
import { Container, Navbar, Nav, Form } from 'react-bootstrap';
export default function Footer() {
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
        // fixed="top"
        className="d-flex justify-content-center"
      >
        <Nav>
          {/* <Nav.Link href="/#about">About Dermatology Network</Nav.Link>
          <Nav.Link href="/#offer">What We Offer</Nav.Link>
          <Nav.Link href="/#board">Editorial Board</Nav.Link>
          <Nav.Link href="/#sections">Sections</Nav.Link>
          <Nav.Link href="/news">News</Nav.Link>
          <Nav.Link href="/contact-us">Contact Us</Nav.Link>
          <Nav.Link href="/lms/signup">Register</Nav.Link>
          <Nav.Link href="/lms/login">Login</Nav.Link> */}
        </Nav>
      </Navbar>
      <div style={{
        background: "#212529",
        color: "#ffffff",
        fontSize: "12px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: "10px"
      }}>
        © 2022, The Indian College of Physicians (ICP). All Rights Reserved.
      </div>
    </>
  );
}
