import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Card, Col, Row, Modal } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';

import { site_ip } from '../../globalSetting';
import API from '../API';

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

import { Upload } from '@aws-sdk/lib-storage';
import { S3Client } from '@aws-sdk/client-s3';
import { useNavigate } from 'react-router-dom';
export default function AddCourses(props) {
  const navigate = useNavigate();
  const initialValues = {
    subject_id: '',
    category_id: '',
    topic_id: '',
    owner_id: '',
    video_title: '',
    // video_name: '',
    video_type: '',
    video_desc: '',
    duration: '',
    video_filename: '',
  };
  const [values, setValues] = useState(initialValues);
  const [courseVideo, setCourseVideo] = useState(null);
  const [uploading, setUploading] = useState(false);

  const [description, setDescription] = useState("");

  function handleChange(e) {
    setValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    if (e.target.name === 'subject_id') getTopic(e.target.value);
  }
  const [subject, setSubject] = useState([]);
  const getSubjects = async () => {
    try {
      setSubject(await API.fetchSubjects());
    } catch (error) {
      console.log(error);
    }
  };
  const [category, setCategory] = useState([]);
  const getCategory = async () => {
    try {
      setCategory(await API.fetchCategories());
    } catch (error) {
      console.log(error);
    }
  };
  const [topic, setTopic] = useState([]);
  const getTopic = async (subject_id) => {
    try {
      setTopic(await API.fetchTopics(subject_id));
    } catch (error) {
      console.log(error);
    }
  };
  const [owner, setOwner] = useState([]);
  const getOwners = async () => {
    try {
      setOwner(await API.fetchOwners());
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSubjects();
    getCategory();
    getOwners();
  }, []);

  const handleVideo = async (e) => {
    e.preventDefault();
    setUploading(true);
    const jwtHeader = {
      headers: { 'access-token': localStorage.getItem('admin_token') },
    };

    let videoAttachment = null;
    if (courseVideo !== null) {
      videoAttachment = await fileUpload(courseVideo)
    }

    setUploading(false);

    let video_desc = description;

    await axios
      .post(
        site_ip + '/createExpertsVideo',
        {
          subject_id: values.subject_id,
          category_id: values.category_id,
          topic_id: values.topic_id,
          owner_id: values.owner_id,
          video_title: values.video_title,
          video_name: values.video_title,
          video_type: values.video_type,
          video_desc: video_desc,
          duration: values.duration,
          video_filename: videoAttachment,
        },
        jwtHeader
      )
      .then((res) => {
        console.log(res);
        if (res.data.Status === 200) {
          Swal.fire('New Video Added');
          navigate('/admin/videos');
        }
      })
      .catch((err) => console.log(err));
  };

  const fileUpload = async (attachment) => {
    const s3_url =
      'https://derma.s3.ap-south-1.amazonaws.com/' +
      attachment.name;
    const target = { Bucket: 'derma', Key: attachment.name, Body: attachment };
    const creds = {
      accessKeyId: 'AKIAWUYVKQ2U4TARTTST',
      secretAccessKey: '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',
    };
    try {
      const parallelUploads3 = new Upload({
        client: new S3Client({ region: 'ap-south-1', credentials: creds }),
        leavePartsOnError: false, // optional manually handle dropped parts
        params: target,
      });

      parallelUploads3.on('httpUploadProgress', (progress) => {
        console.log(progress);
      });

      await parallelUploads3.done();
      return s3_url;

    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <Container fluid>
        <br />
        <Card>
          <Card.Header as="h4">New Video</Card.Header>
          <Card.Body>
            <Form>
              <Row>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Category</Form.Label>
                    <Form.Control
                      name="category_id"
                      value={values.category_id}
                      onChange={handleChange}
                      className="mb-3"
                      as="select"
                    >
                      <option value="">Select Category</option>
                      {category?.map((item, key) => (
                        <option key={key} value={item.category_id}>
                          {item.category_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                      name="subject_id"
                      value={values.subject_id}
                      onChange={handleChange}
                      className="mb-3"
                      as="select"
                    >
                      <option value="">Select Subject</option>
                      {subject?.map((item, key) => (
                        <option key={key} value={item.subject_id}>
                          {item.subject_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Topic</Form.Label>
                    <Form.Control
                      name="topic_id"
                      value={values.topic_id}
                      onChange={handleChange}
                      className="mb-3"
                      as="select"
                    >
                      <option value="">Select Topic</option>
                      {topic?.map((item, key) => (
                        <option key={key} value={item.topic_id}>
                          {item.topic_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Owner</Form.Label>
                    <Form.Control
                      name="owner_id"
                      value={values.owner_id}
                      onChange={handleChange}
                      className="mb-3"
                      as="select"
                    >
                      <option value="">Select Owner</option>
                      {owner?.map((item, key) => (
                        <option key={key} value={item.admin_id}>
                          {item.admin_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Video Title</Form.Label>
                    <Form.Control
                      name="video_title"
                      value={values.video_title}
                      onChange={handleChange}
                      className="mb-3"
                      type="text"
                    />
                  </Form.Group>
                </Col>
                <Col md={3} >
                  <Form.Group>
                    <Form.Label>Video File</Form.Label>
                    <Form.Control
                      type="file"
                      className="mb-3"
                      accept="video/mp4,video/x-m4v,video/*"
                      name="video_filename"
                      // value={values.case_html_file_link}
                      onChange={(e) => {
                        setCourseVideo(e.target.files[0]);
                        handleChange(e);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Duration(in hrs)</Form.Label>
                    <Form.Control
                      name="duration"
                      value={values.duration}
                      onChange={handleChange}
                      className="mb-3"
                      type="number"
                      min={0}
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Video Type</Form.Label>
                    <Form.Control
                      name="video_type"
                      value={values.video_type}
                      onChange={handleChange}
                      className="mb-3"
                      as="select"
                    >
                      <option value="">Select Video Type</option>
                      <option value="mp4">MP4</option>
                      <option value="mkv">MKV</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group>
                    <Form.Label>Video Description</Form.Label>
                    <div
                      style={{
                        border: '1px solid #ced4da',
                        padding: '2px',
                        minHeight: '300px',
                      }}
                    >
                      <CKEditor
                        editor={Editor}
                        data={description}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setDescription(data)
                        }}
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <div style={{ height: "15px" }}></div>
              <Button type="submit" onClick={handleVideo}>
                Submit
              </Button>
            </Form>
          </Card.Body>
        </Card>
        <Modal show={uploading}>
          <Modal.Body style={{ textAlign: 'center' }}>Please wait, while we are uploading.</Modal.Body>
        </Modal>
      </Container>
    </>
  );
}
