import React, {useState } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

import { site_ip } from '../../globalSetting';

export default function AssessmentQuestion(props) {
  const [values, setValues] = useState({
    course_id: parseInt(props.course_id),
    section_id: props.section_id,
    lesson_id: props.lesson_id,
    ques_type_id: 'S'
  });

  const [answer, setAnswer] = useState([]);

  const [question, setQuestion] = useState("");
  const [option1, setOption1] = useState("");
  const [option2, setOption2] = useState("");
  const [option3, setOption3] = useState("");
  const [option4, setOption4] = useState("");

  function handleChange(e) {
    setValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }


  const handleAssessmentQuestion = async (e) => {

    e.preventDefault();

    let answer_str = "";
    let answers;
    if (answer.length === 0) {
      alert("Please select answer.")
      return
    } else {
      let selected_ans = [...answer]
      selected_ans.sort();
      answer_str = selected_ans.join(",");
      answers = {
        answer: answer_str
      }
    }

    const questions = {
      question: question,
      option1: option1,
      option2: option2,
      option3: option3,
      option4: option4
    };
    const jwtHeader = {
      headers: { 'access-token': localStorage.getItem('admin_token') },
    };

    await axios
      .post(
        `${site_ip}/createCourseLessonQuestions`,
        { ...values, ...questions, ...answers },
        jwtHeader
      )
      .then((res) => {
        if (res.data.Status === 200) {
          Swal.fire('New question added successfully');
          props.refreshList()
        }
      })
      .catch((err) => console.log(err));
  };

  const answerSelected = (item) => {
    if (item === "") {
      setAnswer([])
    } else {
      let selected_answers = [...answer];
      if(values.ques_type_id === 'S'){
        setAnswer(item)
      }else{
        if (selected_answers.includes(item)) {
          selected_answers.splice(selected_answers.indexOf(item), 1)
        } else {
          selected_answers.push(item);
        }
        setAnswer(selected_answers)    
      }
    }
  }

  return (
    <>
      <div style={{ padding: "15px" }}>
        <Row>
          <Col md={12}>
            <Form.Group>
              <Form.Label>Question</Form.Label>
              <div
                style={{
                  border: '1px solid #ced4da',
                  padding: '2px',
                  minHeight: '300px',
                }}
              >
                <CKEditor
                  editor={Editor}
                  data={question}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setQuestion(data)
                  }}
                />
              </div>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Option 1</Form.Label>
              <div
                style={{
                  border: '1px solid #ced4da',
                  padding: '2px',
                  minHeight: '300px',
                }}
              >
                <CKEditor
                  editor={Editor}
                  data={option1}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setOption1(data)
                  }}
                />
              </div>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Option 2</Form.Label>
              <div
                style={{
                  border: '1px solid #ced4da',
                  padding: '2px',
                  minHeight: '300px',
                }}
              >
                <CKEditor
                  editor={Editor}
                  data={option2}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setOption2(data)
                  }}
                />
              </div>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Option 3</Form.Label>
              <div
                style={{
                  border: '1px solid #ced4da',
                  padding: '2px',
                  minHeight: '300px',
                }}
              >
                <CKEditor
                  editor={Editor}
                  data={option3}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setOption3(data)
                  }}
                />
              </div>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Option 4</Form.Label>
              <div
                style={{
                  border: '1px solid #ced4da',
                  padding: '2px',
                  minHeight: '300px',
                }}
              >
                <CKEditor
                  editor={Editor}
                  data={option4}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setOption4(data)
                  }}
                />
              </div>
            </Form.Group>
          </Col>
          <div style={{ height: "15px" }}></div>
          <Col md={4}>
            <Form.Label>Type of Question</Form.Label>
          </Col>
          <Col md={8}>
            <Form.Control
              name="ques_type_id"
              value={values.ques_type_id}
              onChange={(e) => { handleChange(e); setAnswer([]) }}
              className="mb-3"
              as="select"
            >
              <option value="S">Single Choice</option>
              {/* <option value="M">Multiple Choice</option> */}
            </Form.Control>
          </Col>
          <Col md={4}>
            <Form.Label>Answer</Form.Label>
          </Col>
          <Col md={8}>
            <Form.Control
              name="answer"
              value={values.ques_type_id === 'S' ? (answer.length > 0 ? answer[0] : "") :answer}
              onChange={(e) => answerSelected(e.target.value)}
              className="mb-3"
              as="select"
              multiple={values.ques_type_id === 'S' ? false : true}
            >
              <option value="">Select Answer</option>
              <option value="1">Option 1</option>
              <option value="2">Option 2</option>
              <option value="3">Option 3</option>
              <option value="4">Option 4</option>
            </Form.Control>
          </Col>
        </Row>
        <br />
        <Button type="submit" onClick={handleAssessmentQuestion}>
          Submit
        </Button>
      </div>
    </>
  );
}
