import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import "../user.css"
import { useLocation, useNavigate } from 'react-router-dom';
const SecondaryHeader = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [userName, setUserName] = useState(null);

    useEffect(() => {
        let username = localStorage.getItem("endUserName");
        if (username !== null) {
            setUserName(username)
        } else {
            navigate("/user/auth/login")
        }
    }, [])

    const logmeOut = () => {
        localStorage.clear();
        navigate("/user/auth/login")
    }

    return (
        <div style={{ backgroundColor: "#212529" }} className="secondary-header">
            <div>
                <Row style={{margin:0}}>
                    <Col md={1}>

                    </Col>
                    <Col md={5} style={{display:'flex', alignItems:'center'}}>
                        <a href="/" onClick={(e) => e.preventDefault()}>Support</a>
                        <div className="menu-divider" />
                        <a href="/" onClick={(e) => e.preventDefault()}>Subscribe</a>
                        <div className="menu-divider" />
                        <a href="/" onClick={(e) => e.preventDefault()}>English</a>
                    </Col>
                    <Col md={5}>
                        {userName !== null ?
                            <div className="usergreeting-div">
                                <i class="far fa-user-circle"></i>
                                <p className="usergreeting-text">Hi, {userName}{"    "}(<a style={{ color: "#0000ff", marginRight:"0px"}} href="/" onClick={(e) => { e.preventDefault(); logmeOut() }}>Logout</a>)</p>
                            </div> : null}
                    </Col>
                    <Col md={1}>

                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default SecondaryHeader;