import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import "./homepage.css";
import Footer from "./Footer";
import ReactPlayer from "react-player";
import axios from 'axios';
import { site_ip } from '../config';

export default function Home() {
  const videoRef = useRef(null)
  const params = useParams();
  const navigate = useNavigate();
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('endUserToken') },
  };
  const [currentProgress, setCurrentProgress] = useState({});
  const [lesson, setLesson] = useState({})
  const [lessons, setLessons] = useState([]);
  const getLessons = async (course_id) => {
    let req = {
      course_id: course_id,
      user_id: localStorage.getItem("user_id")
    }
    try{
      await axios
      .post(
        `${site_ip}/getCourseLessonsByUser`,
        req,
        jwtHeader
      )
      .then((res) => {
        setLessons(res.data?.Lessons)
      })
      .catch((err) => console.log(err));
    }catch(e){

    }
  };

  const getLessonById = async (video_id) => {
    try{
      await axios
      .get(
        `${site_ip}/getCourseLessonByLessonId/`+video_id,
        jwtHeader
      )
      .then((res) => {
        setLesson(res.data?.Lessons[0])
      })
      .catch((err) => console.log(err));
    }catch(e){

    }
  };

  const getCurrentProgress = async () => {
    let req = {
      user_id:localStorage?.getItem("user_id"),
      course_id: params?.id,
      lesson_id: params?.video_id,
    }
    try{
      await axios
      .post(
        `${site_ip}/getCourseProgress`,
        req,
        jwtHeader
      )
      .then((res) => {
        setCurrentProgress(res.data?.Content?.length > 0 ? res.data?.Content[0] : {})
        if(res.data?.Content?.length > 0){
          // let sec = hmsToSecondsOnly(res.data?.Content[0]?.time_spent)
          if(videoRef !== null){
            setTimeout(() => {
              console.log("called")
              videoRef?.current?.seekTo(res.data?.Content[0]?.time_spent)
            }, 2000);
          }
        }
      })
      .catch((err) => console.log(err));
    }catch(e){

    }
  };

  function hmsToSecondsOnly(str) {
    console.log(str)
    var p = str.split(':'),
        s = 0, m = 1;

    while (p.length > 0) {
        s += m * parseInt(p.pop(), 10);
        m *= 60;
    }

    return s;
}

  useEffect(() => {
    if(params?.video_id !== undefined){
      getLessonById(params?.video_id);
      getLessons(params?.id)
      getCurrentProgress();
    }else{
      navigate("/")
    }

    return () => {
      
    }
  }, []);

  const updateCourseProgress = async (completed, seconds) => {
    
    let req = {
      user_id:localStorage?.getItem("user_id"),
      course_id: params?.id,
      time_spent:seconds,
      course_start_time:'',
      lesson_id: params?.video_id,
      video_status:completed,
      assessment_status:0,
      status: 0
    }
    if(currentProgress?.progress_id !== undefined){
      req = {...req, progress_id: currentProgress?.progress_id}
    }
    try{
      await axios
      .post(
        currentProgress?.progress_id !== undefined ? `${site_ip}/setCourseProgress` : `${site_ip}/createCourseProgress`,
        req,
        jwtHeader
      )
      .then((res) => {
        if(currentProgress?.progress_id === undefined){
          setCurrentProgress(res.data.Data)
        }else{
          if(completed === 2){
            getLessons(params?.id)
          }
        }
      })
      .catch((err) => console.log(err));
    }catch(e){

    }
  };


  return (
    <div>
      {/* <div className="m-3">
        <div className="box-header">
          <span>Short Certificate Course Name</span>
        </div>
      </div> */}
      <div className="m-3">
        <Row>
          
          <Col md={9} className="float-end">
            <div className="box p-3">
              <p className="course-card-title">{lesson?.lesson_name}</p>
              <div className="divider mb-3"></div>
              <div className='react-player'>
                <ReactPlayer
                    controls={true}
                    ref={videoRef}
                    url={lesson?.lesson_video}
                    height="100%"
                    playing={false}
                    width='100%'
                    progressInterval={30000}
                    onEnded={() => updateCourseProgress(2, 0)}
                    onProgress={(e) => {console.log(e); updateCourseProgress(1, e?.playedSeconds)}}
                />
              </div>
            </div>  
          </Col>
          <Col md={3} className="float-start">
            <div className="box p-3 video-page-thumbnails">
              <Row>
                {lessons?.map((item, index)=>{
                  return(
                    <CourseVideoThumbnail key={index} item={item} course_id={params?.id} last_item={lessons[index - 1]}/>
                  )
                })}
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
}


const CourseVideoThumbnail = ({item, course_id, last_item}) => {
  const navigate = useNavigate();
  const [waiting, setWaiting] = useState(false);
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('endUserToken') },
  };
  
  const startAssessment = async () => {
    try{
      await axios
      .get(
        `${site_ip}/getCourseLessonQuestions/`+item?.lesson_id,
        jwtHeader
      )
      .then((res) => {
        navigate("/user/assessment/test", {state: {data: res.data.Lessons, test_type: "Lesson Assessment", lesson_id: item?.lesson_id, course_id: course_id  }})
      })
      .catch((err) => console.log(err));
    }catch(e){

    }
  };

  const showError = () =>{
    setWaiting(true)
    setTimeout(()=>{
      setWaiting(false)
    }, 4000)
  }
  
  return (
    <Col md={12} className={"mb-3"}>
      <div className="box p-3">
        <div className="video-thumbnail-div">
          <img src={item?.lesson_thubmnail_img_name}  />
          {item?.video_status !== null && item?.status !== 2 ?
          <div className="fixed-continue-div">
            <button className="progress-btn play-btn" onClick={() => null} style={{cursor:"initial"}} >
              <span style={{marginLeft:"5px"}}>In Progress</span>
            </button>
          </div> : null }
          {item?.video_status !== null && item?.video_status === 2 ?
          <div className="fixed-continue-div">
            <button className="complete-btn play-btn" onClick={() => null} style={{cursor:"initial"}} >
              <span style={{marginLeft:"5px"}}>Completed</span>
            </button>
          </div> : null }
          {item?.video_status === null ?
          <div className="fixed-watchnow-div">
            <button className="coa-btn play-btn" disabled={last_item?.status !== undefined && last_item?.status !== 2 ? false : false} onClick={() => last_item?.status !== undefined && last_item?.status !== 2 ? showError() : navigate("/course/"+course_id+"/video/"+item?.lesson_id)} style={{opacity: last_item?.status !== 2 && last_item?.status !== undefined ? 0.6 : 1}}  >
              <i className="far fa-play-circle" size={{marginRight:"10px"}}></i>
              <span style={{marginLeft:"5px"}}>{item?.video_status !== null && item?.video_status === 2 ? "Watch Again" : "Watch Now"}</span>
            </button>
          </div> : null }
        </div>
        <div className="divider mb-3"></div>
        <div className="director-div pt-3">
          <Row>
            <Col md={12} sm={12}>
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: "100%" }}>
                <p className="course-card-label">Topic:</p>
                <p className="doctor-name">
                  {item?.lesson_name}
                </p>
              </div>
            </Col>
            <Col md={12} sm={12}>
              <div style={{ marginTop:"10px", display: "flex", flexDirection: "column", justifyContent: "center", height: "100%", opacity: item?.video_status === 2 || item?.assessment_status === 1 ? 1 : 0.6 }}>
                <button disabled={item?.video_status === 2 || item?.assessment_status === 1 ? (item?.assessment_status === 2 ? true : false) : true} onClick={() => startAssessment()} className="coa-btn">{item?.assessment_status === 2 ? "Assessment Completed" : "Start Assessment"}</button>
              </div>
            </Col>
          </Row>
        </div>
        <div className="mb-3 mt-3"></div>
        <div>
          
        </div>
      </div>
      <Modal show={waiting}>
          <Modal.Body style={{ textAlign: 'center' }}>In order to move to the next video, please complete watching the previous video and assessment.</Modal.Body>
        </Modal>
    </Col>
  )
}
