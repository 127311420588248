import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Table, Button, Container } from 'react-bootstrap';
import API from '../common/API';

export default function Commentary(props) {
  const [cases, setCases] = useState([]);

  const fetchCases = async () => {
    try {
      setCases(await API.fetchMedicalCases());
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchCases();
  }, []);
  return (
    <>
      <Row style={{ backgroundColor: '#0C66A6' }}>
        <Col></Col>
        <Col>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              alt="dynamism"
              src="/img/Expert_Commentaries.svg"
              className="offerImages"
            />
          </div>
        </Col>
        <Col></Col>
      </Row>
      <br />
      <Container>
        <Row>
          <Col>
            <Table striped hover>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Review</th>
                  <th>Author</th>
                  <th>Section Name</th>
                  <th>View</th>
                  <th>Download</th>
                  {/* <th>Play Video</th> */}
                </tr>
              </thead>
              <tbody>
                {cases?.map((item, i) => (
                  <tr>
                    <td>{i + 1}</td>
                    <td>ABC</td>
                    <td>XYZ</td>
                    <td>Section {i + 1}</td>
                    <td>
                      <i className="fas fa-eye"></i>
                    </td>
                    <td>
                      <i className="fas fa-download"></i>
                    </td>
                    {/* <td>
                      <i className="fas fa-play"></i>
                    </td> */}
                    {/* <td>{item.case_id}</td>
                    <td>{item.case_name}</td>
                    <td>{item.patient_name}</td>
                    <td>
                      <Link to={`/lms/view-cases/${item.case_id}`} state={item}>
                        View
                      </Link>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
}
