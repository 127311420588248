import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import API from '../API';

export default function VideoDetails(props) {
  const params = useParams();
  const [video, setVideo] = useState([]);
  const handleVideo = async (video_id) => {
    try {
      setVideo(await API.fetchVideoById(video_id));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleVideo(params.video_id);
    /* eslint-disable-next-line */
  }, []);
  return (
    <Container>
      <br />
      <h3 style={{ textAlign: 'center' }}>{video.video_title}</h3>
      <div className="d-flex align-items-center justify-content-center">
        <video
          width="720px"
          height="450px"
          controls
          poster="https://cf-images.ap-southeast-1.prod.boltdns.net/v1/static/6197335189001/d75d3def-1f2f-47b7-94ab-4114726ec7ab/3d3ba397-f3f7-4a5d-8713-1a03af7ff754/1280x720/match/image.jpg
"
        >
          <source src="movie.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </Container>
  );
}
