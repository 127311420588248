import React, { useEffect, useState } from "react"
import Header from "../../common/Header"
import Menu from "../../common/Menu";
import axios from 'axios';
import { site_ip } from '../../../config';
import { Container, Col, Row } from "react-bootstrap";
import "./book.css";
import { useParams, useNavigate } from "react-router-dom";
const BookDetails = () => {
    const navigate = useNavigate();
    const params = useParams();
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('endUserToken') },
    };
    const [activeTab, setActiveTab] = useState(1);
    const [bookDetails, setBookDetails] = useState({})
    const [chapterList, setChapterList] = useState([])
    const [authors, setAuthors] = useState([]);
    const [sectionList, setSectionList] = useState([]);
    const [chapterExpand, setChapterExpand] = useState(false);
    useEffect(() => {
        getBookById(params?.id);
        getChapters(params?.id);
        getAuthorsByBookId(params?.id)
    }, [])

    const getAuthorsByBookId = (id) => {
        axios
            .get(site_ip + '/getBookAuthorByBook/' + id, jwtHeader)
            .then((res) => {
                let authors_list = res.data.Content;
                authors_list.sort((a,b) => b.author_disp_seq - a.author_disp_seq);
                let authors = [];
                authors_list.map((item)=>{
                    // let auth = item?.salutation+" "+item?.first_name+" "+item?.last_name
                    let auth = item?.first_name+" "+item?.last_name
                    authors.push(auth)
                })
                setAuthors(authors);
            })
            .catch((err) => console.log(err));
    };

    const getBookById = (id) => {
        axios
            .get(site_ip + '/getBookById/' + id, jwtHeader)
            .then((res) => {
                setBookDetails(res.data.Books);

            })
            .catch((err) => console.log(err));
    };

    const getChapters = (id) => {
        axios
            .get(site_ip + '/getBookChapterBySection/' + id, jwtHeader)
            .then((res) => {
                console.log(res.data)
                // check if sections present
                let sections = [];
                if (res.data?.Sections?.length > 0) {
                    res.data?.Sections?.map((item) => {
                        let chapter_list = res.data?.Content?.filter((chapter) => item.section_id === chapter.section_id)
                        let ele = {
                            section: item,
                            chapters: chapter_list
                        }
                        sections.push(ele)
                    })
                }
                // Look for chapters having no section mapped
                let chapter_list = res.data?.Content?.filter((chapter) => chapter.section_id === null)
                if (chapter_list.length > 0) {
                    let ele = {
                        section: null,
                        chapters: chapter_list
                    }
                    sections.push(ele)
                }
                setSectionList(sections)
            })
            .catch((err) => console.log(err));
    };

    

    return (
        <>
            <Container style={{marginTop:"25px"}}>
                <Row>
                    <Col md={3}>
                        <div>
                            <img src={bookDetails?.book_logo} className="book-detail-logo" />
                        </div>
                        <div className="book-detail-menu">
                            {bookDetails?.book_overview !== null && bookDetails?.book_overview !== "" ?
                            <div>
                                <button onClick={() => navigate("/user/books/"+params?.id+"/section/overview")}>
                                    Overview
                                </button>
                            </div> : null }
                            {bookDetails?.copyright_details !== null && bookDetails?.copyright_details !== "" ? 
                            <div>
                                <button onClick={() => navigate("/user/books/"+params?.id+"/section/copyright")}>
                                    Copyright
                                </button>
                            </div> : null }
                            {bookDetails?.preface !== null && bookDetails?.preface !== "" ?
                            <div>
                                <button onClick={() => navigate("/user/books/"+params?.id+"/section/preface")}>
                                    Preface
                                </button>
                            </div> : null }
                            {/* {bookDetails?.dedication !== null && bookDetails?.dedication !== "" ? */}
                            <div>
                                <button onClick={() => navigate("/user/books/"+params?.id+"/section/authors")}>
                                    Authors/Editors
                                </button>
                            </div> 
                            {/* : null } */}
                            {/* {bookDetails?.book_desc !== null && bookDetails?.book_desc !== "" ? */}
                            {/* <div>
                                <button onClick={() => alert("Under development")}>
                                    Contributors
                                </button>
                            </div>  */}
                            {/* : null } */}
                            
                            {/* {bookDetails?.foreward !== null && bookDetails?.foreward !== "" ?
                            <div>
                                <button onClick={() => navigate("/user/books/"+params?.id+"/section/forward")}>
                                    Forward
                                </button>
                            </div> : null }
                            
                            {bookDetails?.book_suppl_details !== null && bookDetails?.book_suppl_details !== "" ?
                            <div>
                                <button onClick={() => navigate("/user/books/"+params?.id+"/section/supplementary_information")}>
                                    Supplementary information
                                </button>
                            </div> : null } */}
                            {/* <div>
                                <button onClick={() => navigate("/user/books/"+params?.id+"/section/editors")}>
                                    Editors
                                </button>
                            </div>
                            <div>
                                <button onClick={() => navigate("/user/books/"+params?.id+"/section/cover_illustration")}>
                                    Cover Illustration
                                </button>
                            </div> */}
                            {/* <div>
                                <button onClick={() => navigate("/user/books/"+params?.id+"/section/copyright")}>
                                    Related Harrison's Resources
                                </button>
                            </div> */}
                            {/* <div>
                                <button onClick={() => navigate("/user/books/"+params?.id+"/section/contributors")}>
                                    Contributors
                                </button>
                            </div> */}
                            
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="padding-vertical-15">
                            <p className="black label-6 mb-5" style={{ marginBottom: 0 }}>{bookDetails.book_sub_title}</p>
                            <p className="black label-2 mb-5">{bookDetails?.book_title}</p>
                            <p className="authors-name">
                                {authors.join(", ")}
                            </p>
                        </div>
                        <div>
                            <div className="book-detail-menu" style={{display:"flex", justifyContent:"center", borderLeft:0, borderRight:0}} >
                                <div>
                                    <button onClick={() => setChapterExpand(true)} style={{borderBottom:0}}>
                                        Show Chapters
                                    </button>
                                </div>
                                <div>
                                    <button onClick={() => setChapterExpand(false)} style={{borderBottom:0}}>
                                        Hide Chapters
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div>
                            {sectionList?.map((item, index) => {
                                return (
                                    <SectionItem item={item} key={index} book_id={bookDetails?.book_id} chapterExpand={chapterExpand} />
                                )
                            })}
                        </div>
                    </Col>
                    <Col md={3}></Col>
                </Row>
            </Container>
            {/* <div className="padding-vertical-15">
                <Container>
                    <div style={{ display: "flex" }}>

                        <div style={{ padding: "0px 15px" }}>
                            <p className="preface-text">{bookDetails?.preface}</p>
                            <div className="book-meta">
                                <Row>
                                    <Col>
                                        <p><div className="bullet" /> ISBN: {bookDetails?.isbn}</p>
                                    </Col>
                                    <Col>
                                        <p><div className="bullet" /> Type: {bookDetails?.book_type}</p>
                                    </Col>
                                    <Col>
                                        <p><div className="bullet" /> BISAC: {bookDetails?.bisac}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p><div className="bullet" /> Edition: {bookDetails?.edition}</p>
                                    </Col>
                                    <Col>
                                        <p><div className="bullet" /> Volume: {bookDetails?.volume}</p>
                                    </Col>
                                    <Col></Col>
                                </Row>
                            </div>

                        </div>
                    </div>
                </Container>
            </div>
            <div className="padding-vertical-15">
                <Container>
                    <Row>
                        <Col md={9}>
                            <div style={{ display: "flex" }}>
                                <button className={activeTab === 1 ? "dashboard-tab-btn active" : "dashboard-tab-btn"} onClick={() => setActiveTab(1)}>Table of Contents</button>
                                <button className={activeTab === 2 ? "dashboard-tab-btn active" : "dashboard-tab-btn"} onClick={() => setActiveTab(2)}>Description</button>
                                <button className={activeTab === 3 ? "dashboard-tab-btn active" : "dashboard-tab-btn"} onClick={() => setActiveTab(3)}>Overview</button>
                                {bookDetails?.book_suppl_details !== null ? <button className={activeTab === 4 ? "dashboard-tab-btn active" : "dashboard-tab-btn"} onClick={() => setActiveTab(4)}>Description</button> : null}
                            </div>
                            {activeTab === 1 ?
                                <div className="chapter-content-box">
                                    {chapterList.map((item, index) => {
                                        return (
                                            <div className="content-row">
                                                <button className="chapter-btn" onClick={() => openChapter(item.chapter_id)}>
                                                    <p>{item.chapter_title}</p>
                                                </button>
                                            </div>
                                        )
                                    })}
                                </div> : null}
                            {activeTab === 2 ?
                                <div className="chapter-content-box padding-15 custom-text-css">
                                    <RawHTML>{bookDetails?.book_desc}</RawHTML>
                                </div> : null}
                            {activeTab === 3 ?
                                <div className="chapter-content-box padding-15">
                                    <RawHTML>{bookDetails?.book_overview}</RawHTML>
                                </div> : null}
                            {activeTab === 4 ?
                                <div className="chapter-content-box padding-15">
                                    <RawHTML>{bookDetails?.book_suppl_details}</RawHTML>
                                </div> : null}
                            <div><RawHTML>{bookDetails?.copyright_details}</RawHTML></div>
                        </Col>
                        <Col md={3}>
                        </Col>
                    </Row>
                </Container>
            </div> */}
        </>
    )
}

export default BookDetails

const RawHTML = ({ children, className = "" }) => {
    return (
        children !== undefined ?
            <div className={className}
                dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, '<br />') }} /> : <div />
    )
}


const SectionItem = ({ item, chapterExpand, book_id }) => {
    const navigate = useNavigate();
    const [expended, setExpended] = useState(item.section === null ? true : false)

    useEffect(() => {
        setExpended(chapterExpand)
    }, [chapterExpand])

    const openChapter = (id) => {
        navigate("/user/books/" + book_id + "/chapter/" + id);
    }

    return (
        <div style={{ borderBottom: "1px solid #ccc" }}>
            {item.section !== null ?
                <div className="content-row">
                    <button className="section-btn" onClick={() => setExpended(!expended)}>
                        <p style={{ paddingRight: "15px" }}>{item.section?.section_name}</p>
                        <p style={{ fontSize: "16px", position: "absolute", right: 0 }}>{expended ? "-" : "+"}</p>
                    </button>
                </div> : null}
            {expended ?
                <div style={{ paddingLeft: "20px" }}>
                    {item?.chapters?.map((chapter, index) => {
                        return (
                            <button key={index} className="chapter-button" onClick={() => openChapter(chapter.chapter_id)}>
                                <p><b>Chapter {index + 1}: </b>{chapter?.chapter_title}</p>
                            </button>
                        )
                    })}
                </div> : null}
        </div>
    )
}