import React, { useState } from 'react';
import Aux from '../hoc/_Aux';
import { site_ip } from '../globalSetting';
import axios from 'axios';
import './admin.css';
// import Logo from '../assets/DKN.png';
// import Logo2 from '../assets/Knowledge.png';
// import {publicIpv4} from 'public-ip';
// const { detect } = require('detect-browser');
// const browser = detect();

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const handleChange = (e) => {
    setEmail(e.target.value);
  };
  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = () => {
    axios({
      method: 'post',
      url: site_ip + '/auth/adminSignin',
      data: {
        admin_email: email,
        password: password,
      },
      dataType: 'json',
    })
      .then(function (response) {
        console.log(response);
        if (response.data.accessToken) {
          localStorage.setItem(
            'userdata',
            JSON.stringify(response.data.content)
          );
          localStorage.setItem('admin_token', response.data.accessToken);
          redirectUser();
          // updateActivities(response.data.content, response.data.accessToken);
        } else {
          alert('Invalid credentials');
        }
      })
      .catch(function (error) {
        console.log('error1' + error);
      });
  };

  const redirectUser = () => {
    setTimeout(() => {
      window.location.href = '/admin/news';
    }, 1000);
  };

  // const updateActivities = async(content, token) =>{
  //   const jwtHeader = {
  //     headers: { 'access-token': token },
  //   };
  //   let req = {
  //     admin_id: content?.admin_id,
  //     user_ip: await publicIpv4(),
  //     user_browser: browser?.name,
  //     user_device: browser.os,
  //     login_datetime: new Date()
  //   }

  //   axios.post(site_ip + "/addAdminLoginActivities", req, jwtHeader)
  //     .then((res) => {
  //       redirectUser();
  //     }).catch((err) =>
  //       console.log(err)
  //     )
  // }

  return (
    <Aux>
      <div className="bg_admin">
        <div className="container">
          
          <div className="row justify-content-center align-items-center h-100">
            <div className="col-md-6 col-sm-12 mx-auto">
                
              <div className="bg-white p-4 border rounded-3">
              <img
                src={"/images/icp.jpeg"}
                alt="DKN"
                width="100%"
                style={{marginBottom:"30px"}}
              />
                <div className="row align-items-center">
                  <div className="col-md-12 ml-auto">
                    <div className="">
                      <h4 className="m-0 font-weight-normal">
                        Admin Login
                      </h4>
                      <hr />
                      <div className="form-group mb-3">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          value={email}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group mb-4">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Password"
                          value={password}
                          onChange={handleChangePassword}
                        />
                      </div>

                      <button
                        className="btn btn-primary shadow-2 mb-4"
                        onClick={handleLogin}
                      >
                        Login
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Aux>
  );
}

export default Login;
