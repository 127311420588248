import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import "../user.css"
import { useLocation, useNavigate } from 'react-router-dom';
const Footer = () => {

    return (
        <div style={{ backgroundColor: "#212529", height: "60px", display:"flex", justifyContent:"center" }}>
            <div style={{
                background: "#212529",
                color: "#ffffff",
                fontSize: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}>
                © 2023, Knowledge Bridge. All Rights Reserved.
            </div>
        </div>
    )
}

export default Footer;