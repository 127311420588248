import React, { useEffect, useState } from 'react';
import Header from "../../common/Header"
import Menu from "../../common/Menu"
import axios from 'axios';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { site_ip } from '../../../config';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import Footer from '../../../Components/Footer';
import "./news.css";
const News = () => {
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('endUserToken') },
    };
    const params = useParams();

    // const [isLiked, setIsLiked] = useState(false);
    const [related, setRelated] = useState([]);
    const [liked, setLiked] = useState(false)
    const navigate = useNavigate();
    useEffect(() => {
        if (params.id !== undefined) {
            getNewsByNewsId(params.id)
        }
    }, [params.id])

    const [newsDetails, setNewsDetails] = useState({});
    const getNewsByNewsId = (id) => {
        setLiked(false)
        let req = {
            news_id: id,
            user_id: parseInt(localStorage.getItem("user_id") !== null ? localStorage.getItem("user_id") : 0)
        }
        axios
            .post(site_ip + '/getNewsByNewsId', req, jwtHeader)
            .then((res) => {

                console.log(res.data.News[0].flipbook_folder)
                setNewsDetails(res.data.News[0]);
                // if(localStorage.getItem("user_id") !== null){
                //     updateViews(id)
                // }
                setRelated(res.data.RelatedNews)
                window.scrollTo(0, 0)
            })
            .catch((err) => console.log(err));
    };

    const updateViews = (id) => {
        axios
            .get(site_ip + '/setNewsViews/' + id, jwtHeader)
            .then((res) => {

            })
            .catch((err) => console.log(err));
    }

    const updateLike = (id) => {

        if (localStorage.getItem("user_id") !== null) {
            let req = {
                user_id: parseInt(localStorage.getItem("user_id")),
                news_id: newsDetails.news_id
            };

            axios
                .post(site_ip + '/setNewsLikes', req, jwtHeader)
                .then((res) => {
                    setLiked(true)
                })
                .catch((err) => console.log(err));
        } else {
            navigate("/user/auth/login", {state: {id: newsDetails.news_id, name: newsDetails?.news_title?.split(' ').join('_')}})
        }
    }

    return (
        <div>
            <Container>
                <div className="box p-3 m-3">
                    <Row style={{ margin: 0 }}>
                        <Col md={8}>
                            <div style={{ padding: "15px 0px 150px 0px" }}>
                                {newsDetails?.thumbnail !== null ?
                                    <div className="imageBox">
                                        <img alt="news-image" src={newsDetails?.thumbnail} />
                                    </div> : null}
                                <p className="news-thumbnail-category">{newsDetails?.category_name}</p>
                                <p className="news-title">{newsDetails?.news_title}</p>
                                <p className="news-subtitle">{newsDetails?.news_subtitle}</p>
                                <div style={{ display: "flex", marginTop: "10px" }}>
                                    {newsDetails?.author_name !== null && newsDetails?.author_name !== "" ?
                                        <div style={{ display: 'flex', alignItems: 'center', marginRight: "20px" }}>
                                            <i className="fas fa-user-edit"></i>
                                            <p className='author-name'>{newsDetails?.author_name}</p>
                                        </div> : null}
                                    {newsDetails?.publish_date !== null && newsDetails?.publish_date !== "" ?
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <i class="fas fa-calendar-alt"></i>
                                            <p className='author-name'>{moment(newsDetails?.publish_date).format("DD, MMM YYYY")}</p>
                                        </div> : null}
                                </div>
                                <Row style={{ marginTop: "25px" }}>
                                    <Col>
                                        <div style={{ display: "flex" }}>
                                            <div className="count-div">
                                                <i class="fa fa-heart" aria-hidden="true" style={{ color: "#ff0000" }}></i>
                                                <p>{newsDetails?.likes_cnt + (liked ? 1 : 0)}</p>
                                            </div>
                                            <div className="count-div" style={{ marginLeft: "25px" }}>
                                                <i class="fa fa-eye" aria-hidden="true"></i>
                                                <p>{newsDetails?.views_cnt}</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                            {newsDetails?.like_status === "Liked" || liked ?
                                                <button className="liked-btn">
                                                    <FavoriteIcon style={{ color: "#ff0000" }} />
                                                    {/* Like */}
                                                </button> :
                                                <button className="like-btn" onClick={() => updateLike()}>
                                                    <FavoriteBorderIcon style={{ color: "#ccc" }} />
                                                    {/* Liked */}
                                                </button>}
                                        </div>
                                    </Col>
                                </Row>
                                <div style={{ marginTop: "10px", marginBottom: "50px" }}>
                                    <div
                                        style={{ height: "100%" }}
                                        dangerouslySetInnerHTML={{ __html: newsDetails?.news }} className="ck-content" />
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <p className="related-news-title">Related News</p>
                            {related?.map((item, index) => {
                                return (
                                    <NewsThumbnail item={item} key={index} />
                                )
                            })}
                        </Col>
                    </Row>
                </div>
            </Container>
            <Footer />
        </div>
    )
}

export default News;

const NewsThumbnail = (props) => {
    const item = props.item;
    const navigate = useNavigate();
    const location = useLocation();
    const navigateToPage = () => {
        if (location?.pathname === '/user/news') {
            navigate("/user/news/" + item.news_id + "/" + item.news_title?.split(' ').join('_'))
        } else {
            navigate("/news/" + item.news_id + "/" + item.news_title?.split(' ').join('_'))
        }
    }
    return (
        <div className='news-thumbnail-div'>
            <Row>
                <Col md={12}>
                    <a href="/" style={{ fontSize: "13px" }} onClick={(e) => { e.preventDefault(); navigateToPage(); }}>
                        <div className='news-thumbnail-img-div'>
                            {item?.thumbnail !== null ?
                                <img src={item?.thumbnail} alt="news_image" /> : null}
                        </div>
                    </a>
                </Col>
                <Col md={12}>
                    <p className="news-thumbnail-category">{item.subject_name}</p>
                    <p className="news-thumbnail-name" ><a href="/" style={{ fontSize: "13px" }} onClick={(e) => { e.preventDefault(); navigateToPage(); }}>{item.news_title}</a></p>
                    <Row>
                        <Col md={6}>
                            <div>
                                {/* {item?.author_name !== null && item?.author_name !== "" ?
                                    <div style={{ display: 'flex', alignItems: 'center', marginRight: "20px" }}>
                                        <i className="fas fa-user-edit"></i>
                                        <p className='author-name'>{item?.author_name}</p>
                                    </div> : null} */}
                                {item?.publish_date !== null && item?.publish_date !== "" ?
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <i class="fas fa-calendar-alt"></i>
                                        <p className='author-name'>{moment(item?.publish_date).format("DD, MMM YYYY")}</p>
                                    </div> : null}
                            </div>
                        </Col>
                        <Col md={6}>
                            <div style={{ display: "flex" }}>
                                <div className="count-div">
                                    <i class="fa fa-heart" aria-hidden="true" style={{ color: "#ff0000" }}></i>
                                    <p>{item?.likes_cnt}</p>
                                </div>
                                <div className="count-div" style={{ marginLeft: "25px" }}>
                                    <i class="fa fa-eye" aria-hidden="true"></i>
                                    <p>{item?.views_cnt}</p>
                                </div>
                            </div>
                        </Col>

                    </Row>
                </Col>
            </Row>
        </div>
    )
}