import React from "react";
// eslint-disable-next-line
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import Login from "./content/login";
// import Signup from './content/Signup';
import Logout from "./content/Logout";
import TopBar from "./content/TopBar";
import LeftSideBar from "./content/LeftSideBar";

import Dashboard from "./content/Dashboard";

//Institution
import AddInstitution from "./content/Institution/Add";
import InstitutionsList from "./content/Institution/View";
import EditInstitution from "./content/Institution/InstitutionEdit";
import WIP from "./content/Institution/WIP";

//Courses
import Courses from "./content/Courses/Courses";
import CourseDetails from "./content/Courses/CourseDetails";
import AddCourses from "./content/Courses/AddCourses";
import EditCourse from "./content/Courses/EditCourseSection";
//Expert Videos
import Videos from "./content/Videos/Videos";
import VideoDetails from "./content/Videos/VideoDetails";
import AddVideos from "./content/Videos/AddVideos";

// News
import NewsCreate from "./content/News/CreateNews";
import NewsEdit from "./content/News/EditNews";
import News from "./content/News/News";

// Users
import AdminUsers from "./content/users/AdminUsers";
import AddPublisherUsers from "./content/users/PublisherUsers/Add";
import PublisherUsersList from "./content/users/PublisherUsers/View";
import EditPublisherUsers from "./content/users/PublisherUsers/Edit";
import AddInstitutionUsers from "./content/users/InstitutionUsers/Add";
import InstitutionUsersList from "./content/users/InstitutionUsers/View";
import EditInstitutionUsers from "./content/users/InstitutionUsers/Edit";

import AddSiteUsers from "./content/users/SiteUsers/Add";
import SiteUsersList from "./content/users/SiteUsers/View";
import EditSiteUsers from "./content/users/SiteUsers/Edit";

import Fellowship from "./content/Fellowship/List";
import FellowshipView from "./content/Fellowship/View";


function App() {
  // const Route = ({
  //   component: Component,
  //   layout: Layout,
  //   ...rest
  // }) => (
  //   <Route
  //     {...rest}
  //     render={(props) => (
  //       <Layout>
  //         <Component {...props} />
  //       </Layout>
  //     )}
  //   />
  // );
  const BlankLayout = ({ children }) => <>{children}</>;
  const LayoutOfAfterLogin = (children) => (
    <>
      <TopBar />
      <LeftSideBar />
      <div className="right-block">
        <div className="content-wrapper">{children}</div>
      </div>
    </>
  );

  return (
      <Routes>
        {/* <Route
        exact
        path="/admin"
        layout={BlankLayout}
        element={<Login}
      /> */}
        <Route exact path="/" element={<Login />} />
        <Route exact path="/login" layout={BlankLayout} element={<Login />} />
        <Route exact path="/logout" layout={BlankLayout} element={<Logout />} />

        <Route
          path="/dashboard"
          exact
          element={LayoutOfAfterLogin(<Dashboard />)}
        />
        <Route
          path="/manage-institutions"
          exact
          element={LayoutOfAfterLogin(<InstitutionsList />)}
        />
        <Route
          path="/add-institution"
          exact
          element={LayoutOfAfterLogin(<AddInstitution />)}
        />
        <Route
          path="/edit-institution/:institution_id"
          exact
          element={LayoutOfAfterLogin(<EditInstitution />)}
        />
        <Route
          path="/manage-institutions-ipaddressess"
          exact
          element={LayoutOfAfterLogin(<WIP />)}
        />
        <Route
          path="/manage-institutions-subscriptions"
          exact
          element={LayoutOfAfterLogin(<WIP />)}
        />
        <Route
          path="/courses"
          exact
          element={LayoutOfAfterLogin(<Courses />)}
        />
        <Route
          path="/add-course"
          exact
          element={LayoutOfAfterLogin(<AddCourses />)}
        />
        <Route
          path="/edit-course/:id"
          exact
          element={LayoutOfAfterLogin(<EditCourse />)}
        />
        <Route
          path="/course/:course_id"
          exact
          element={LayoutOfAfterLogin(<CourseDetails />)}
        />
        <Route path="/videos" exact element={LayoutOfAfterLogin(<Videos />)} />
        <Route
          path="/add-video"
          exact
          element={LayoutOfAfterLogin(<AddVideos />)}
        />
        <Route
          path="/video/:video_id"
          exact
          element={LayoutOfAfterLogin(<VideoDetails />)}
        />
        <Route path="/news" exact element={LayoutOfAfterLogin(<News />)} />
        <Route
          path="/create-news"
          exact
          element={LayoutOfAfterLogin(<NewsCreate />)}
        />
        <Route
          path="/edit-news/:news_id"
          exact
          element={LayoutOfAfterLogin(<NewsEdit />)}
        />
        {/* <Route
          path="/question-listing"
          exact
          element={LayoutOfAfterLogin(<ExamQuestionSearch />)}
        /> */}
        {/* <Route
        path="/question-add"
        exact
        element={LayoutOfAfterLogin(<ExamQuestionAdd />)}
      /> */}
        {/* <Route
        path="/question-edit/:question_id"
        exact
        element={LayoutOfAfterLogin(<ExamQuestionEdit />)}
      /> */}
        {/* <Route
          path="/question"
          exact
          element={LayoutOfAfterLogin(<ExamQuestion />)}
        /> */}
        {/* <Route
          path="/question/:question_id"
          exact
          element={LayoutOfAfterLogin(<ExamQuestion />)}
        /> */}
        {/* <Route
          path="/question/osce/:osce_id"
          exact
          element={LayoutOfAfterLogin(<ExamQuestion />)}
        /> */}
        {/* <Route
          path="/osce-question-listing"
          exact
          element={LayoutOfAfterLogin(<OSCEQuestionSearch />)}
        /> */}
        {/* <Route
          path="/osce-question"
          exact
          element={LayoutOfAfterLogin(<OSCEExamQuestion />)}
        /> */}
        {/* <Route
          path="/osce-question/:question_id"
          exact
          element={LayoutOfAfterLogin(<OSCEExamQuestion />)}
        /> */}
        {/* <Route
          path="/osce-question/sub-question/:para_id"
          exact
          element={LayoutOfAfterLogin(<OSCEParaQuestions />)}
        /> */}
        {/* <Route
        path="/osce-question-add"
        exact
        element={LayoutOfAfterLogin(<OSCEQuestionAdd />)}
      />
      <Route
        path="/osce-question-edit/:question_id"
        exact
        element={LayoutOfAfterLogin(<OSCEQuestionEdit />)}
      /> */}
        <Route
          path="/manage-admin-users"
          exact
          element={LayoutOfAfterLogin(<AdminUsers />)}
        />
        <Route
          path="/manage-publisher-users"
          exact
          element={LayoutOfAfterLogin(<PublisherUsersList />)}
        />
        <Route
          path="/add-publisher-user"
          exact
          element={LayoutOfAfterLogin(<AddPublisherUsers />)}
        />
        <Route
          path="/edit-publisher-user/:user_id"
          exact
          element={LayoutOfAfterLogin(<EditPublisherUsers />)}
        />
        <Route
          path="/manage-site-users"
          exact
          element={LayoutOfAfterLogin(<SiteUsersList />)}
        />
        <Route
          path="/add-site-user"
          exact
          element={LayoutOfAfterLogin(<AddSiteUsers />)}
        />
        <Route
          path="/edit-site-user/:user_id"
          exact
          element={LayoutOfAfterLogin(<EditSiteUsers />)}
        />
        <Route
          path="/manage-institution-users"
          exact
          element={LayoutOfAfterLogin(<InstitutionUsersList />)}
        />
        <Route
          path="/add-institution-user"
          exact
          element={LayoutOfAfterLogin(<AddInstitutionUsers />)}
        />
        <Route
          path="/edit-institution-user/:user_id"
          exact
          element={LayoutOfAfterLogin(<EditInstitutionUsers />)}
        />
        {/* <Route
          exact
          path="/exam-dashboard"
          layout={LayoutOfAfterLogin}
          element={<ExamDashboard />}
        /> */}
        {/* <Route
          exact
          path="/exam-setup"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<ExamsSetup />)}
        /> */}
        {/* <Route
          exact
          path="/exam-student-prize-list"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<ExamStudentPrizeList />)}
        /> */}
        {/* <Route
          exact
          path="/exam-student-ranking-list"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<ExamStudentRankingList />)}
        /> */}
        {/* <Route
          exact
          path="/payment-detail-listing"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<PaymentDetailsListing />)}
        /> */}
        {/* <Route
          exact
          path="/exam-pat-setup"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<QuestionPatternSetup />)}
        />
        <Route
          exact
          path="/exam-pat-add-edit/:id"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<ExamPatternAddEdit />)}
        />
        <Route
          exact
          path="/student-appeared-for-exam"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<StudentAppearedForExam />)}
        /> */}
        {/* <Route
          exact
          path="/student-reg-list-by-exam"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<StudentRegListByExam />)}
        />
        <Route
          exact
          path="/student-score"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<StudentScore />)}
        />
        <Route
          exact
          path="/exam-listing"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<ExamsListing />)}
        />
        <Route
          exact
          path="/exam-setup-level1"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<ExamSetupLevel1 />)}
        /> */}

        {/* Fellowship routes */}
        <Route
          exact
          path="/fellowships"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<Fellowship />)}
        />
        <Route
          exact
          path="/fellowships/:id"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<FellowshipView />)}
        />
      </Routes>
  );
}

export default App;
