import React, { useState, useEffect } from 'react';
import { Container, Button, Col, Row, Modal } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import Plus from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Aux from '../../hoc/_Aux';
import { site_ip } from '../../globalSetting';

const IPAddressess = (props) => {
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('admin_token') },
    };
    const [show, setShow] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(0);
    const blankSubscription = {
        institution_id: props.id,
        // subscription_id: req.body.subscription_id,
        content_bundle_id: '',
        license_start_date: '',
        license_end_date: '',
        start_date: '',
        end_date: '',
        expire_date: '',
        display_status: '1',
    }

    const [subscriptions, setSubscriptions] = useState([blankSubscription]);
    const addNewSubscription = () => {
        setSubscriptions([...subscriptions, blankSubscription])
    }

    useEffect(() => {
        getEntries()
        // eslint-disable-next-line
    }, [])

    const getEntries = async () => {
        await axios
            .get(site_ip + '/getInstitutionSubscriptions/' + props.id, jwtHeader)
            .then(function (response) {
                if (response.data.Data.length !== 0) {
                    setSubscriptions(response.data.Data)
                } else {
                    setSubscriptions([blankSubscription])
                }
            })
            .catch(function (error) {
                console.log('error' + error);
            });
    }

    const saveEntry = async (item) => {

        //Check it is a new entry
        if (item.subscription_id === undefined) {
            // New Entry
            await axios
                .post(site_ip + '/addInstitutionSubscription', item, jwtHeader)
                .then(function (response) {
                    Swal.fire({
                        title: 'Success',
                        text: 'New Subscription added successfully',
                        icon: 'success',
                    });
                })
                .catch(function (error) {
                    console.log('error' + error);
                });
        } else {
            // Update Entry
            await axios
                .post(site_ip + '/setInstitutionSubscription', item, jwtHeader)
                .then(function (response) {
                    Swal.fire({
                        title: 'Success',
                        text: 'Subscription updated successfully',
                        icon: 'success',
                    });
                })
                .catch(function (error) {
                    console.log('error' + error);
                });
        }
    }


    const handleClose = () => setShow(false);

    const removeRecord = async () => {
        
        let item = {
            subscription_id: selectedRecord
        }
        handleClose();
        await axios
            .post(site_ip + '/deleteInstitutionIpAddress', item, jwtHeader)
            .then(function (response) {
                Swal.fire({
                    title: 'Success',
                    text: 'Subscription record removed successfully',
                    icon: 'success',
                });
                getEntries();
            })
            .catch(function (error) {
                console.log('error' + error);
            });
    }

    const deleteEntry = (subscription_id, index) => {
        if (subscription_id === undefined) {
            let all_subs = [...subscriptions];
            all_subs.splice(index, 1);
            setSubscriptions(all_subs);
        } else {
            // Need to hit API
            setSelectedRecord(subscription_id);
            setShow(true);
        }
    }

    return (
        <Aux>
            <Container fluid>
                <Row>
                    <Col className="text-end">
                        <Button variant="dark" onClick={() => addNewSubscription()}>
                            <Plus /> Add Subscriptions
                        </Button>
                    </Col>
                </Row>
                <Row className="rangeBox">
                    <Col>
                        {/* <TableHeader /> */}
                        {subscriptions.map((item, index) => {
                            return (
                                <SubscriptionComponent key={index} item={item} saveEntry={(item) => saveEntry(item)} deleteEntry={(subscription_id) => deleteEntry(subscription_id, index)} />
                            )
                        })}
                    </Col>
                </Row>
            </Container>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Body>
                    Are you sure to remove this record?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={removeRecord}>
                        Yes
                    </Button>
                    <Button variant="secondary" onClick={handleClose} >No</Button>
                </Modal.Footer>
            </Modal>
        </Aux>
    );
}

const TableHeader = () => {

    return (
        <Row className="tableRow" style={{ paddingTop: "5px", paddingBottom: "5px" }}>
            <Col xs={2} style={{ fontWeight: 'bold', paddingLeft: "20px" }}>
                IP From
            </Col>
            <Col xs={2} style={{ fontWeight: 'bold', paddingLeft: "20px" }}>
                IP To
            </Col>
            <Col xs={2} style={{ fontWeight: 'bold', paddingLeft: "20px" }}>
                INET From
            </Col>
            <Col xs={2} style={{ fontWeight: 'bold', paddingLeft: "20px" }}>
                INET To
            </Col>
            <Col xs={2} style={{ fontWeight: 'bold', paddingLeft: "20px" }}>
                Status
            </Col>
            <Col xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                Actions
            </Col>
        </Row>
    )
}

const SubscriptionComponent = (props) => {
    const [item, setItem] = useState(props.item);

    useEffect(() => {
        setItem(props.item)
    }, [props.item])

    function checkIP(ip) {
        var x = ip.split("."), x1, x2, x3, x4;

        if (x.length == 4) {
            x1 = parseInt(x[0], 10);
            x2 = parseInt(x[1], 10);
            x3 = parseInt(x[2], 10);
            x4 = parseInt(x[3], 10);

            if (isNaN(x1) || isNaN(x2) || isNaN(x3) || isNaN(x4)) {
                return false;
            }

            if ((x1 >= 0 && x1 <= 255) && (x2 >= 0 && x2 <= 255) && (x3 >= 0 && x3 <= 255) && (x4 >= 0 && x4 <= 255)) {
                return true;
            }
        }
        return false;
    }

    const onChange = (e) => {
        let prev_item = { ...item };
        prev_item[e.target.name] = e.target.value;
        setItem(prev_item)
    }

    const addNewSubscription = () => {
        props.saveEntry(item)
    }

    return (
        <Row className="tableRow">
            <Col xs={3}>
                <span>Bundle</span>
                <input className="form-input" onChange={onChange} type="number" min={1} name="content_bundle_id" value={item.content_bundle_id}></input>
            </Col>
            <Col xs={3}>
                <span>License Start Date</span>
                <input className="form-input" onChange={onChange} type="date" name="license_start_date" value={item.license_start_date}></input>
            </Col>
            <Col xs={3}>
                <span>License End Date</span>
                <input className="form-input" onChange={onChange} type="date" name="license_end_date" value={item.license_end_date}></input>
            </Col>
            <Col xs={3}>
                <span>Expire Date</span>
                <input className="form-input" onChange={onChange} type="date" name="expire_date" value={item.expire_date}></input>
            </Col>
            <Col xs={3}>
                <span>Start Date</span>
                <input className="form-input" onChange={onChange} type="date" name="start_date" value={item.start_date}></input>
            </Col>
            <Col xs={3}>
                <span>End Date</span>
                <input className="form-input" onChange={onChange} type="date" name="end_date" value={item.end_date}></input>
            </Col>
            <Col xs={3}>
                <span>Status</span>
                <select className="form-input" onChange={onChange} name="display_status" value={item.display_status}>
                    <option value="1">Active</option>
                    <option value="2">Deactive</option>
                </select>
            </Col>
            <Col xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                <Button variant="success" className="rowActionButton" onClick={() => addNewSubscription()}>
                    <AddBoxIcon />
                </Button>
                <Button variant="danger" className="rowActionButton" onClick={() => props.deleteEntry(item?.subscription_id)}>
                    <DeleteForeverIcon />
                </Button>
            </Col>
        </Row>
    )
}

export default IPAddressess 
