import React from "react";
import { Modal, Row, Col, Button, Table } from "react-bootstrap";
import "./Home.css";

const ThankYou = () => {
    return (
        <div>
            <Row style={{margin:0}}>
                <Col md={4}>
                </Col>
                <Col md={4}>
                    <div className="center-section">
                        <div className="thankyou-container">
                            <i className="fas fa-check-circle" color="#4caf50" style={{fontSize:"100px"}}></i>
                            <p className="title">THANK YOU!</p>
                            <p className="subtitle">Your application submitted successfully.</p>
                        </div>
                    </div>
                </Col>
                <Col md={4}>
                </Col>
            </Row>
        </div >
    );
};

export default ThankYou;
