import React, { useState, useEffect } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import "./homepage.css";
import Footer from "./Footer";
import axios from 'axios';
import { site_ip } from '../config';

export default function CourseDetails() {
  const user = JSON.parse(localStorage.getItem('userdata'));
  const [lessons, setLessons] = useState([]);
  const [allAttempted, setAllAttempted] = useState(0);
  const params = useParams();
  const navigate = useNavigate();
  const [waiting, setWaiting] = useState(false);
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('endUserToken') },
  };
  const getLessons = async (course_id) => {
    let req = {
      course_id: course_id,
      user_id: localStorage.getItem("user_id")
    }
    try {
      await axios
        .post(
          `${site_ip}/getCourseLessonsByUser`,
          req,
          jwtHeader
        )
        .then((res) => {
          const { Lessons, all_attempted } = res.data;
          setLessons(Lessons);
          setAllAttempted(prevAllAttempted => {
            // Access the previous state value here
            console.log("Previous allAttempted:", prevAllAttempted);
            return all_attempted;
          });
        })
        .catch((err) => console.log(err));
    } catch (e) {

    }
  };
  const showError = () => {
    // Your showError logic here
    setWaiting(true);
    setTimeout(() => {
      setWaiting(false);
    }, 4000);
  };

  const handleClick = (allAttempted) => {
    if (!allAttempted) {
      showError();
    } else {
      // Call another function
      localStorage.setItem('course_id', params?.id);
      navigate("/generate-certificate");
      // replace the following line with the function you want to call
      console.log("Calling another function");
    }
  };
  useEffect(() => {
    let token = localStorage.getItem("endUserToken")
    console.log(token)
    if (token === null) {
      navigate("/user/auth/login")
    } else {
      try {
        let end_user = JSON.parse(localStorage.getItem("endUser"))
        
      } catch (e) {
        navigate("/user/auth/login")
      }
    }
  }, [])

  useEffect(() => {
    if (params?.id !== undefined) {
      getLessons(params?.id);
    } else {
      navigate("/")
    }
  }, []);

  return (
    <div>
      {/* <div className="m-3">
        <div className="box-header">
          <span>Short Certificate Course Name</span>
        </div>
      </div> */}
      <div className="m-3">
        <div className="box-content">
          <Row>
            {lessons?.map((item, index) => {
              return (
                <CourseVideoThumbnail key={item.lesson_id} item={item} course_id={params?.id} last_item={lessons[index - 1]} />
              )
            })}

<Col md={3} className={"mb-3"}>

      <div className="box p-3"><a href="#" onClick={() => handleClick(allAttempted)}>
  <div className="animate_plus_45">
    
      <img className="course-tab-image" src="../images/Bonafide-Certificate-Format.jpg" />
      <p className="course-tab-text"> Get your Certificate</p>
 
  </div>
  
  </a></div>
</Col>
          </Row>
        </div>
      </div>
      <Modal show={waiting}>
          <Modal.Body style={{ textAlign: 'center' }}>In order to get your certificate - first please complete watching all the course videos and complete all the assessments</Modal.Body>
        </Modal>
      <Footer />
    </div>
  );
}


const CourseVideoThumbnail = ({ item, course_id, last_item }) => {
  const navigate = useNavigate();
  const [waiting, setWaiting] = useState(false);
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('endUserToken') },
  };

  const startAssessment = async () => {
    try {
      await axios
        .get(
          `${site_ip}/getCourseLessonQuestions/` + item?.lesson_id,
          jwtHeader
        )
        .then((res) => {
          navigate("/user/assessment/test", { state: { data: res.data.Lessons, test_type: "Lesson Assessment", lesson_id: item?.lesson_id, course_id: course_id } })
        })
        .catch((err) => console.log(err));
    } catch (e) {

    }
  };


  const showError = () =>{
    setWaiting(true)
    setTimeout(()=>{
      setWaiting(false)
    }, 4000)
  };



  return (
    <Col md={3} className={"mb-3"}>
      <div className="box p-3">
        <div className="video-thumbnail-div">
          <img src={item?.lesson_thubmnail_img_name} />
          {item?.video_status !== null && item?.status !== 2 ?
            <div className="fixed-continue-div">
              <button className="progress-btn play-btn" onClick={() => navigate("/course/" + course_id + "/video/" + item?.lesson_id)}>
                <span style={{ marginLeft: "5px" }}>Resume</span>
              </button>
            </div> : null}
          {item?.video_status !== null && item?.video_status === 2 ?
            <div className="fixed-continue-div">
              <button className="complete-btn play-btn" onClick={() => null} style={{ cursor: "initial" }} >
                <span style={{ marginLeft: "5px" }}>Completed</span>
              </button>
            </div> : null}
          {item?.video_status === null ?
            <div className="fixed-watchnow-div">
              <button className="coa-btn play-btn" disabled={last_item?.status !== undefined && last_item?.status !== 2 ? false : false} onClick={() => last_item?.status !== undefined && last_item?.status !== 2 ? showError() : navigate("/course/" + course_id + "/video/" + item?.lesson_id)} style={{ opacity: last_item?.status !== 2 && last_item?.status !== undefined ? 0.6 : 1 }}  >
                <i className="far fa-play-circle" size={{ marginRight: "10px" }}></i>
                <span style={{ marginLeft: "5px" }}>{item?.video_status !== null && item?.video_status === 2 ? "Watch Again" : "Watch Now"}</span>
              </button>
            </div> : null}
        </div>
        <div className="divider mb-3"></div>
        <div className="director-div pt-3">
          <Row>
            <Col md={12} sm={12}>
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: "100%" }}>
                <p className="course-card-label">Topic:</p>
                <p className="doctor-name">
                  {item?.lesson_name}
                </p>
              </div>
            </Col>
            <Col md={12} sm={12}>
              <div style={{ marginTop: "10px", display: "flex", flexDirection: "column", justifyContent: "center", height: "100%", opacity: item?.video_status === 2 || item?.assessment_status === 1 ? 1 : 0.6 }}>
                <button
                  disabled={item?.video_status === 2 || item?.assessment_status === 1 ? (item?.assessment_status === 2 ? true : false) : true}
                  onClick={() => startAssessment()} className="coa-btn">{item?.assessment_status === 2 ? "Assessment Completed" : "Start Assessment"}</button>
              </div>
            </Col>
          </Row>
        </div>
        <div className="mb-3 mt-3"></div>
        <div>

        </div>
        <Modal show={waiting}>
          <Modal.Body style={{ textAlign: 'center' }}>In order to move to the next video, please complete watching the previous video and assessment.</Modal.Body>
        </Modal>
      </div>
    </Col>
  )
}