import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { site_ip } from '../../../config';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import "./news.css"
const News = () => {
    const params = useParams();
    const navigate = useNavigate();
    const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('endUserToken') },
    };
    useEffect(() => {
        getSubjects();
        getMedicalCases();
    }, [])

    const [subjectList, setSubjectList] = useState([]);
    const [activeSubject, setActiveSubject] = useState(null);
    const [searchedString, setSearchString] = useState("");
    const [queryString, setQueryString] = useState("");
    const getSubjects = (id) => {
        axios
            .get(site_ip + '/getSubjects', jwtHeader)
            .then((res) => {
                setSubjectList(res.data.content);
            })
            .catch((err) => console.log(err));
    };

    const [medicalCasesList, setMedicalCasesList] = useState([]);
    const getMedicalCases = () => {
        axios
            .get(site_ip + '/getMedicalCases', jwtHeader)
            .then((res) => {
                setMedicalCasesList(res.data.Content);
            })
            .catch((err) => console.log(err));
    };

    const searchMedicalCases = (subject_id) => {
        let req;
        if(subject_id !== null){
            setSearchString("");
            req = {
                subject_id: subject_id 
            }
        }else{
            setActiveSubject(null)
            if(queryString.trim() === ""){
                return 
            }
            req = {
                news_title: queryString.trim() 
            }
            setSearchString(queryString.trim())
        }
        axios
            .post(site_ip + '/searchMedicalCases', req, jwtHeader)
            .then((res) => {
                setMedicalCasesList(res.data.Cases);
            })
            .catch((err) => console.log(err));
    };

    

    return (
        <div>
            <Container>
                <Row style={{ marginTop: "30px" }}>
                    <Col md={3}>
                        <p style={{ fontWeight: "bold", fontSize: "15px", color: "#000000" }}>Subjects</p>
                        {subjectList.map((item, index) => {
                            return (
                                <button key={index} className="news-subject-item" onClick={() => {searchMedicalCases(item.subject_id); setActiveSubject(item)}} >{item.subject_name}</button>
                            )
                        })}
                    </Col>
                    <Col md={9}>
                        <Row style={{ margin: 0, marginBottom:"15px" }}>
                            <Col style={{display:"flex", alignItems:"center"}}>
                                <p style={{ fontWeight: "bold", fontSize: "15px", color: "#000000" }}>Medical Cases</p>
                            </Col>
                            <Col>
                                <div className="search-round-div">
                                    <input placeholder='Search medical cases...' value={queryString} onChange={(e) => setQueryString(e.target.value)} />
                                    <button style={{cursor:"pointer"}} onClick={() => searchMedicalCases(null)}>Search</button>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ margin: 0 }}>
                            <Row>
                                <Col xs={10}>
                                    {activeSubject !== null ? <p className="related-news-title">Showing result for <b>{activeSubject?.subject_name}</b></p> : null }
                                    {searchedString !== "" ? <p className="related-news-title">Showing result for <b>{searchedString}</b></p> : null }
                                </Col>
                                <Col xs={2}>
                                   {activeSubject !== null || searchedString !== "" ? <button style={{borderBottom:"0px"}} className="news-subject-item" onClick={() => {getMedicalCases(); setActiveSubject(null); setSearchString("")}} >Clear</button> : null }
                                </Col>
                            </Row>
                            {medicalCasesList.map((item, index) => {
                                return (
                                    <Col key={index} md={12}>
                                        <CaseSubjectThumbnail item={item} />
                                    </Col>
                                )
                            })}
                            {medicalCasesList.length === 0 ? 
                            <div style={{height:"150px", display:"flex", alignItems:"center", justifyContent:'center'}}>
                                <p>No result found...</p>
                            </div> : null }
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default News;

const CaseSubjectThumbnail = (props) => {
    const item = props.item;
    const navigate = useNavigate();
    return (
        <div className='news-thumbnail-div'>
            <Row>
                <Col md={3}>
                    <div className='news-thumbnail-img-div'>
                        {item?.case_images_names !== null ?
                            <img src={item?.case_images_names} alt="news_image" /> : null}
                    </div>
                </Col>
                <Col md={9}>
                    <p className="news-thumbnail-category">{item.subject_name}</p>
                    <p className="news-thumbnail-name"><a href="/" onClick={(e) => { e.preventDefault(); navigate("/user/medical_cases/" + item.case_id + "/" + item.case_name?.split(' ').join('_')) }}>{item.case_name}</a></p>
                    <Row>
                        <Col md={8}>
                            <div>
                                {item?.author_name !== null && item?.author_name !== "" ?
                                    <div style={{ display: 'flex', alignItems: 'center', marginRight: "20px" }}>
                                        <i className="fas fa-user-edit"></i>
                                        <p className='author-name'>{item?.author_name}</p>
                                    </div> : null}
                                {/* {item?.publish_date !== null && item?.publish_date !== "" ?
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <i class="fas fa-calendar-alt"></i>
                                        <p className='author-name'>{moment(item?.publish_date).format("DD, MMM YYYY")}</p>
                                    </div> : null} */}
                            </div>
                        </Col>
                        {/* <Col md={4}>
                            <div style={{ display: "flex" }}>
                                <div className="count-div">
                                    <i class="fa fa-heart" aria-hidden="true" style={{ color: "#ff0000" }}></i>
                                    <p>{item?.likes_cnt}</p>
                                </div>
                                <div className="count-div" style={{ marginLeft: "25px" }}>
                                    <i class="fa fa-eye" aria-hidden="true"></i>
                                    <p>{item?.views_cnt}</p>
                                </div>
                            </div>
                        </Col> */}

                    </Row>
                </Col>
            </Row>
        </div>
    )
}