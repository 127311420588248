import { site_ip } from '../config';
import axios from 'axios';

const jwtHeader = {
  headers: { 'access-token': localStorage.getItem('siteuser') },
};
console.log(jwtHeader)
const apiSettings = {
  fetchCategories: async () => {
    const response = await axios.get(`${site_ip}/getCategories`, jwtHeader);
    return response.data.Data;
  },
  fetchCategoryById: async (category_id) => {
    const endpoint = `${site_ip}/getCategoryById/${category_id}`;
    const response = await axios.get(endpoint, jwtHeader);
    return response.data.content;
  },
  fetchSubjects: async () => {
    const response = await axios.get(`${site_ip}/getSubjects`, jwtHeader);
    return response.data.content;
  },
  fetchSubjectById: async (subject_id) => {
    const response = await axios.get(`${site_ip}/getSubjectById/${subject_id}`, jwtHeader);
    return response.data.Content[0];
  },
  fetchTopics: async () => {
    const response = await axios.get(`${site_ip}/getAllTopics`, jwtHeader);
    return response.data.Summary;
  },
  fetchTopicById: async (topic_id) => {
    const response = await axios.get(`${site_ip}/getTopicById/${topic_id}`, jwtHeader);
    return response.data.Content;
  },
  fetchSubjectTopics: async (subject_id) => {
    const endpoint = `${site_ip}/getSubjectTopics/${subject_id}`;
    const response = await axios.get(endpoint, jwtHeader);
    return response.data.content;
  },
  fetchQuestionTags: async () => {
    const response = await axios.get(`${site_ip}/getQuestionTags`, jwtHeader);
    return response.data.Content;
  },
  fetchQuestionTagById: async (tag_id) => {
    const response = await axios.get(`${site_ip}/getQuestionTagById/${tag_id}`, jwtHeader);
    return response.data.Content;
  },
  fetchSkills: async () => {
    const response = await axios.get(`${site_ip}/getSkills`, jwtHeader);
    return response.data.Content;
  },
  fetchSkillById: async (skill_id) => {
    const response = await axios.get(`${site_ip}/getSkillById/${skill_id}`, jwtHeader);
    return response.data.Content;
  },
  fetchConcepts: async () => {
    const response = await axios.get(`${site_ip}/getExamSubjectConcepts`, jwtHeader);
    return response.data.Data;
  },
  fetchConceptById: async (concept_id) => {
    const endpoint = `${site_ip}/getExamSubjectConceptsById/${concept_id}`;
    const response = await axios.get(endpoint, jwtHeader);
    return response.data.Data[0];
  },
  fetchUnits: async () => {
    const response = await axios.get(`${site_ip}/getExamSubjectUnitsList`, jwtHeader);
    return response.data.Data;
  },
  fetchUnitById: async (unit_id) => {
    const endpoint = `${site_ip}/getExamSubjectUnitById/${unit_id}`;
    const response = await axios.get(endpoint, jwtHeader);
    return response.data.Data;
  },
  fetchPublishers: async () => {
    const response = await axios.get(`${site_ip}/getPublishers`, jwtHeader);
    return response.data.Data;
  },
  fetchPublisherById: async (publisher_id) => {
    const endpoint = `${site_ip}/getPublisherById/${publisher_id}`;
    const response = await axios.get(endpoint, jwtHeader);
    return response.data.content;
  },
  fetchInstitutions: async () => {
    const response = await axios.get(`${site_ip}/getInstitutions`, jwtHeader);
    return response.data.Data;
  },
  fetchInstitutionById: async (institution_id) => {
    const endpoint = `${site_ip}/getInstitutionById/${institution_id}`;
    const response = await axios.get(endpoint, jwtHeader);
    return response.data.Data;
  },
  fetchQuestions: async () => {
    const response = await axios.get(`${site_ip}/getQuestionBank`, jwtHeader);
    return response.data.content[0];
  },
  fetchQuestionById: async (question_id) => {
    const endpoint = `${site_ip}/getQuestionById/${question_id}`;
    const response = await axios.get(endpoint, jwtHeader);
    return response.data.Data;
  },
  fetchCountryList: async () => {
    const response = await axios.get(`${site_ip}/getCountryList`, jwtHeader);
    return response.data.Data;
  },
  fetchStateByCountry: async (country_id) => {
    const endpoint = `${site_ip}/getStateListByID/${country_id}`;
    const response = await axios.get(endpoint, jwtHeader);
    return response.data.Data;
  },
  fetchExams: async () => {
    const response = await axios.get(`${site_ip}/getExams`, jwtHeader);
    return response.data.Data;
  },
  fetchCourses: async () => {
    const response = await axios.get(`${site_ip}/getCourses`, jwtHeader);
    return response.data.Courses;
  },
  fetchCourseById: async (course_id) => {
    const response = await axios.get(`${site_ip}/getCourseById/${course_id}`, jwtHeader);
    return response.data.content;
  },
  fetchCoursesBySubject: async (subject_id) => {
    const endpoint = `${site_ip}/getCoursesbySubject/${subject_id}`;
    const response = await axios.get(endpoint, jwtHeader);
    return response.data.content;
  },
  fetchCommentries: async () => {
    const response = await axios.get(`${site_ip}/getExpertCommentaries`, jwtHeader);
    return response.data.Data;
  },
  fetchCommentaryById: async (commentary_id) => {
    const endpoint = `${site_ip}/getExpertCommentariesById/${commentary_id}`;
    const response = await axios.get(endpoint, jwtHeader);
    return response.data.Data[0];
  },
  fetchCommentriesBySubject: async (subject_id) => {
    const endpoint = `${site_ip}/getExpertCommentariesbySubject/${subject_id}`;
    const response = await axios.get(endpoint, jwtHeader);
    return response.data.Data;
  },
  fetchVideos: async () => {
    const response = await axios.get(`${site_ip}/getVideos`, jwtHeader);
    return response.data.Videos;
  },
  fetchVideoById: async (video_id) => {
    const response = await axios.get(`${site_ip}/getVideoById/${video_id}`, jwtHeader);
    return response.data.Videos;
  },
  fetchVideosBySubject: async (subject_id) => {
    const endpoint = `${site_ip}/getVideosbySubject/${subject_id}`;
    const response = await axios.get(endpoint, jwtHeader);
    return response.data.Data;
  },
  fetchMedicalCases: async () => {
    const response = await axios.get(`${site_ip}/getMedicalCases`, jwtHeader);
    return response.data.Cases;
  },
  fetchMedicalCaseById: async (case_id) => {
    const endpoint = `${site_ip}/getMedicalCaseById/${case_id}`;
    const response = await axios.get(endpoint, jwtHeader);
    return response.data.Cases[0];
  },
  fetchMedicalCaseBySubject: async (subject_id) => {
    const endpoint = `${site_ip}/getMedicalCasesBySubject/${subject_id}`;
    const response = await axios.get(endpoint, jwtHeader);
    return response.data.Cases;
  },
  fetchOwners: async () => {
    const response = await axios.get(`${site_ip}/getOwners`, jwtHeader);
    return response.data.content;
  },
  fetchBooks: async () => {
    const response = await axios.get(`${site_ip}/getBooks`, jwtHeader);
    return response.data.Books;
  },
  fetchSubjectBooks: async (subject_id) => {
    const endpoint = `${site_ip}/getBooksBySubjectId/${subject_id}`;
    const response = await axios.get(endpoint, jwtHeader);
    return response.data.Books;
  },
  fetchChapters: async (book_id) => {
    const response = await axios.get(`${site_ip}/getBookChapters/${book_id}`, jwtHeader);
    return response.data.Data;
  },
};
export default apiSettings;
