import React, { useState, useEffect } from "react";
import { Button, Col, Row, Modal } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import Plus from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import Aux from "../../hoc/_Aux";
import { site_ip } from "../../globalSetting";

import FAQQuestion from "./FAQQuestion";
import FAQQuestionEdit from "./FAQQuestionEdit";
const FAQs = (props) => {
  const jwtHeader = {
    headers: { "access-token": localStorage.getItem("admin_token") },
  };

  const [activeSection, setActiveSection] = useState(1);
  const [show, setShow] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);
  const [editQuestionItem, setEditQuestionItem] = useState();

  const [faqs, setFAQs] = useState([]);

  useEffect(() => {
    getEntries();
    // eslint-disable-next-line
  }, []);

  const getEntries = async () => {
    await axios
      .get(site_ip + "/getCourseFaq/" + props.id, jwtHeader)
      .then(function (response) {
        if (response.data.Lessons.length !== 0) {
          setFAQs(response.data.Lessons);
        } else {
          setFAQs([]);
        }
      })
      .catch(function (error) {
        console.log("error" + error);
      });
  };

  const handleClose = () => setShow(false);

  const removeRecord = async () => {
    handleClose();
    await axios
      .get(site_ip + "/deleteCourseFaq/" + selectedRecord, jwtHeader)
      .then(function (response) {
        Swal.fire({
          title: "Success",
          text: "FAQ removed successfully",
          icon: "success",
        });
        getEntries();
      })
      .catch(function (error) {
        console.log("error" + error);
      });
  };

  const deleteEntry = (section_id, index) => {
    setSelectedRecord(section_id);
    setShow(true);
  };

//   const showCompleteList = () => {
//     setActiveSection(1);
//     // eslint-disable-next-line
//   };

  return (
    <Aux>
      <div style={{ padding: "15px" }}>
        <Row>
          <Col className="text-end">
            <Button variant="dark" onClick={() => setActiveSection(2)}>
              <Plus /> New Question
            </Button>
          </Col>
        </Row>
        <Row className="rangeBox">
          <Col md={4} style={{ borderRight: "1px solid #000000" }}>
            <TableHeader />
            <div style={{ height: "calc(100vh - 250px)", overflow: "scroll" }}>
              {faqs.map((item, index) => {
                return (
                  <QuestionItem
                    index={index}
                    key={index}
                    item={item}
                    editLesson={() => {
                      setEditQuestionItem(item);
                      setActiveSection(3);
                    }}
                    deleteEntry={(section_id) => deleteEntry(section_id, index)}
                  />
                );
              })}
            </div>
          </Col>
          <Col
            md={8}
            style={{ height: "calc(100vh - 250px)", overflow: "scroll" }}
          >
            {activeSection === 2 ? (
              <FAQQuestion
                course_id={props.id}
                refreshList={() => {
                  getEntries();
                  setActiveSection(1);
                }}
              />
            ) : null}
            {activeSection === 3 ? (
              <FAQQuestionEdit
                item={editQuestionItem}
                course_id={props.id}
                refreshList={() => {
                  getEntries();
                  setActiveSection(1);
                }}
              />
            ) : null}
          </Col>
        </Row>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>Are you sure to remove this record?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={removeRecord}>
            Yes
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Aux>
  );
};

const TableHeader = () => {
  return (
    <Row
      className="tableRow"
      style={{ paddingTop: "5px", paddingBottom: "5px" }}
    >
      <Col xs={8} style={{ fontWeight: "bold", paddingLeft: "20px" }}>
        Questions
      </Col>
      <Col
        xs={4}
        style={{ fontWeight: "bold", paddingLeft: "20px", textAlign: "center" }}
      >
        Action
      </Col>
    </Row>
  );
};

const QuestionItem = (props) => {
  const [item, setItem] = useState(props.item);

  useEffect(() => {
    setItem(props.item);
  }, [props.item]);

  return (
    <Row className="tableRow">
      <Col xs={8} style={{ display: "flex", alignItems: "center" }}>
        <p style={{ marginBottom: "0px" }}>Question {props.index + 1}</p>
      </Col>
      <Col
        xs={4}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "5px",
        }}
      >
        <Button
          variant="success"
          className="rowActionButton"
          onClick={() => props.editLesson()}
        >
          <EditIcon />
        </Button>
        <Button
          variant="danger"
          className="rowActionButton"
          onClick={() => props.deleteEntry(item?.faq_id)}
        >
          <DeleteForeverIcon />
        </Button>
      </Col>
    </Row>
  );
};

export default FAQs;
