import React, { useEffect, useState,useRef } from 'react';
import HTMLFlipBook from "react-pageflip";
import Page from './Page';
import "./FlipBook.css";



const FlipBook = ({ images, bucketName }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [zoomed, setZoomed] = useState(false);
  const [page, setPage]= useState(0);
  const [totalPage,setTotalPage]=useState(0);
  const [clickedArrow, setClickedArrow] = useState('');
  const myref=useRef()
  const fullscreenRef = useRef(null);


  useEffect(()=>{
    setTotalPage(images.length-1)
    // console.log(window.innerHeight,'==',window.innerWidth)
  },[])

  const enterFullScreen = () => {
    console.log(fullscreenRef)
    if (fullscreenRef.current) {
      if (fullscreenRef.current.requestFullscreen) {
        fullscreenRef.current.requestFullscreen();
      } else if (fullscreenRef.current.mozRequestFullScreen) { // Firefox
        fullscreenRef.current.mozRequestFullScreen();
      } else if (fullscreenRef.current.webkitRequestFullscreen) { // Chrome, Safari and Opera
        fullscreenRef.current.webkitRequestFullscreen();
      } else if (fullscreenRef.current.msRequestFullscreen) { // IE/Edge
        fullscreenRef.current.msRequestFullscreen();
      }
    }
  };

  const handleNextPage = (arrow) => {
    setClickedArrow('');
    setTimeout(() => {
      setClickedArrow(arrow);
    }, 10);
    if(currentPage==totalPage){
      return;
    }
    setCurrentPage((prevPage) => prevPage + 1);
    myref.current.pageFlip().flipNext();
  };

  const handlePrevPage = (arrow) => {
    setClickedArrow('');
    setTimeout(() => {
      setClickedArrow(arrow);
    }, 10);
    console.log('prev')
    if(currentPage<=0){
      return;
    }
    setCurrentPage((prevPage) => prevPage - 1);
    myref.current.pageFlip().flipPrev();
  };

  const handleZoomToggle = () => {
    setZoomed((prevZoomed) => !prevZoomed);
  };

  if (images.length === 0) {
    return <div>Loading images...</div>;
  }

  // const imageUrl = bucketName ? `https://${bucketName}.s3.amazonaws.com/${images[currentPage]}` : images[currentPage];

  const onPage=(e)=>{
    // console.log(e,'OnPage')
    setPage(e.data)
    setCurrentPage(e.data)
  }

  const redirectTo=(e)=>{
    console.log(e);
    myref.current.pageFlip().turnToPage(e.target.value);
  }


  return (<>
    <div className='top_container' >
      <div className='top_container-page'>
          <span style={{fontWeight:800}}>Newsletter</span>
          <div className="separator"></div>
          <div><span style={{fontWeight:800}}>Pages</span>
            <input style={{width:"25px",marginLeft:"2px",alignContent:"center"}} value={currentPage} />
          </div>
      </div>
      <div>
        {/* <input placeholder='Search...'/> */}
      </div>
    </div>
<div className='flipbook_container' ref={fullscreenRef} >
      
      {/* <button disabled={currentPage === 0} onClick={handlePrevPage}>
      </button> */}

  <div className={`back_arrow arrow ${clickedArrow==="prev"?'clicked':''} ${currentPage === 0 ? 'disabled' : ''}` } onClick={()=>handlePrevPage('prev')} >
    <img src="/images/back_arrow.svg" alt="" srcSet=""/>
  </div>
     
      
      {/* <div>
        {zoomed ? (
          <img src={imageUrl} alt={`Page ${currentPage}`} onClick={handleZoomToggle} />
        ) : (
          <div onClick={handleZoomToggle} style={{ cursor: 'zoom-in' }}>
            <img src={imageUrl} alt={`Page ${currentPage}`} />
          </div>
        )}
      </div> */}

      <HTMLFlipBook 
         width={550}
         height={750}
         size="stretch"
         minWidth={415}
         maxWidth={600}
         minHeight={400}
         maxHeight={1533}
         maxShadowOpacity={0.5}
         showCover={true}
         mobileScrollSupport={true}
         onFlip={(e)=>onPage(e)}
        //  onChangeOrientation={this.onChangeOrientation}
        //  onChangeState={(e)=>handleChangeState(e)}
         className="flip-book"
         ref={myref}
      >
        {images.map((image,index)=>(
          bucketName?
          <div key={index} >
              <Page number={index+1} image={`https://${bucketName}.s3.amazonaws.com/${images[index]}`} >
            </Page>
          </div>: <div key={index}>
              <Page number={index+1} image={image} zoomed={zoomed} setZoomed={setZoomed} >
            </Page>
          </div>
          
        ))}
      </HTMLFlipBook>
      
<div className={`front_arrow arrow ${clickedArrow==="next"?'clicked':''} ${currentPage === totalPage ? 'disabled' : ''}`} onClick={()=>handleNextPage("next")}  >
      <img src="/images/back_arrow.svg" alt="" srcSet="" style={{ transform: "rotate(180deg)" }} />
    </div>
</div>
      <div className='bottom_container'>
        <div onClick={()=>handlePrevPage('prev')} className={`arrow ${clickedArrow==="prev"?'clicked':''} ${currentPage === 0 ? 'disabled' : ''}`}>
            <img src='/images/left-arrow.svg' height={24} width={24} />
        </div>
            {/* <div style={{margin:"5px"}}>
              {zoomed?<img src="/images/zoom-out.svg" alt="" srcSet="" height={24} width={24} onClick={()=>setZoomed(false)} />:<img src="/images/zoom.svg" alt="" srcSet="" height={24} width={24} onClick={()=>setZoomed(true)}/>}
            </div> */}
            <div style={{margin:"5px"}}  onClick={enterFullScreen} className={`arrow `}>
            <img src="/images/fullscreen.svg" alt="" srcSet="" height={24} width={24} />
            </div>
            <div onClick={()=>handleNextPage("next")} className={`arrow ${clickedArrow==="next"?'clicked':''} ${currentPage === totalPage ? 'disabled' : ''}`}>
            <img src="/images/right-arrow.svg" height={24} width={24} />
        </div>
      </div>
    </>
  );
}; 



export default FlipBook;